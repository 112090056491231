import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import Input from '../../elementos/Input';
import Snack from '../../elementos/Snack';

import LoginOutData from '../../helpers/LoginOutData';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import AccountCircle from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PasswordIcon from '@mui/icons-material/Password';
import CheckIcon from '@mui/icons-material/Check';
import PercentIcon from '@mui/icons-material/Percent';


export default function UsuariosNuevo() {

    const [estadoVista, setEstadoVista] = useState('cargado');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [tel_whatsapp, setTelWhatsapp] = useState('');
    const [comision, setComision] = useState('');
    const [contrasena1, setContrasena1] = useState('');
    const [contrasena2, setContrasena2] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            nombre,
            email,
            tel_whatsapp,
            comision,
            contrasena1,
            contrasena2
        }

        axios.post(Global.url_api + 'usuarios/create', body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: 'Usuario creado. Cerrar sesión y volver a ingresar para verlo.',
                    severity: 'success'
                });
                axios.get(Global.url_api + 'self/refresh-token', { headers: { Authorization: localStorage.getItem("token") } })
                    .then(res => {

                        LoginOutData.LoginData(res);

                        navigate('/usuarios');

                    })
                    .catch((error) => {
                        setSnackOpen({
                            open: true,
                            message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                            severity: 'error'
                        })
                    });

            })
            .catch((error) => {
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/usuarios"
                    titulo="Nuevo Usuario"
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Nombre"
                                    icon={<AccountCircle />}
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Email"
                                    icon={<MailOutlineIcon />}
                                    helperText="Será el usuario"
                                    value={email}
                                    onChange={setEmail}
                                    type="email"
                                    restricciones={['email']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Whatsapp"
                                    icon={<WhatsAppIcon />}
                                    helperText="Ej 5491144445555"
                                    value={tel_whatsapp}
                                    onChange={setTelWhatsapp}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Comisión"
                                    icon={<PercentIcon />}
                                    helperText="Ej 0.1"
                                    value={comision}
                                    onChange={setComision}
                                    type="tel"
                                    restricciones={['soloNumerosDecimales']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Contraseña"
                                    icon={<PasswordIcon />}
                                    helperText="Ingresa una contraseña"
                                    value={contrasena1}
                                    onChange={setContrasena1}
                                    type="password"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Repetir Contraseña"
                                    icon={<PasswordIcon />}
                                    helperText="Por favor repite la contraseña"
                                    value={contrasena2}
                                    onChange={setContrasena2}
                                    type="password"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}