import './assets/css/App.css';

import { React, useState, useEffect } from 'react';

import Router from './Router';
import Login from './components/vistas/Login';
import Loading from './components/elementos/Loading';

export default function App() {

  const [userSession, setUserSession] = useState('loading');

  const verificarSesionUsuario = () => {
    
    if( localStorage.getItem("token") && localStorage.getItem("token").length > 0 ){
      
      setUserSession('loggedin')

    }else{

      setUserSession('loggedout')

    }

  }

  useEffect(() => {
    
    verificarSesionUsuario()

  }, []);

  if( userSession === 'loading' ){

      return(
        <div className="App">
          <Loading navbar />
        </div>
      )

  }else if( userSession === 'loggedin' ){
  
    return(
      <div className="App">
        <Router changeUserSession={verificarSesionUsuario} />
      </div>
    )

  }else if( userSession === 'loggedout' ){

    return(
      <div className="App">
        <Login changeUserSession={setUserSession} />
      </div>
    )

  }

  
}