
export const Roles = [
    {
        value: "",
        label: "Personalizado"
    },
    {
        value: "1,2,3,4,5,6,7,9,11,12,13,14,15,16,17,19,21,23,24,25,26,27,32,43,44,45,46,47,48,49,50,51,55",
        label: "Administrador"
    },
    {
        value: "2,3,4,5,7,9,11,12,13,14,15,16,19,21,23,24,27,44,45,46,47,48",
        label: "Recepcionista"
    },
    {
        value: "2,4,17",
        label: "Profesional"
    },
]