import { React, useState, useEffect } from 'react';

import axios from 'axios';
import { Global } from '../../Global';
import Moment from 'react-moment';
import * as moment from 'moment';

import Loading from '../../elementos/Loading';
import ButtonIcon from '../../elementos/ButtonIcon';
import HeaderVista from '../../elementos/HeaderVista';
import Input from '../../elementos/Input';


import '../../../assets/css/table.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';

import SearchIcon from '@mui/icons-material/Search';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import { create } from '@mui/material/styles/createTransitions';

const delay = 0.4;

export default function WhatsappEnviados() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [registros, setRegistros] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocs, setTotalDocs] = useState(0);
    const [textoSearch, setTextoSearch] = useState('');
    const [desde, setDesde] = useState(moment().add(-7, 'days').format("YYYY-MM-DD"));
    const [hasta, setHasta] = useState(moment().format("YYYY-MM-DD"));
    const [desdeTime, setDesdeTime] = useState("00:00:01");
    const [hastaTime, setHastaTime] = useState("23:59:59");
    const [telefono, setTelefono] = useState(0)
    const [instance, setInstance] = useState(0)
    const [createdby, setCreatedby] = useState(0)

    useEffect(() => {

        refreshDatos()

    }, [page]);

    const refreshDatos = () => {

        axios.get(Global.url_api + 'whatsapp/enviados/' + desde + ' ' + desdeTime + '/' + hasta + ' ' + hastaTime + '/' + telefono + '/' + instance + '/' + createdby + '/' + page, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setRegistros(res.data.docs)
                setTotalPages(res.data.totalPages)
                setTotalDocs(res.data.totalDocs)
                setEstadoVista('cargado')

            })
            .catch((error) => {

            });

    }

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    titulo="Whatsapp Enviados"
                    icon={<WhatsAppIcon />}
                    end={<>
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="success"
                            startIcon={<CheckIcon />}
                            to={'/whatsapp/enviados'}
                            disabled
                        >Enviados</ButtonIcon>
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="error"
                            startIcon={<ErrorIcon />}
                            to={'/whatsapp/fallidos'}
                        >Fallidos</ButtonIcon>
                    </>}
                />
                <Container className="containerBigTable" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Desde Fecha"
                                value={desde}
                                onChange={setDesde}
                                type="date"
                                required
                                size="small"
                            />
                            <Input
                                label="Desde Hora"
                                value={desdeTime}
                                onChange={setDesdeTime}
                                type="time"
                                required
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Hasta Fecha"
                                value={hasta}
                                onChange={setHasta}
                                type="date"
                                required
                                size="small"
                            />
                            <Input
                                label="Hasta Hora"
                                value={hastaTime}
                                onChange={setHastaTime}
                                type="time"
                                required
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Teléfono"
                                value={telefono}
                                onChange={setTelefono}
                                type="text"
                                required
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Instancia"
                                value={instance}
                                onChange={setInstance}
                                type="text"
                                required
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Createdby"
                                value={createdby}
                                onChange={setCreatedby}
                                type="text"
                                required
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <p align="center"><Button type="button" variant="contained" color="success" onClick={() => refreshDatos()} startIcon={<CheckIcon />} >
                                Aplicar
                            </Button></p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid className="titulo" item xs={12}><hr /></Grid>
                        <Grid className="titulo" item xs={12} md={8}>
                            <p>Se encontraron {totalDocs} registros.</p>
                        </Grid>
                    </Grid>
                    <div className="tableContainer">
                        <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th align="center">Estado</th>
                                    <th align="center">Enviado El</th>
                                    <th align="left">Enviado Por</th>
                                    <th align="left">Tipo</th>
                                    <th align="left">DNI</th>
                                    <th align="center">Teléfono</th>
                                    <th align="center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    registros.length === 0 &&
                                    <tr>
                                        <td colSpan={7} align='center'>
                                            No hay datos.
                                        </td>
                                    </tr>
                                }
                                {registros.map((value, key) => {
                                    return (
                                        <tr key={key} >
                                            <td align="center"><CircleIcon color="success" /></td>
                                            <td align="center">{<Moment date={value.enviado_date} format="DD-MM-YYYY HH:mm:ss" />}</td>
                                            <td align="left">{value.objeto.createdBy}</td>
                                            <td align="left">{value.objeto.extra_data?.tipo}</td>
                                            <td align="left">{value.objeto.extra_data?.dni}</td>
                                            <td align="center">{value.objeto.message.to}</td>
                                            <td align="center">
                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    startIcon={<SearchIcon />}
                                                    to={'/whatsapp/enviados/' + value._id}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        className="table-pagination"
                        showFirstButton showLastButton
                        shape="rounded"
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />

                </Container>
            </>
        )

}