import { React, useState, useEffect } from 'react'

import axios from 'axios'
import { Global } from '../../Global'

import LoadingTabla from '../../elementos/LoadingTabla'
import ButtonIcon from '../../elementos/ButtonIcon'
import HeaderVista from '../../elementos/HeaderVista'
import Snack from '../../elementos/Snack'
import Error from '../../elementos/Error'

import LoginOutData from '../../helpers/LoginOutData'

import '../../../assets/css/table.css'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import { TextField } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'

const delay = 0.4

export default function Rubros() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [rubros, setRubros] = useState([])
    const [textSearch, setTextSearch] = useState("")
    const [page, setPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(15)
    const [totalDocs, setTotalDocs] = useState(0)
    const [totalPages, setTotalPages] = useState(0)

    var bodyTabla = (<LoadingTabla colSpan="3" />)

    const handleChangeTextSearch = (e) => {
        setPage(1)
        setTextSearch(e.target.value)
    }

    useEffect(() => {

        let timer1 = setTimeout(() => {

            setEstadoVista('loading')

            axios.get(Global.url_api + 'gastos/rubros', {
                headers: {
                    Authorization: localStorage.getItem("token"),
                    page: page,
                    itemsperpage: itemsPerPage,
                    textsearch: textSearch,
                }
            })
                .then(res => {

                    setRubros(res.data.resultados)
                    setTotalDocs(res.data.totalDocs)
                    setTotalPages(res.data.totalPages)
                    setEstadoVista('cargado')

                })
                .catch((error) => {
                    setEstadoVista('error');
                    setSnackOpen({
                        open: true,
                        message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                        severity: 'error'
                    });
                })

        }, delay * 1000)


        return () => {
            clearTimeout(timer1)
        }

    }, [textSearch, itemsPerPage, page])

    if (estadoVista === 'loading') bodyTabla = (<LoadingTabla colSpan="3" />)

    if (estadoVista === 'error') return (<><Error /><Snack estado={snackOpen} setSnackOpen={setSnackOpen} /></>)

    if (estadoVista === 'cargado') {
        bodyTabla = (<tbody>
            {
                rubros.length === 0 &&
                <tr>
                    <td colSpan={3} align='center'>
                        No hay datos.
                    </td>
                </tr>
            }
            {rubros.map((value, key) => {
                return (
                    <tr key={key} >
                        <td align="center">{value.id}</td>
                        <td align="left">{value.nombre}</td>
                        <td align="center">
                            <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="primary"
                                endIcon={<EditIcon />}
                                to={'/gastos/rubros/edit/' + value.id}
                            />
                        </td>
                    </tr>
                )
            })}
        </tbody>)
    }
    
    return (
        <>
            <HeaderVista
                volverTo={"/gastos"}
                titulo="Rubros"
                icon={<AutoAwesomeMotionIcon />}
                end={
                    (LoginOutData.userHasPermiso(17) ? <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<AddIcon />}
                        to="/gastos/rubros/nuevo"
                    >
                        Nuevo
                    </ButtonIcon> : '')
                }
            />
            <Container className="containerBigTable" component="main" maxWidth="lg">
                <Grid container>
                    <Grid className="titulo" item xs={12} md={8}>
                        <p>Se encontraron {totalDocs} registros.</p>
                    </Grid>
                    <Grid className="buscador" item xs={12} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField onChange={handleChangeTextSearch} fullWidth label="Buscar..." variant="standard" />
                        </Box>
                    </Grid>
                </Grid>
                <div className="tableContainer">
                    <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th align="center">Id</th>
                                <th align="left">Nombre</th>
                                <th align="center">Acciones</th>
                            </tr>
                        </thead>
                        {bodyTabla}
                    </table>
                </div>
                <Pagination
                    className="table-pagination"
                    showFirstButton showLastButton
                    shape="rounded"
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                />

            </Container>
        </>
    )

}