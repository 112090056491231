import { React, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router'

import axios from 'axios'
import { Global } from '../../Global'

import '../../../assets/css/nuevoRegistro.css'

import Loading from '../../elementos/Loading'
import HeaderVista from '../../elementos/HeaderVista'
import TituloMini from '../../elementos/TituloMini'
import SwitchInput from '../../elementos/SwitchInput'
import Input from '../../elementos/Input'
import Error from '../../elementos/Error'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'


import CheckIcon from '@mui/icons-material/Check'


export default function ClienteEditPaquete() {

    const [estadoVista, setEstadoVista] = useState('loading')

    const [dominio, setDominio] = useState('')

    const [solo_paquetes, setSoloPaquetes] = useState('')
    const [sena_paquetes, setSenaPaquetes] = useState('')
    const [crea_solo_admin, setCreaSoloAdmin] = useState('')

    const urlParams = useParams()
    const navigate = useNavigate()

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio)

                setSoloPaquetes(res.data.paquetes.solo_paquetes)
                setSenaPaquetes(res.data.paquetes.sena_paquetes)
                setCreaSoloAdmin((res.data.paquetes.crea_solo_admin ? res.data.paquetes.crea_solo_admin : false))

                setEstadoVista('cargado')

            })
            .catch((error) => {
                console.log(error?.response?.data)
                setEstadoVista('error')
            });

    }, [urlParams.id])


    const handleSubmit = (e) => {

        e.preventDefault()

        setEstadoVista('loading')

        let body = {
            solo_paquetes,
            sena_paquetes,
            crea_solo_admin,
        }

        axios.put(Global.url_api + 'cliente/update/paquetes/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id)

            })
            .catch((error) => {

            })

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Config. Paquetes " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Configuración de Paquetes">Opciones sobre los paquetes.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <SwitchInput
                                    label="Solo Paquetes"
                                    value={solo_paquetes}
                                    onChange={setSoloPaquetes}
                                    helperText="El cliente sólo puede reservar un turno desde paquetes. Si no está activado, la visualización de paquetes dependerá si hay algún paquete creado."
                                />
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <SwitchInput
                                    label="Solo Admin"
                                    value={crea_solo_admin}
                                    onChange={setCreaSoloAdmin}
                                    helperText="Si está activo, sólo el admin será quien asigna los paquetes a los clientes. Si está inactivo, además del admin, el cliente también podrá comprarlos."
                                />
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Input
                                    label="Seña Paquetes"
                                    value={sena_paquetes}
                                    onChange={setSenaPaquetes}
                                    type="tel"
                                    helperText="Porcentaje de seña para cobrar paquetes.0: Cualquier cliente compra paquetes y no los paga. 0.3: paga el total o una seña del 30%. 1.3: paga solo la seña del 30%. 1: paga solo el total."
                                    restricciones={['soloNumerosDecimales']}
                                    required
                                />
                            </Grid>



                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}