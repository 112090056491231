import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import SwitchInput from '../../elementos/SwitchInput';
import Select from '../../elementos/Select';
import Input from '../../elementos/Input';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';
import FilterListIcon from '@mui/icons-material/FilterList';


export default function ClienteEditConfigreserva() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [servicios_home, setServiciosHome] = useState('');
    const [orden_servicios_home, setOrdenServiciosHome] = useState('');
    const [precios_home, setPreciosHome] = useState('');
    const [precios_elige, setPreciosElige] = useState('');
    const [tiempo_home, setTiempoHome] = useState('');
    const [tiempo_elige, setTiempoElige] = useState('');
    const [agenda_elige, setAgendaElige] = useState('');
    const [agenda_loantesposible, setLoantesposible] = useState('');
    const [multiples_servicios, setMultiplesServicios] = useState('');
    const [combina_categorias, setCombinaCategorias] = useState('');
    const [registro_clientes, setRegistroClientes] = useState('');
    const [turno_addtime, setTurnoAddtime] = useState('');
    const [flex_time, setFlextime] = useState('');
    const [ncliente, setNcliente] = useState('');
    const [subcategoria, setSubcategoria] = useState('');
    const [cliente_turnos_pendientes, setClienteTurnosPendientes] = useState(0);
    const [cliente_superpone_turnos, setClienteSuperponeTurnos] = useState(true);
    const [email_duplicado, setEmailDuplicado] = useState(false);

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);
                setServiciosHome(res.data.config_reserva.servicios_home);
                setOrdenServiciosHome(res.data.config_reserva.orden_servicios_home);
                setPreciosHome(res.data.config_reserva.precios_home);
                setPreciosElige(res.data.config_reserva.precios_elige);
                setTiempoHome(res.data.config_reserva.tiempo_home);
                setTiempoElige(res.data.config_reserva.tiempo_elige);
                setAgendaElige(res.data.config_reserva.agenda_elige);
                setLoantesposible(res.data.config_reserva.agenda_loantesposible);
                setMultiplesServicios(res.data.config_reserva.multiples_servicios);
                setCombinaCategorias(res.data.config_reserva.combina_categorias);
                setRegistroClientes(res.data.config_reserva.registro_clientes);
                setTurnoAddtime(res.data.config_reserva.turno_addtime);
                setFlextime(res.data.config_reserva.flex_time);
                setNcliente(res.data.config_reserva.ncliente);
                setSubcategoria((res.data.config_reserva.subcategoria ? res.data.config_reserva.subcategoria : false));
                setClienteTurnosPendientes((res.data.config_reserva.cliente_turnos_pendientes ? res.data.config_reserva.cliente_turnos_pendientes : 0));
                setClienteSuperponeTurnos((res.data.config_reserva.cliente_superpone_turnos === undefined ? true : res.data.config_reserva.cliente_superpone_turnos));
                setEmailDuplicado(res.data.config_reserva.email_duplicado);

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            servicios_home,
            orden_servicios_home,
            precios_home,
            precios_elige,
            tiempo_home,
            tiempo_elige,
            agenda_elige,
            agenda_loantesposible,
            multiples_servicios,
            combina_categorias,
            registro_clientes,
            turno_addtime,
            flex_time,
            ncliente,
            subcategoria,
            cliente_turnos_pendientes,
            cliente_superpone_turnos,
            email_duplicado,
        }

        axios.put(Global.url_api + 'cliente/update/config-reserva/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Config. Reserva " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Servicios Home">Listado de servicios que aparece entre las dos fotos de portada de la vista de clientes. <b>No</b> es el listado de servicios que aparece cuando el cliente está resrevando un turno.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Servicios Home"
                                    value={servicios_home}
                                    onChange={setServiciosHome}
                                    helperText="Muestra el listado de servicios y categorías"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Precios Home"
                                    value={precios_home}
                                    onChange={setPreciosHome}
                                    helperText="Muestra el precio en el listado Servicios Home"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Tiempo Home"
                                    value={tiempo_home}
                                    onChange={setTiempoHome}
                                    helperText="Muestra el tiempo en el listado Servicios Home"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Select
                                    label="Orden Servicios"
                                    icon={<FilterListIcon />}
                                    value={orden_servicios_home}
                                    options={[
                                        {
                                            value: 'precio',
                                            label: 'Precio'
                                        },
                                        {
                                            value: 'descripcion',
                                            label: 'Descripción'
                                        },
                                        {
                                            value: 'tiempo',
                                            label: 'Tiempo'
                                        }
                                    ]}
                                    onChange={setOrdenServiciosHome}
                                    type="text"
                                    helperText="Orden en que aparecen los Servicios Home"
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <hr />
                            </Grid>

                            <Grid item xs={12} >
                                <TituloMini titulo="Opciones de Reserva">Opciones mientras el cliente está reservando el turno. Se aplica cuando el cliente elige profesional, fecha, hora, etc.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Precio Elige"
                                    value={precios_elige}
                                    onChange={setPreciosElige}
                                    helperText="Muestra el precio del servicio y el total del turno"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Tiempo Elige"
                                    value={tiempo_elige}
                                    onChange={setTiempoElige}
                                    helperText="Muestra el tiempo del servicio y el total del turno"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Agenda Elige"
                                    value={agenda_elige}
                                    onChange={setAgendaElige}
                                    helperText="Permite elegir agenda (profesional) o lo antes posible"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Lo Antes Posible"
                                    value={agenda_loantesposible}
                                    onChange={setLoantesposible}
                                    helperText="Si el cliente no sabe qué agenda elegir, aparece la opción 'Lo antes posible'"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Múltiples Servicios"
                                    value={multiples_servicios}
                                    onChange={setMultiplesServicios}
                                    helperText="Permite elegir más de un servicio en un mismo turno (checkbox de servicios, o elige uno solo)"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Combina Categorías"
                                    value={combina_categorias}
                                    onChange={setCombinaCategorias}
                                    helperText="Permite elegir servicios de diferentes categorías en un mismo turno (puede afectar a que no encuentre profesionales)"
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <hr />
                            </Grid>

                            <Grid item xs={12} >
                                <TituloMini titulo="Otras opciones">Opciones adicionales a la reserva.</TituloMini>
                            </Grid>                            
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Flex Time"
                                    value={flex_time}
                                    onChange={setFlextime}
                                    helperText="Sólo desactivar cuando los turnos son TODOS de la misma duración. Permite que el sistema calcule solo los turnos."
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="N° Cliente"
                                    value={ncliente}
                                    onChange={setNcliente}
                                    helperText="Si la empresa necesita cargar un n° cliente"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Subcategoría"
                                    value={subcategoria}
                                    onChange={setSubcategoria}
                                    helperText="Si la empresa usa subcategorías"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Turno Addtime"
                                    value={turno_addtime}
                                    onChange={setTurnoAddtime}
                                    type="tel"
                                    helperText="Minutos adicionales que se le agregan a todos los turnos (ej desinfección)"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} ></Grid>
                            <Grid item xs={12} md={4} ></Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Registro de Clientes"
                                    value={registro_clientes}
                                    onChange={setRegistroClientes}
                                    helperText="Desactiva el botón 'Registrate' y sólo puede agregar clientes el administrador"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Cliente Turnos Pendientes"
                                    value={cliente_turnos_pendientes}
                                    onChange={setClienteTurnosPendientes}
                                    type="tel"
                                    helperText="Cantidad de turnos pendientes que puede tener el mismo cliente (DNI). 0: libre. 2: solo puede reservar 2 turnos, hasta que no pase uno de ellos no podrá reservar otro."
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Cliente Superpone Turnos"
                                    value={cliente_superpone_turnos}
                                    onChange={setClienteSuperponeTurnos}
                                    helperText="El mismo cliente puede reservar 2 turnos o más a la misma hora."
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Duplica Email"
                                    value={email_duplicado}
                                    onChange={setEmailDuplicado}
                                    helperText="La marca permite el mismo email para varios clientes."
                                />
                            </Grid>


                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}