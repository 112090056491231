import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import Error from '../../elementos/Error';
import HeaderVista from '../../elementos/HeaderVista';
import Input from '../../elementos/Input';
import Snack from '../../elementos/Snack';


import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PasswordIcon from '@mui/icons-material/Password';
import CheckIcon from '@mui/icons-material/Check';


export default function MiPerfilEditar() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [tel_whatsapp, setTelWhatsapp] = useState('');
    const [contrasena1, setContrasena1] = useState('');
    const [contrasena2, setContrasena2] = useState('');

    const urlParams = useParams();

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            nombre,
            email,
            tel_whatsapp,
            contrasena1,
            contrasena2,
        }

        axios.put(Global.url_api + 'usuarios/mi-perfil', body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: '¡Se guardaron los cambios!',
                    severity: 'success'
                })
                setEstadoVista('cargado');

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }


    useEffect(() => {

        axios.get(Global.url_api + 'usuarios/mi-perfil', { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setNombre(res.data.nombre);
                setEmail(res.data.email);
                setTelWhatsapp(res.data.tel_whatsapp);
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });



    }, [urlParams]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    titulo={"Editar Usuario / " + nombre}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Nombre"
                                    icon={<LocalGroceryStoreIcon />}
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Email"
                                    icon={<MailOutlineIcon />}
                                    helperText="Será el usuario"
                                    value={email}
                                    onChange={setEmail}
                                    type="email"
                                    restricciones={['email']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Whatsapp"
                                    icon={<WhatsAppIcon />}
                                    helperText="Ej 5491144445555"
                                    value={tel_whatsapp}
                                    onChange={setTelWhatsapp}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Contraseña"
                                    icon={<PasswordIcon />}
                                    helperText="Ingresa una contraseña"
                                    value={contrasena1}
                                    onChange={setContrasena1}
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Repetir Contraseña"
                                    icon={<PasswordIcon />}
                                    helperText="Por favor repite la contraseña"
                                    value={contrasena2}
                                    onChange={setContrasena2}
                                    type="password"
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}