import { React, useState, useEffect } from 'react';

import axios from 'axios';
import { Global } from '../../Global';

import Loading from '../../elementos/Loading';
import ButtonIcon from '../../elementos/ButtonIcon';
import HeaderVista from '../../elementos/HeaderVista';
import Error from '../../elementos/Error';
import Snack from '../../elementos/Snack';

import LoginOutData from '../../helpers/LoginOutData';

import '../../../assets/css/table.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { TextField } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const delay = 0.7;

export default function Usuarios() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [usuarios, setUsuarios] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocs, setTotalDocs] = useState(0);
    const [textoSearch, setTextoSearch] = useState('');

    useEffect(() => {

        let timer1 = setTimeout(() => {

            let data = {
                page: (page ? page : 1),
                nombre: (textoSearch ? textoSearch : '')
            }

            axios.post(Global.url_api + 'usuarios/get', data, { headers: { Authorization: localStorage.getItem("token") } })
                .then(res => {

                    setUsuarios(res.data.usuarios.docs)
                    setTotalPages(res.data.usuarios.totalPages)
                    setTotalDocs(res.data.usuarios.totalDocs)
                    setEstadoVista('cargado')

                })
                .catch((error) => {
                    setEstadoVista('error');
                    setSnackOpen({
                        open: true,
                        message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                        severity: 'error'
                    });
                });


        }, delay * 1000);


        return () => {
            clearTimeout(timer1);
        };

    }, [textoSearch, page]);

    const handleChangeTextSearch = (e) => {
        setPage(1)
        setTextoSearch(e.target.value)
    }

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<><Error /><Snack estado={snackOpen} setSnackOpen={setSnackOpen} /></>)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    titulo={"Usuarios"}
                    icon={<SupportAgentIcon />}
                    end={(LoginOutData.userHasPermiso(6) ? <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<AddIcon />}
                        to="/usuarios/nuevo"
                    >
                        Nuevo
                    </ButtonIcon> : '')}
                />
                <Container className="containerBigTable" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="titulo" item xs={12} md={8}>
                            <p>Se encontraron {totalDocs} registros.</p>
                        </Grid>
                        <Grid className="buscador" item xs={12} md={4}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField onChange={handleChangeTextSearch} fullWidth label="Buscar..." variant="standard" />
                            </Box>
                        </Grid>
                    </Grid>
                    <div className="tableContainer">
                        <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th align="left">Nombre</th>
                                    <th align="left">Email</th>
                                    <th align="left">Whatsapp</th>
                                    <th align="center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    usuarios.length === 0 && 
                                    <tr>
                                        <td colSpan={4} align='center'>
                                            No hay datos.
                                        </td>
                                    </tr>
                                }
                                {usuarios.map((value, key) => {
                                    return (
                                        <tr key={key} >
                                            <td align="left">{value.nombre}</td>
                                            <td align="left">{value.email}</td>
                                            <td align="left">{value.tel_whatsapp}</td>
                                            <td align="center">
                                                {
                                                    LoginOutData.userHasPermiso(7) &&
                                                    <ButtonIcon
                                                        variant="outlined"
                                                        size="small"
                                                        color="primary"
                                                        startIcon={<CreateIcon />}
                                                        to={'/usuarios/' + value._id}
                                                        marginRight
                                                    />
                                                }
                                                {
                                                    LoginOutData.userHasPermiso(16) &&
                                                    <ButtonIcon
                                                        variant="outlined"
                                                        size="small"
                                                        color="error"
                                                        startIcon={<DeleteOutlineIcon />}
                                                        to={'/usuarios/delete/' + value._id}
                                                        marginRight
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        className="table-pagination"
                        showFirstButton showLastButton
                        shape="rounded"
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />

                </Container>
            </>
        )

}