import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../Global';
import Moment from 'react-moment';

import Loading from '../../elementos/Loading';
import ButtonIcon from '../../elementos/ButtonIcon';
import HeaderVista from '../../elementos/HeaderVista';


import '../../../assets/css/table.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import LayersIcon from '@mui/icons-material/Layers';
import TodayIcon from '@mui/icons-material/Today';
import BadgeIcon from '@mui/icons-material/Badge';
import DataObjectIcon from '@mui/icons-material/DataObject';

export default function WhatsappEnviado() {

    const urlParams = useParams()

    const [estadoVista, setEstadoVista] = useState('loading');
    const [registro, setRegistro] = useState(undefined);

    const recordatorioWhatsappFormato = (mensaje) => {

        const htmlFormat = [
            { symbol: '*', tag: 'b' },
            { symbol: '_', tag: 'em' },
            { symbol: '~', tag: 'del' },
            { symbol: '`', tag: 'code' },
        ];

        htmlFormat.forEach(({ symbol, tag }) => {
            if (!mensaje) return;

            const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
            const match = mensaje.match(regex);
            if (!match) return;

            match.forEach(m => {
                let formatted = m;
                for (let i = 0; i < 2; i++) {
                    formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
                }
                mensaje = mensaje.replace(m, formatted);
            });
        });

        return mensaje.replace(/(?:\r\n|\r|\n)/g, "<br>")

    }

    useEffect(() => {

        axios.get(Global.url_api + 'whatsapp/enviados/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setRegistro(res.data)
                setEstadoVista('cargado')

            })
            .catch((error) => {

            });

    }, []);



    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'cargado' && registro !== undefined)
        return (
            <>
                <HeaderVista
                    titulo="Whatsapp Enviado"
                    icon={<WhatsAppIcon />}
                    end={<>
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="success"
                            startIcon={<CheckIcon />}
                            to={'/whatsapp/enviados'}
                        >Enviados</ButtonIcon>
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="error"
                            startIcon={<ErrorIcon />}
                            to={'/whatsapp/fallidos'}
                        >Fallidos</ButtonIcon>
                    </>}
                />
                <Container className="containerBigTable" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><ChatBubbleIcon /> ID:</b> {registro._id}</p>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><LaptopChromebookIcon /> Instancia:</b> {registro.objeto.instance}</p>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><LayersIcon /> Tipo:</b> {registro.objeto.extra_data.tipo}</p>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><TodayIcon /> Creado El:</b> <Moment date={registro.objeto.createdAt} format={"DD-MM-YYYY HH:mm:ss"} /></p>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><StorefrontIcon /> Creado Por:</b> {registro.objeto.createdBy}</p>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><TodayIcon /> Enviado El:</b> <Moment date={registro.enviado_date} format={"DD-MM-YYYY HH:mm:ss"} /></p>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><BadgeIcon /> DNI:</b> {registro.objeto.extra_data.dni}</p>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><WhatsAppIcon /> Whatsapp:</b> {registro._id}</p>
                            <p style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}><b><DataObjectIcon /> Extra Data:</b> <pre>{JSON.stringify(registro.objeto.extra_data, null, 2)}</pre></p>
                        </Grid>
                        <Grid item xs={12} md={6} style={{padding: '25px'}}>
                            <Paper elevation={3} style={{padding: '15px'}}>
                                <div dangerouslySetInnerHTML={{ __html: recordatorioWhatsappFormato(registro.objeto?.message?.message) }} >
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        )

}