import { React, useState, useEffect } from 'react'

import axios from 'axios'
import { Global } from '../Global'


import Select from './Select'

import PersonIcon from '@mui/icons-material/Person'


export default function FiltroVendedores({changeVendedor, vendedor}) {

    const [vendedores, setVendedores] = useState([])

    useEffect(() => {

        axios.get(Global.url_api + 'usuarios/all-con-acceso', { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                let vendedores = res.data.usuarios
                vendedores.push({
                    _id: 0,
                    nombre: 'Todos'
                })

                setVendedores(vendedores)
                changeVendedor(res.data.self)

            })
            .catch((error) => {

                console.log(error)

            })

    }, [changeVendedor])

    return (
        <Select
            label="Vendedor"
            icon={<PersonIcon />}
            value={vendedor}
            options={vendedores.map(function (e) {
                return {
                    value: e._id,
                    label: e.nombre
                }
            })}
            onChange={changeVendedor}
            required
        />
    )

}