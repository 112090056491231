import { React } from 'react';

import { url_cdn } from '../Global';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CircularProgress from '@mui/material/CircularProgress';

export default function Loading(props) {

    const navbar = (<Grid container spacing={0} style={{ background: '#FFE600', marginBottom: '35px', boxShadow: '0 2px 3px 0 rgba(0,0,0,0.2)' }}>
        <Grid item xs={3} sm={4} md={5}>

        </Grid>
        <Grid item xs={6} sm={4} md={2}>
            <img style={{ paddingTop: '13px', paddingBottom: '13px' }} width="90%" alt="doyTurnos.com" src={url_cdn + "/web/sistema-de-turnos.png"} />
        </Grid>
        <Grid item xs={3} sm={4} md={5}>

        </Grid>
    </Grid>)

    return (
        <>
            {props.navbar && navbar}
            <Container component="main" >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div align="center"><CircularProgress style={{ 'color': 'black' }} /></div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}