import * as React from 'react';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

export default function SimpleSnackbar(props) {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        props.setSnackOpen({
            open: false, 
            message: props.estado.message, 
            severity: props.estado.severity
        });
      };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={props.estado.open}
                autoHideDuration={6000}
                message={props.estado.message}
                onClose={handleClose}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={props.estado.severity}
                >
                    {props.estado.message}
                    {action}
                </Alert>
            </Snackbar>
        </div>
    );
}