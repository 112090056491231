import { React, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

import '../../assets/css/Sidebar.css';

import LoginOutData from '../helpers/LoginOutData';

import { SidebarData } from './SidebarData';

const Sidebar = ({ sideBar, setSidebar }) => {

    const location = useLocation();


    useEffect(() => {

        if (window.innerWidth >= 768) {
            setSidebar(true)
        } else {
            setSidebar(false)
        }

    }, [location.pathname, setSidebar]);

    return (
        <div id="sidebar" className={(sideBar ? 'open' : 'closed')}>
            <ul>
                {SidebarData.map((val, key) => {

                    if (LoginOutData.userHasPermiso(val.permiso)) {

                        return (
                            <Link key={key} to={val.path}>
                                <li
                                    className="sidebar-row"
                                >
                                    <div className="icono">
                                        {val.icono}
                                    </div>
                                    <div className="titulo">
                                        {val.titulo}
                                    </div>
                                </li>
                            </Link>
                        )

                    }else{
                        return(<div key={key}></div>)
                    }

                })}
            </ul>
        </div>
    );

}

export default Sidebar;