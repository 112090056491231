import React from 'react';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const SidebarData = [
    {
        titulo: 'Reportes',
        path: '/reportes',
        icono: <ShowChartIcon />,
        permiso: 0
    },
    {
        titulo: 'Clientes',
        path: '/clientes',
        icono: <PeopleAltIcon />,
        permiso: 8
    },
    {
        titulo: 'Planes',
        path: '/planes',
        icono: <AutoAwesomeMotionIcon />,
        permiso: 1
    },
    {
        titulo: 'Gastos',
        path: '/gastos',
        icono: <ReceiptIcon />,
        permiso: 20
    },
    {
        titulo: 'Usuarios',
        path: '/usuarios',
        icono: <SupportAgentIcon />,
        permiso: 5
    },
    {
        titulo: 'Permisos App',
        path: '/permisosapp',
        icono: <FactCheckIcon />,
        permiso: 21
    },
    {
        titulo: 'Whatsapp Logs',
        path: '/whatsapp/enviados',
        icono: <WhatsAppIcon />,
        permiso: 21
    },
]