import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/detalleRegistro.css';

import Loading from '../../elementos/Loading';
import Error from '../../elementos/Error';
import HeaderVista from '../../elementos/HeaderVista';
import DisplayInfoUl from '../../elementos/DisplayInfoUl';
import TextoConIcono from '../../elementos/TextoConIcono';
import ConfirmDelete from '../../elementos/ConfirmDelete';
import ButtonIcon from '../../elementos/ButtonIcon';

import LoginOutData from '../../helpers/LoginOutData';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import CampaignIcon from '@mui/icons-material/Campaign';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';

export default function ClienteDetalle() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [cliente, setCliente] = useState({});

    const urlParams = useParams();



    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setCliente(res.data);
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/clientes"
                    titulo={cliente.dominio + " - Configuración"}
                    end={
                        <>
                            <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="primary"
                                startIcon={<SettingsIcon />}
                                to={'/clientes/' + urlParams.id}
                                marginRight
                            />
                            <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="success"
                                startIcon={<PointOfSaleIcon />}
                                to={'/clientes/' + urlParams.id + '/cuenta-corriente'}
                                marginRight
                            />
                            <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="secondary"
                                startIcon={<AppSettingsAltIcon />}
                                to={'/clientes/' + urlParams.id + '/app'}
                                marginRight
                            />
                            {
                                LoginOutData.userHasPermiso(15) &&
                                <ConfirmDelete
                                    variant="contained"
                                    size="small"
                                    httpDelete={"cliente/" + urlParams.id}
                                    returnUrl={"/clientes/"}
                                    soloIcono
                                />
                            }
                            <br />
                        </>
                    }
                />
                <Container component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Info de Contacto"
                                editar={(LoginOutData.userHasPermiso(15) ? {
                                    path: '/clientes/' + cliente._id + '/edit/info-de-contacto'
                                } : '')}
                                data={
                                    [
                                        { index: 'Nombre', value: cliente.nombre },
                                        { index: 'Email', value: cliente.email },
                                        { index: 'Teléfono', value: cliente.telefono },
                                        { index: 'Vendedor', value: cliente.vendedor.nombre }
                                    ]
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={8} >
                            <DisplayInfoUl
                                titulo="Acceso"
                                data={
                                    [
                                        { index: 'Alta', value: cliente.alta },
                                        { index: 'Clientes', value: cliente.url_clientes },
                                        { index: 'Admin', value: cliente.url_admin },
                                    ]
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <hr />
                            <h3 align="center" >Vista de Clientes</h3>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Marca"
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/marca'
                                } : '')}
                                data={
                                    [
                                        { index: 'Marca', value: cliente.marca.marca },
                                        { index: 'Email', value: cliente.marca.email },
                                        { index: 'Cod. País', value: cliente.marca.tel_pais },
                                        { index: 'Cod. Área', value: cliente.marca.tel_area },
                                        { index: 'Ej Cel', value: cliente.marca.ej_cel },
                                        { index: 'Whatsapp', value: cliente.marca.telefono },
                                        { index: 'Direccion', value: cliente.marca.direccion },
                                        { index: 'Mapa', value: cliente.marca.mapa },
                                        { index: 'Lenguaje', value: cliente.lenguaje },
                                    ]
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Colores"
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/colores'
                                } : '')}
                                data={
                                    [
                                        { index: 'Menu', value: cliente.colores.menu },
                                        { index: 'Menu Texto', value: cliente.colores.menu_texto },
                                        { index: 'Botones', value: cliente.colores.botones },
                                        { index: 'Botones Texto', value: cliente.colores.botones_texto },
                                        { index: 'Footer', value: cliente.colores.footer },
                                        { index: 'Footer Texto', value: cliente.colores.footer_texto },
                                        { index: 'Fondo', value: cliente.colores.fondo },
                                        { index: 'Fondo Texto', value: cliente.colores.fondo_texto },
                                        { index: 'Lista', value: cliente.colores.lista },
                                        { index: 'Lista Texto', value: cliente.colores.lista_texto },
                                    ]
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Archivos e Imágenes"
                                data={
                                    [
                                        { index: 'CSS', value: (cliente.rutas.css === '' ? 'No' : 'Sí') },
                                        { index: 'Logo', value: <img src={cliente.rutas.logo} height="35" alt="Logo" style={{ marginLeft: '5px' }} /> },
                                        { index: 'Socials', value: <img src={cliente.rutas.socials} height="35" alt="Socials" style={{ marginLeft: '5px' }} /> },
                                        { index: 'Favicon', value: <img src={cliente.rutas.favicon} height="35" alt="Favicon" style={{ marginLeft: '5px' }} /> },
                                        { index: 'Banner 1', value: <img src={cliente.rutas.banner1} height="35" alt="Banner 1" style={{ marginLeft: '5px' }} /> },
                                        { index: 'Banner 2', value: <img src={cliente.rutas.banner2} height="35" alt="Banner 2" style={{ marginLeft: '5px' }} /> },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/rutas'
                                } : '')}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <hr />
                            <h3 align="center" >Opciones de Reserva</h3>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Config. Reserva"
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/config-reserva'
                                } : '')}
                                data={
                                    [
                                        { index: 'Servicios Home', value: (cliente.config_reserva.servicios_home ? 'Sí' : 'No') },
                                        { index: 'Orden Servicios', value: cliente.config_reserva.orden_servicios_home },
                                        { index: 'Precios Home', value: (cliente.config_reserva.precios_home ? 'Sí' : 'No') },
                                        { index: 'Precios Elige', value: (cliente.config_reserva.precios_elige ? 'Sí' : 'No') },
                                        { index: 'Tiempo Home', value: (cliente.config_reserva.tiempo_home ? 'Sí' : 'No') },
                                        { index: 'Tiempo Elige', value: (cliente.config_reserva.tiempo_elige ? 'Sí' : 'No') },
                                        { index: 'Agenda Elige', value: (cliente.config_reserva.agenda_elige ? 'Sí' : 'No') },
                                        { index: 'Agenda Lo antes posible', value: (cliente.config_reserva.agenda_loantesposible ? 'Sí' : 'No') },
                                        { index: 'Multiples Servicios', value: (cliente.config_reserva.multiples_servicios ? 'Sí' : 'No') },
                                        { index: 'Combina Categorias', value: (cliente.config_reserva.combina_categorias ? 'Sí' : 'No') },
                                        { index: 'Registro Clientes', value: (cliente.config_reserva.registro_clientes ? 'Sí' : 'No') },
                                        { index: 'Turno Addtime', value: cliente.config_reserva.turno_addtime },
                                        { index: 'Flex Time', value: (cliente.config_reserva.flex_time ? 'Sí' : 'No') },
                                        { index: 'N° Cliente', value: (cliente.config_reserva.ncliente ? 'Sí' : 'No') },
                                        { index: 'Usa Subcategorías', value: (cliente.config_reserva.subcategoria ? 'Sí' : 'No') },
                                        { index: 'Cliente Turnos Pendientes', value: (cliente.config_reserva.cliente_turnos_pendientes ? cliente.config_reserva.cliente_turnos_pendientes : '0') },
                                        { index: 'Cliente Superpone Turnos', value: (cliente.config_reserva.cliente_superpone_turnos == undefined ? 'Sí' : (cliente.config_reserva.cliente_superpone_turnos ? 'Sí' : 'No')) },
                                        { index: 'Duplica Email', value: (cliente.config_reserva.email_duplicado ? 'Sí' : 'No') },
                                    ]
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Config. Fechas"
                                data={
                                    [
                                        { index: 'Elige', value: (cliente.fechas.elige ? 'Sí' : 'No') },
                                        { index: 'Tope (días)', value: cliente.fechas.tope },
                                        { index: 'Anticipación (horas)', value: cliente.fechas.anticipacion },
                                        { index: 'Canchas', value: (cliente.fechas.canchas ? 'Sí' : 'No') },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/fechas'
                                } : '')}
                            />
                            <DisplayInfoUl
                                titulo="Config. Reprograma"
                                data={
                                    [
                                        { index: 'Cancela', value: (cliente.turno_reprograma.cancela ? 'Sí' : 'No') },
                                        { index: 'Cancela hs', value: cliente.turno_reprograma.cancela_hs },
                                        { index: 'Reprograma', value: (cliente.turno_reprograma.reprograma ? 'Sí' : 'No') },
                                        { index: 'Reprograma hs', value: cliente.turno_reprograma.reprograma_hs },
                                        { index: 'Reprograma Cant. Máx', value: (cliente.turno_reprograma.reprograma_cant_maxima ? cliente.turno_reprograma.reprograma_cant_maxima : 0) },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/turno-reprograma'
                                } : '')}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                        <DisplayInfoUl
                                titulo="Mensaje Home"
                                data={
                                    [
                                        { index: 'Activo', value: (cliente.home?.activo ? 'Sí' : 'No') },
                                        { index: 'Mensaje Home', value: cliente.home?.mensaje_home },
                                        { index: 'Color Fondo', value: cliente.home?.color_fondo },
                                        { index: 'Color Texto', value: cliente.home?.color_texto },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/mensaje-home'
                                } : '')}
                            />
                            <br /><br />
                            <DisplayInfoUl
                                titulo="Términos y Condiciones"
                                data={
                                    [
                                        { index: 'Activo', value: (cliente.terminosycondiciones?.activo ? 'Sí' : 'No') },
                                        { index: 'Mensaje', value: cliente.terminosycondiciones?.mensaje },
                                        { index: 'Botón', value: cliente.terminosycondiciones?.boton },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/terminosycondiciones'
                                } : '')}
                            />
                            <br /><br />
                            <TextoConIcono texto="Campos Personalizados" justifyContent="center" icono={<NoteAltIcon />} />
                            <TextoConIcono texto="Nuevo Cliente" justifyContent="center" />
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                                fullWidth
                                color="dark"
                                style={{ backgroundColor: '#fff' }}
                            >
                                {
                                    cliente.campos_adicionales.cliente.map((object, key) => {
                                        return (<Link to={(LoginOutData.userHasPermiso(10) ? "/clientes/" + urlParams.id + "/delete/campos-adicionales/cliente/" + key : '')} key={key}><Button disabled={!LoginOutData.userHasPermiso(10)} style={{ justifyContent: "flex-start" }}>{object.nombre}</Button></Link>)
                                    })
                                }
                                <Link to={(LoginOutData.userHasPermiso(10) ? "/clientes/" + urlParams.id + "/create/campos-adicionales/cliente" : '')}><Button disabled={!LoginOutData.userHasPermiso(10)} startIcon={<AddIcon />} style={{ justifyContent: "flex-start" }}>Nuevo</Button></Link>
                            </ButtonGroup>
                            <TextoConIcono texto="Nuevo Turno" justifyContent="center" />
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                                fullWidth
                                color="dark"
                                style={{ backgroundColor: '#fff' }}
                            >
                                <ButtonGroup
                                    orientation="vertical"
                                    aria-label="vertical outlined button group"
                                    fullWidth
                                    color="dark"
                                    style={{ backgroundColor: '#fff' }}
                                >
                                    {
                                        cliente.campos_adicionales.turno.map((object, key) => {
                                            return (<Link to={(LoginOutData.userHasPermiso(10) ? "/clientes/" + urlParams.id + "/delete/campos-adicionales/turno/" + key : '')} key={key}><Button disabled={!LoginOutData.userHasPermiso(10)} style={{ justifyContent: "flex-start" }}>{object.nombre}</Button></Link>)
                                        })
                                    }
                                    <Link to={(LoginOutData.userHasPermiso(10) ? "/clientes/" + urlParams.id + "/create/campos-adicionales/turno" : '')}><Button disabled={!LoginOutData.userHasPermiso(10)} startIcon={<AddIcon />} style={{ justifyContent: "flex-start" }}>Nuevo</Button></Link>
                                </ButtonGroup>
                            </ButtonGroup>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <hr />
                            <h3 align="center" >Módulos Adicionales</h3>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Medios de pago"
                                data={
                                    [
                                        { index: 'Mercadopago', value: (cliente.medios_de_pago?.medios_de_pago ? ((cliente.medios_de_pago?.medios_de_pago).includes("mercadopago") ? 'Sí' : 'No') : 'No') },
                                        { index: 'Transbank', value: (cliente.medios_de_pago?.medios_de_pago ? ((cliente.medios_de_pago?.medios_de_pago).includes("transbank") ? 'Sí' : 'No') : 'No') },
                                        { index: 'Paypal', value: (cliente.medios_de_pago?.medios_de_pago ? ((cliente.medios_de_pago?.medios_de_pago).includes("paypal") ? 'Sí' : 'No') : 'No') },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/medios-de-pago'
                                } : '')}
                            />
                            <DisplayInfoUl
                                titulo="Seña Turnos"
                                data={
                                    [
                                        { index: 'Deben pagar', value: (cliente.cobra_sena.deben_pagar ? 'Sí' : 'No') },
                                        { index: 'Pueden pagar', value: (cliente.cobra_sena.pueden_pagar ? 'Sí' : 'No') },
                                        { index: 'Importe debe', value: cliente.cobra_sena.importe_debe },
                                        { index: 'Importe valor', value: cliente.cobra_sena.importe_valor },
                                        { index: 'Descuento puede pagar', value: cliente.cobra_sena.descuento_puede_pagar },
                                        { index: 'Puede pagar en local', value: (cliente.cobra_sena.puede_pagar_en_local ? 'Sí' : 'No') },
                                        { index: 'Completar Pago', value: cliente.cobra_sena.completa_pago },
                                        { index: 'Dias Aviso', value: cliente.cobra_sena.completa_dias_aviso },
                                        { index: 'Horas Baja', value: cliente.cobra_sena.completa_hs_baja },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/cobra-sena'
                                } : '')}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Paquetes"
                                data={
                                    [
                                        { index: 'Sólo paquetes', value: (cliente.paquetes.solo_paquetes ? 'Sí' : 'No') },
                                        { index: 'Sólo Admin', value: (cliente.paquetes.crea_solo_admin ? 'Sí' : 'No') },
                                        { index: 'Pago de paquetes', value: cliente.paquetes.sena_paquetes },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/paquetes'
                                } : '')}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                        <DisplayInfoUl
                                titulo="Zoom"
                                data={
                                    [
                                        { index: 'Activo', value: (cliente.zoom?.activo ? 'Sí' : 'No') },
                                        { index: 'Días Genera', value: cliente.zoom?.dias_genera },
                                        { index: 'Api Key', value: cliente.zoom?.api_key },
                                        { index: 'Secret', value: cliente.zoom?.secret },
                                        { index: 'User', value: cliente.zoom?.user },
                                        { index: 'Contraseña', value: cliente.zoom?.password },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/zoom'
                                } : '')}
                            />
                            <DisplayInfoUl
                                titulo="Google Calendar"
                                data={
                                    [
                                        { index: 'Activo', value: (cliente.gcalendar?.activo ? 'Sí' : 'No') },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/gcalendar'
                                } : '')}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <hr />
                            <h3 align="center" >Notificaciones</h3>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Notificaciones Automáticas"
                                data={
                                    [
                                        { index: 'Email - Crea', value: (cliente.avisos_email.turno_creado ? 'Sí' : 'No') },
                                        { index: 'Email - Actualiza', value: (cliente.avisos_email.turno_actualizado ? 'Sí' : 'No') },
                                        { index: 'Email - Cancela', value: (cliente.avisos_email.turno_cancelado ? 'Sí' : 'No') },
                                        { index: 'Email - Notifica Admin', value: (cliente.avisos_email.notifica_admin ? 'Sí' : 'No') },
                                        { index: 'Email - Recordatorio de Turno', value: (cliente.avisos_email.recordatorio !== null ? 'Sí' : 'No') },
                                        { index: 'Email - Recordatorio de Pago', value: (cliente.avisos_email.recordatorio_pago ? 'Sí' : 'No') },
                                        { index: 'Whatsapp - Crea', value: (cliente.avisos_whatsapp.turno_creado ? 'Sí' : 'No') },
                                        { index: 'Whatsapp - Actualiza', value: (cliente.avisos_whatsapp.turno_actualizado ? 'Sí' : 'No') },
                                        { index: 'Whatsapp - Cancela', value: (cliente.avisos_whatsapp.turno_cancelado ? 'Sí' : 'No') },
                                        { index: 'Whatsapp - Notifica Admin', value: (cliente.avisos_whatsapp.notifica_admin ? 'Sí' : 'No') },
                                        { index: 'Whatsapp - Recordatorio', value: (cliente.avisos_whatsapp.recordatorio !== null ? 'Sí' : 'No') },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/avisos-automaticos'
                                } : '')}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <TextoConIcono texto="Email" justifyContent="center" icono={<EmailIcon />} />
                            <p align="center"><small>Para el Cliente</small></p>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                                fullWidth
                                color="dark"
                                style={{ backgroundColor: '#fff' }}
                            >
                                <Link to={(cliente.avisos_email.turno_creado ? "/clientes/" + urlParams.id + "/edit/email/turno-creado" : '#')}><Button disabled={!cliente.avisos_email.turno_creado || !LoginOutData.userHasPermiso(10)} startIcon={<AddIcon />} style={{ justifyContent: "flex-start" }}>Turno Creado</Button></Link>
                                <Link to={(cliente.avisos_email.turno_actualizado ? "/clientes/" + urlParams.id + "/edit/email/turno-actualizado" : '#')}><Button disabled={!cliente.avisos_email.turno_actualizado || !LoginOutData.userHasPermiso(10)} startIcon={<CachedIcon />} style={{ justifyContent: "flex-start" }}>Turno Actualizado</Button></Link>
                                <Link to={(cliente.avisos_email.turno_cancelado ? "/clientes/" + urlParams.id + "/edit/email/turno-cancelado" : '#')}><Button disabled={!cliente.avisos_email.turno_cancelado || !LoginOutData.userHasPermiso(10)} startIcon={<DeleteOutline />} style={{ justifyContent: "flex-start" }}>Turno Cancelado</Button></Link>
                                <Link to={(cliente.avisos_email.recordatorio ? "/clientes/" + urlParams.id + "/edit/email/turno-recordatorio" : '#')}><Button disabled={!cliente.avisos_email.recordatorio || !LoginOutData.userHasPermiso(10)} startIcon={<CampaignIcon />} style={{ justifyContent: "flex-start" }}>Recordatorio de Turno</Button></Link>
                                <Link to={(cliente.avisos_email.recordatorio_pago ? "/clientes/" + urlParams.id + "/edit/email/turno-recordatorio-pago" : '#')}><Button disabled={!cliente.avisos_email.recordatorio_pago || !LoginOutData.userHasPermiso(10)} startIcon={<CampaignIcon />} style={{ justifyContent: "flex-start" }}>Recordatorio de Pago</Button></Link>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <TextoConIcono texto="Whatsapp" justifyContent="center" icono={<WhatsAppIcon />} />
                            <p align="center"><small>Para el Cliente</small></p>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                                fullWidth
                                color="dark"
                                style={{ backgroundColor: '#fff' }}
                            >
                                <Link to={(cliente.avisos_whatsapp.turno_creado ? "/clientes/" + urlParams.id + "/edit/whatsapp/turno-creado" : "#")}><Button disabled={!cliente.avisos_whatsapp.turno_creado || !LoginOutData.userHasPermiso(10)} startIcon={<AddIcon />} style={{ justifyContent: "flex-start" }}>Turno Creado</Button></Link>
                                <Link to={(cliente.avisos_whatsapp.turno_actualizado ? "/clientes/" + urlParams.id + "/edit/whatsapp/turno-actualizado" : "#")}><Button disabled={!cliente.avisos_whatsapp.turno_actualizado || !LoginOutData.userHasPermiso(10)} startIcon={<CachedIcon />} style={{ justifyContent: "flex-start" }}>Turno Actualizado</Button></Link>
                                <Link to={(cliente.avisos_whatsapp.turno_cancelado ? "/clientes/" + urlParams.id + "/edit/whatsapp/turno-cancelado" : "#")}><Button disabled={!cliente.avisos_whatsapp.turno_cancelado || !LoginOutData.userHasPermiso(10)} startIcon={<DeleteOutline />} style={{ justifyContent: "flex-start" }}>Turno Cancelado</Button></Link>
                                <Link to={"/clientes/" + urlParams.id + "/edit/whatsapp/recordatorio-manual"}><Button disabled={!LoginOutData.userHasPermiso(10)} startIcon={<CampaignIcon />} style={{ justifyContent: "flex-start" }}>Recordatorio Manual</Button></Link>
                                <Link to={(cliente.avisos_whatsapp.recordatorio !== null ? "/clientes/" + urlParams.id + "/edit/whatsapp/recordatorio-automatico" : "#")}><Button disabled={cliente.avisos_whatsapp.recordatorio === null || !LoginOutData.userHasPermiso(10)} startIcon={<CampaignIcon />} style={{ justifyContent: "flex-start" }}>Recordatorio Automático</Button></Link>
                            </ButtonGroup>
                            <p align="center"><small>Para el Admin</small></p>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                                fullWidth
                                color="dark"
                                style={{ backgroundColor: '#fff' }}
                            >
                                <Link to={(cliente.avisos_whatsapp.notifica_admin ? "/clientes/" + urlParams.id + "/edit/whatsapp/admin-turno-creado" : "#")}><Button disabled={!cliente.avisos_whatsapp.notifica_admin || !LoginOutData.userHasPermiso(10)} startIcon={<AddIcon />} style={{ justifyContent: "flex-start" }}>Turno Creado</Button></Link>
                                <Link to={(cliente.avisos_whatsapp.notifica_admin ? "/clientes/" + urlParams.id + "/edit/whatsapp/admin-turno-actualizado" : "#")}><Button disabled={!cliente.avisos_whatsapp.notifica_admin || !LoginOutData.userHasPermiso(10)} startIcon={<CachedIcon />} style={{ justifyContent: "flex-start" }}>Turno Actualizado</Button></Link>
                                <Link to={(cliente.avisos_whatsapp.notifica_admin ? "/clientes/" + urlParams.id + "/edit/whatsapp/admin-turno-cancelado" : "#")}><Button disabled={!cliente.avisos_whatsapp.notifica_admin || !LoginOutData.userHasPermiso(10)} startIcon={<DeleteOutline />} style={{ justifyContent: "flex-start" }}>Turno Cancelado</Button></Link>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <hr />
                            <h3 align="center" >Facturación</h3>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Datos de Facturación"
                                data={
                                    [
                                        { index: 'Activo', value: (cliente.facturacion?.activo ? 'Sí' : 'No') },
                                        { index: 'Tipo Doc', value: cliente.facturacion?.tipo_documento },
                                        { index: 'N° Doc', value: cliente.facturacion?.numero_documento },
                                        { index: 'Condición IVA', value: cliente.facturacion?.condicion_iva },
                                        { index: 'Razón Social', value: cliente.facturacion?.razon_social },
                                        { index: 'Condición de Venta', value: cliente.facturacion?.condicion_venta },
                                        { index: 'Domicilio', value: cliente.facturacion?.domicilio },
                                        { index: 'Provincia', value: cliente.facturacion?.provincia },
                                    ]
                                }
                                editar={(LoginOutData.userHasPermiso(10) ? {
                                    path: '/clientes/' + cliente._id + '/edit/facturacion'
                                } : '')}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </>
        )

}