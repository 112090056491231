import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import Error from '../../elementos/Error';
import HeaderVista from '../../elementos/HeaderVista';
import Select from '../../elementos/Select';
import Snack from '../../elementos/Snack';

import LoginOutData from '../../helpers/LoginOutData';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import AccountCircle from '@mui/icons-material/AccountCircle';
import CheckIcon from '@mui/icons-material/Check';



export default function UsuariosEditar() {

    const [estadoVista, setEstadoVista] = useState('cargado')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })

    const [usuarioDelegar, setUsuarioDelegar] = useState('')
    const [vendedores, setVendedores] = useState([])

    const navigate = useNavigate()
    const urlParams = useParams()

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');


        axios.delete(Global.url_api + 'usuarios/' + urlParams.id + "/" + usuarioDelegar, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                axios.get(Global.url_api + 'self/refresh-token', { headers: { Authorization: localStorage.getItem("token") } })
                    .then(res => {

                        LoginOutData.LoginData(res)
                        navigate('/usuarios')

                    })
                    .catch((error) => {
                        setSnackOpen({
                            open: true,
                            message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                            severity: 'error'
                        })
                    });

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }


    useEffect(() => {

        axios.get(Global.url_api + 'usuarios/all-con-acceso', { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                let usuarios = res.data.usuarios
                let idVendedorBorra = res.data.usuarios.map(el => { return el._id }).indexOf(urlParams.id)
                usuarios.splice(idVendedorBorra, 1)
                setVendedores(usuarios)
                setUsuarioDelegar(res.data.self)

            })
            .catch((error) => {
                console.log(error?.response?.data)
                setEstadoVista('error')
            });





    }, [urlParams]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/usuarios"
                    titulo={"Eliminar Usuario"}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} >
                                <p>¿A qué usuario se le delegan los clientes?</p>
                                <Select
                                    label="Vendedor"
                                    icon={<AccountCircle />}
                                    value={usuarioDelegar}
                                    options={vendedores.map(function (e) {
                                        return {
                                            value: e._id,
                                            label: e.nombre
                                        }
                                    })}
                                    onChange={setUsuarioDelegar}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="error" startIcon={<CheckIcon />} >
                                    Eliminar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}