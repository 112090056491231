import React from 'react';

import ButtonIcon from './ButtonIcon';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default function Volver(props) {

    return (
        <ButtonIcon
            variant="outlined"
            size="small"
            color="neutral"
            startIcon={<ChevronLeftIcon />}
            to={props.to}
        />
    )

}