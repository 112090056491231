import { React, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import '../../../../assets/css/niceTable.css'

import axios from 'axios';
import { Global } from '../../../Global';


import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Snack from '../../../elementos/Snack';

import Grid from '@mui/material/Grid';

export default function EstadoClientes(props) {

    const [estadoVista, setEstadoVista] = useState('cargado')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })


    const [clientes, setClientes] = useState([])

    useEffect(() => {

        setEstadoVista('loading')

        axios.get(Global.url_api + 'reportes/clientes-estado-plan/' + props.vendedor, {
            headers: {
                Authorization: localStorage.getItem("token"),
                "from": props.fechaInicio,
                "to": props.fechaFin
            }
        })
            .then(res => {

                setClientes(res.data)
                setEstadoVista('cargado')

            })
            .catch((error) => {
                //console.log(error?.response?.data);

                setClientes([])

                setSnackOpen({
                    open: true,
                    message: error.response.data.message,
                    severity: 'error'
                })
            });

    }, [props])


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3 className="text-center" style={{ marginBottom: '25px' }}>
                            Estado Clientes
                        </h3>
                    </Grid>
                </Grid>
                <table className='niceTable' width="100%" cellSpacing={0}>
                    <thead>
                        <tr>
                            <td align='center'>Deuda</td>
                            <td align='left'>Cliente</td>
                            <td align='left'>Plan</td>
                            <td align='right'>Importe</td>
                            <td align='center'>Opciones</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clientes.length === 0 &&
                            <tr>
                                <td colSpan={5} align='center'>No hay datos.</td>
                            </tr>
                        }
                        {
                            clientes.map(function (item, key) {

                                let color_deuda = {}
                                if (item.meses_deuda > 1) color_deuda = { color: 'red', fontWeight: '600' }

                                return (
                                    <tr key={key}>
                                        <td align='center' style={color_deuda}>{item.meses_deuda}</td>
                                        <td align='left'>{item.dominio}</td>
                                        <td align='left'>{item.plan}</td>
                                        <td align='right'>
                                            <NumberFormat
                                                value={item.importe}
                                                className="foo"
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                prefix={'$ '}
                                            />
                                        </td>
                                        <td align='center'>
                                            -
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
            </>
        )

}