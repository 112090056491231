import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import Error from '../../elementos/Error';
import Card from '../../elementos/Card';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export default function ClienteEditRutas() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [css, setCss] = useState('');
    const [logo, setLogo] = useState('');
    const [socials, setSocials] = useState('');
    const [favicon, setFavicon] = useState('');
    const [banner1, setBanner1] = useState('');
    const [banner2, setBanner2] = useState('');

    const urlParams = useParams();


    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                setCss(res.data.rutas.css);
                setLogo(res.data.rutas.logo);
                setSocials(res.data.rutas.socials);
                setFavicon(res.data.rutas.favicon);
                setBanner1(res.data.rutas.banner1);
                setBanner2(res.data.rutas.banner2);

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Archivos e Imágenes " + dominio}
                />
                <Container className="nuevoRegistro" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <br />
                        <Grid item xs={12} >
                            <TituloMini titulo="Archivos e Imágenes">Estos archivos conforman la vista de clientes.</TituloMini>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="Logo"
                                img={logo}
                                descripcion="Es el logo de la marca. Esta imágen aparecerá en la vista de cliente."
                                confirmDelete={
                                    (logo !== '' ?
                                        {
                                            httpDelete: 'cliente/rutas/' + dominio + '/logo',
                                            returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (logo === '' ?
                                        ('/clientes/' + urlParams.id + '/edit/rutas/logo')
                                        : '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="Socials"
                                img={socials}
                                descripcion="Es la imágen que aparece al compartir el link por whatsapp, facebook, instagram o redes sociales."
                                confirmDelete={
                                    (socials !== '' ?
                                        {
                                            httpDelete: 'cliente/rutas/' + dominio + '/socials',
                                            returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (socials === '' ?
                                        ('/clientes/' + urlParams.id + '/edit/rutas/socials')
                                        : '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="Favicon"
                                img={favicon}
                                descripcion="Es el icono que aparece en la pestaña del navegador, tanto en la vista de clientes como administradores."
                                confirmDelete={
                                    (favicon !== '' ?
                                        {
                                            httpDelete: 'cliente/rutas/' + dominio + '/favicon',
                                            returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (favicon === '' ?
                                        ('/clientes/' + urlParams.id + '/edit/rutas/favicon')
                                        : '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="Banner 1"
                                img={banner1}
                                descripcion="Imagen de portada que aparece en la web (antes del listado de servicios)."
                                confirmDelete={
                                    (banner1 !== '' ?
                                        {
                                            httpDelete: 'cliente/rutas/' + dominio + '/banner1',
                                            returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (banner1 === '' ?
                                        ('/clientes/' + urlParams.id + '/edit/rutas/banner1')
                                        : '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="Banner 2"
                                img={banner2}
                                descripcion="Imagen de portada que aparece en la web (después del listado de servicios)."
                                confirmDelete={
                                    (banner2 !== '' ?
                                        {
                                            httpDelete: 'cliente/rutas/' + dominio + '/banner2',
                                            returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (banner2 === '' ?
                                        ('/clientes/' + urlParams.id + '/edit/rutas/banner2')
                                        : '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="CSS"
                                descripcion="Archivo de estilos si la marca pide personalizar la vista de cliente. No se utiliza casi nunca."
                                confirmDelete={
                                    (css !== '' ?
                                        {
                                            httpDelete: 'cliente/rutas/' + dominio + '/css',
                                            returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (css === '' ?
                                        ('/clientes/' + urlParams.id + '/edit/rutas/css')
                                        : '')
                                }
                            />
                        </Grid>



                    </Grid>
                </Container>
                <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >

                        </Grid>
                    </Grid>
                </Container>


            </>
        )

}