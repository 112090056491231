import { React, useState } from 'react';
import axios from 'axios';
import { Global } from '../Global';

import '../../assets/css/Login.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Input from '../elementos/Input';
import LogoHeader from '../elementos/LogoHeader';
import Loading from '../elementos/Loading';

import LoginOutData from '../helpers/LoginOutData';

import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';

export default function Login(props) {

    const [estadoVista, setEstadoVista] = useState("pendiente");
    const [mensajeError, setMensajeError] = useState("");
    const [email, setEmail] = useState("");
    const [contrasena, setContrasena] = useState("");


    const enviarForm = (e) => {
        e.preventDefault();

        let data = {
            email: email,
            contrasena: contrasena
        }

        setEstadoVista("loading");

        axios.post(Global.url_api + 'self/login', data)
            .then(res => {

                if ( res.data.usuario.permisos && res.data.usuario.permisos.length > 0 ) {
                    LoginOutData.LoginData(res);
                    props.changeUserSession('loggedin');
                }else{
                    setEstadoVista("pendiente")
                    setMensajeError("Aún no tienes permisos para acceder.")
                }

            })
            .catch((error) => {
                setEstadoVista("pendiente");
                console.log(error)
                setMensajeError(error.response.data.message);
            });

    }

    return (
        <>
            <Grid container spacing={0} style={{ background: '#FFE600', marginBottom: '35px', boxShadow: '0 2px 3px 0 rgba(0,0,0,0.2)' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <LogoHeader />
                </Grid>
            </Grid>
            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            {
                                estadoVista === 'pendiente' &&
                                <form onSubmit={enviarForm}>
                                    <h2 style={{ letterSpacing: '1px', fontWeight: '600' }}>¡Benvenuti!</h2>
                                    <Input
                                        fullWidth
                                        type="email"
                                        label="Usuario"
                                        variant="standard"
                                        icon={<PersonIcon />}
                                        restricciones={['email']}
                                        onChange={setEmail}
                                        autoFocus
                                        required
                                    />

                                    <br />
                                    <br />

                                    <Input
                                        fullWidth
                                        type="password"
                                        label="Contraseña"
                                        variant="standard"
                                        icon={<PasswordIcon />}
                                        onChange={setContrasena}
                                        required
                                    />

                                    <br />
                                    <br />

                                    <h3 className="error">{mensajeError}</h3>
                                    <p><Button type="submit" variant="outlined" color="success">
                                        Ingresar
                                    </Button></p>
                                </form>
                            }
                            {
                                estadoVista === 'loading' &&
                                <div><Loading /></div>
                            }
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}