import { React, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import '../../../../assets/css/niceTable.css'

import axios from 'axios';
import { Global } from '../../../Global';


import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Snack from '../../../elementos/Snack';

import Grid from '@mui/material/Grid';


export default function PagosYComisiones(props) {

    const [estadoVista, setEstadoVista] = useState('loading')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })


    const [facturado, setFacturado] = useState(0)
    const [desc_mercadopago, setDescMercadopago] = useState(0)
    const [desc_iva_afip, setDescIvaAfip] = useState(0)
    const [desc_iibb, setDescIibb] = useState(0)
    const [desc_impuesstoganancias, setDescImpuestoGanancias] = useState(0)
    const [comision_mercadopago, setComisionMercadopago] = useState(0)
    const [comision_iva, setComisionIva] = useState(0)
    const [comision_iibb, setComisionIibb] = useState(0)
    const [comision_impuestoganancias, setComisionImpuestoGanancias] = useState(0)
    const [totalGanancia, setTotalGanancia] = useState(0)
    const [totalComision, setTotalComision] = useState(0)

    useEffect(() => {

        setEstadoVista('loading')

        axios.get(Global.url_api + 'reportes/facturacion-por-vendedor/'+props.vendedor, {
            headers: {
                Authorization: localStorage.getItem("token"),
                "from": props.fechaInicio,
                "to": props.fechaFin
            }
        })
            .then(res => {

                setFacturado(res.data.facturacion.importe)
                setDescMercadopago(res.data.facturacion.desc_mercadopago)
                setDescIvaAfip(res.data.facturacion.desc_iva_afip)
                setDescIibb(res.data.facturacion.desc_iibb_conv_multilateral)
                setDescImpuestoGanancias(res.data.facturacion.desc_impuesto_ganancias)
                setComisionMercadopago(res.data.facturacion.impuestos.comision_mercadopago_mas_impuestos)
                setComisionIva(res.data.facturacion.impuestos.iva_afip)
                setComisionIibb(res.data.facturacion.impuestos.iibb_conv_multilateral)
                setComisionImpuestoGanancias(res.data.facturacion.impuestos.impuesto_ganancias)
                setTotalGanancia(res.data.facturacion.total_ganancia)
                setTotalComision(res.data.facturacion.total_comision)
                setEstadoVista('cargado');


            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: error.response.data.message,
                    severity: 'error'
                })
                setEstadoVista('cargado')
            });

    }, [props])


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <h3 className="text-center text-md-start" style={{ marginBottom: '25px' }}>
                            Pagos y Comisiones
                        </h3>
                    </Grid>
                </Grid>
                <table className='niceTable' width="100%" cellSpacing={0}>
                    <thead>
                        <tr>
                            <td>Detalle</td>
                            <td align='right'>Importe</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Facturado</td>
                            <td align='right'>
                                <NumberFormat
                                    value={facturado}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Mercadopago ({comision_mercadopago} %)</td>
                            <td align='right'>
                                <NumberFormat
                                    value={desc_mercadopago}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'-$ '}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>IVA AFIP ({comision_iva} %)</td>
                            <td align='right'>
                                <NumberFormat
                                    value={desc_iva_afip}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'-$ '}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>IIBB CONV. MULT. ({comision_iibb} %)</td>
                            <td align='right'>
                                <NumberFormat
                                    value={desc_iibb}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'-$ '}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>IMP GANANCIAS ({comision_impuestoganancias} %)</td>
                            <td align='right'>
                                <NumberFormat
                                    value={desc_impuesstoganancias}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'-$ '}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>TOTAL GANANCIA</td>
                            <td align='right'>
                                <NumberFormat
                                    value={totalGanancia}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>COMISIÓN VENDEDOR</td>
                            <td align='right'>
                                <NumberFormat
                                    value={totalComision}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
            </>
        )

}