
const LoginData = (response) => {

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("nombre", response.data.usuario.nombre);
    localStorage.setItem("email", response.data.usuario.email);
    localStorage.setItem("permisos", response.data.usuario.permisos);

}

const LogOutData = () => {

    localStorage.setItem("nombre", "");
    localStorage.setItem("email", "");
    localStorage.setItem("token", "");
    localStorage.setItem("permisos", "");

}

const userHasPermiso = (permiso) => {

    let permisos = JSON.parse("[0," + localStorage.getItem("permisos") + "]");
    
    if( !permisos.includes(permiso) ) return false;

    return true;

}

const LoginOutData = {LoginData, LogOutData, userHasPermiso}

export default LoginOutData;
