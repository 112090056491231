import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import SwitchInput from '../../elementos/SwitchInput';
import Input from '../../elementos/Input';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';

export default function ClienteCreateCamposAdicionalesTUrno() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [nombre, setNombre] = useState('');
    const [required, setRequired] = useState(false);
    const [leyenda, setLeyenda] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            nombre,
            required,
            leyenda,
        }

        axios.post(Global.url_api + 'cliente/campos-adicionales-turno/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Campos adicionales del turno "+ dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Campo Adicional">Los campos adicionales <b>del turno</b> se solicitan al momento de reservarse un turno nuevo.<br />Los campos adicionales solo se pueden crear/eliminar. Si deseas modificar, deberás eliminarlo y volver a crearlo.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Nombre"
                                    value={nombre}
                                    type="text"
                                    onChange={setNombre}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Input
                                    label="Leyenda"
                                    value={leyenda}
                                    onChange={setLeyenda}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Obligatorio"
                                    value={required}
                                    onChange={setRequired}
                                />
                            </Grid>



                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>

            </>
        )

}