import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';


import axios from 'axios';
import { Global } from '../../../Global';


import '../../../../assets/css/detalleRegistro.css';

import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Input from '../../../elementos/Input';
import Snack from '../../../elementos/Snack';
import HeaderVista from '../../../elementos/HeaderVista';
import Select from '../../../elementos/Select';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckIcon from '@mui/icons-material/Check';
import TodayIcon from '@mui/icons-material/Today';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function ClienteAppAgendaNuevo() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [nombre, setNombre] = useState('');
    const [frecuencia_horarios, setFrecuenciaHorarios] = useState('30');
    const [sucursal, setSucursal] = useState('');
    const [sucursales, setSucursales] = useState([]);

    const urlParams = useParams();

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            nombre,
            frecuencia_horarios,
            sucursal
        }

        axios.post(Global.url_api + 'mysql/agendas/' + urlParams.id, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: 'Se creó la agenda.',
                    severity: 'success'
                })
                navigate("/clientes/" + urlParams.id + "/app");

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }

    useEffect(() => {

        axios.get(Global.url_api + 'mysql/sucursales/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSucursales(res.data.sucursales);
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id + "/app"}
                    titulo="Nueva Agenda"
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Nombre"
                                    icon={<PersonIcon />}
                                    helperText="Ej: Trini"
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Frecuencia Horarios"
                                    icon={<AccessTimeIcon />}
                                    helperText="Fracción de horarios en minutos"
                                    value={frecuencia_horarios}
                                    onChange={setFrecuenciaHorarios}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12}></Grid>
                            <Grid item xs={12} md={4} >
                                <Select
                                    label="Sucursal"
                                    value={sucursal}
                                    icon={<LocationOnIcon />}
                                    options={sucursales.map((value, key) => {
                                        return({
                                            value: value.codigo,
                                            label: value.nombre
                                        })   
                                    })}
                                    onChange={setSucursal}
                                    helperText="Sucursal a la que pertenece la agenda"
                                    type="text"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}