import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import Error from '../../elementos/Error';
import Dropzone from '../../elementos/Dropzone';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export default function ClienteEditRutas() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [dominio, setDominio] = useState('');


    const urlParams = useParams();
    const navigate = useNavigate();

    var formatosPermitidos = "";
    if( ['logo', 'socials', 'banner1', 'banner2'].includes(urlParams.elemento) ){
        formatosPermitidos = 'image/jpeg, image/png';
    }else if( ['favicon'].includes(urlParams.elemento) ){
        formatosPermitidos = 'image/x-icon';
    }else if( ['css'].includes(urlParams.elemento) ){
        formatosPermitidos = 'text/css';
    }

    var maxSize = 0;
    if( ['logo', 'socials', 'css'].includes(urlParams.elemento) ){
        maxSize = 70000;
    }else if( ['banner1', 'banner2', 'favicon'].includes(urlParams.elemento) ){
        maxSize = 200000;
    }

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                if (res.data.rutas[urlParams.elemento] === '') {
                    setEstadoVista('cargado');
                } else {
                    navigate('/clientes/' + res.data._id + "/edit/rutas")
                }

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams, navigate]);

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id + "/edit/rutas"}
                    titulo={"Cargar " + urlParams.elemento + dominio}
                />
                <Container className="nuevoRegistro" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <br />
                        <Grid item xs={12} >
                            <TituloMini titulo={"Cargar Archivo"}>Aquí podrás cargar el archivo <b style={{ textTransform: 'uppercase' }}>{urlParams.elemento}</b>.</TituloMini>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Dropzone 
                            url={Global.url_api + "cliente/rutas/" + dominio + "/" + urlParams.elemento} 
                            returnUrl={'/clientes/' + urlParams.id + '/edit/rutas'}
                            formatosPermitidos={formatosPermitidos}
                            maxSize={maxSize}
                            maxFiles={1}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >

                        </Grid>
                    </Grid>
                </Container>




            </>
        )

}