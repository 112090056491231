import { React, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/elementos/Navbar';
import Sidebar from './components/elementos/Sidebar';

import './assets/css/Routes.css';

//Componentes Vistas
import Home from './components/vistas/Home';
import Clientes from './components/vistas/clientes/Clientes';
import ClienteNuevo from './components/vistas/clientes/ClienteNuevo';
import ClienteDetalle from './components/vistas/clientes/ClienteDetalle';
import ClienteEditMarca from './components/vistas/clientes/ClienteEditMarca';
import ClienteEditInfoContacto from './components/vistas/clientes/ClienteEditInfoContacto';
import ClienteEditColores from './components/vistas/clientes/ClienteEditColores';
import ClienteEditConfigreserva from './components/vistas/clientes/ClienteEditConfigreserva';
import ClienteEditFechas from './components/vistas/clientes/ClienteEditFechas';
import ClienteEditTurnoReprograma from './components/vistas/clientes/ClienteEditTurnoReprograma';
import ClienteEditPaquete from './components/vistas/clientes/ClienteEditPaquete';
import ClienteEditCobraSena from './components/vistas/clientes/ClienteEditCobraSena';
import ClienteEditMediosDePago from './components/vistas/clientes/ClienteEditMediosDePago';
import ClienteEditAvisosAutomaticos from './components/vistas/clientes/ClienteEditAvisosAutomaticos';
import ClienteEditEmailTurnoCreado from './components/vistas/clientes/ClienteEditEmailTurnoCreado';
import ClienteEditEmailTurnoActualizado from './components/vistas/clientes/ClienteEditEmailTurnoActualizado';
import ClienteEditEmailTurnoCancelado from './components/vistas/clientes/ClienteEditEmailTurnoCancelado';
import ClienteEditEmailTurnoRecordatorioAutomatico from './components/vistas/clientes/ClienteEditEmailTurnoRecordatorioAutomatico';
import ClienteEditEmailTurnoRecordatorioPago from './components/vistas/clientes/ClienteEditEmailTurnoRecordatorioPago';
import ClienteEditWhatsappTurnoCreado from './components/vistas/clientes/ClienteEditWhatsappTurnoCreado';
import ClienteEditWhatsappAdminTurnoCreado from './components/vistas/clientes/ClienteEditWhatsappAdminTurnoCreado';
import ClienteEditWhatsappAdminTurnoActualizado from './components/vistas/clientes/ClienteEditWhatsappAdminTurnoActualizado';
import ClienteEditWhatsappAdminTurnoCancelado from './components/vistas/clientes/ClienteEditWhatsappAdminTurnoCancelado';
import ClienteEditWhatsappTurnoActualizado from './components/vistas/clientes/ClienteEditWhatsappTurnoActualizado';
import ClienteEditWhatsappTurnoCancelado from './components/vistas/clientes/ClienteEditWhatsappTurnoCancelado';
import ClienteEditWhatsappTurnoRecordatorioManual from './components/vistas/clientes/ClienteEditWhatsappTurnoRecordatorioManual';
import ClienteEditWhatsappTurnoRecordatorioAutomatico from './components/vistas/clientes/ClienteEditWhatsappTurnoRecordatorioAutomatico';
import ClienteCreateCamposAdicionalesCliente from './components/vistas/clientes/ClienteCreateCamposAdicionalesCliente';
import ClienteDeleteCamposAdicionalesCliente from './components/vistas/clientes/ClienteDeleteCamposAdicionalesCliente';
import ClienteCreateCamposAdicionalesTurno from './components/vistas/clientes/ClienteCreateCamposAdicionalesTurno';
import ClienteDeleteCamposAdicionalesTurno from './components/vistas/clientes/ClienteDeleteCamposAdicionalesTurno';
import ClienteEditRutas from './components/vistas/clientes/ClienteEditRutas';
import ClienteEditRutasCreate from './components/vistas/clientes/ClienteEditRutasCreate';
import ClienteEditFacturacion from './components/vistas/clientes/ClienteEditFacturacion';
import ClienteEditZoom from './components/vistas/clientes/ClienteEditZoom';
import ClienteEditGooglecalendar from './components/vistas/clientes/ClienteEditGooglecalendar';
import ClienteEditMensajeHome from './components/vistas/clientes/ClienteEditMensajeHome';
import ClienteEditTerminosyCondiciones from './components/vistas/clientes/ClienteEditTerminosyCondiciones';
import ClientesEnviarComunicacionEmail from './components/vistas/clientes/ClientesEnviarComunicacionEmail';

import ClienteCuentaCorriente from './components/vistas/clientes/CuentaCorriente/ClienteCuentaCorriente';
import ClientePlanesNuevo from './components/vistas/clientes/CuentaCorriente/ClientePlanesNuevo';
import ClientePagosNuevo from './components/vistas/clientes/CuentaCorriente/ClientePagosNuevo';


import ClienteApp from './components/vistas/clientes/App/ClienteApp';
import ClienteAppSucursalNuevo from './components/vistas/clientes/App/SucursalNuevo';
import ClienteAppSucursalEditar from './components/vistas/clientes/App/SucursalEditar';
import ClienteAppAgendaNuevo from './components/vistas/clientes/App/AgendaNuevo';
import ClienteAppAgendaEditar from './components/vistas/clientes/App/AgendaEditar';
import ClienteAppUsuarioNuevo from './components/vistas/clientes/App/UsuarioNuevo';
import ClienteAppUsuarioEditar from './components/vistas/clientes/App/UsuarioEditar';


import Permisosapp from './components/vistas/permisosapp/Permisosapp';
import PermisosappNuevo from './components/vistas/permisosapp/PermisosappNuevo';
import PermisosappEdit from './components/vistas/permisosapp/PermisosappEdit';

import Planes from './components/vistas/planes/Planes';
import PlanesNuevo from './components/vistas/planes/PlanesNuevo';
import PlanesDetalle from './components/vistas/planes/PlanesDetalle';
import PlanesEdit from './components/vistas/planes/PlanesEdit';

import Gastos from './components/vistas/gastos/Gastos';
import GastosNuevo from './components/vistas/gastos/GastosNuevo';
import GastosEdit from './components/vistas/gastos/GastosEdit';
import GastosRubros from './components/vistas/gastos/Rubros';
import RubrosNuevo from './components/vistas/gastos/RubrosNuevo';
import RubrosEdit from './components/vistas/gastos/RubrosEdit';

import Usuarios from './components/vistas/usuarios/Usuarios';
import UsuariosNuevo from './components/vistas/usuarios/UsuariosNuevo';
import UsuariosEditar from './components/vistas/usuarios/UsuariosEditar';
import UsuariosEliminar from './components/vistas/usuarios/UsuariosEliminar';

import WatsappEnviados from './components/vistas/whatsapp/WatsappEnviados';
import WatsappEnviado from './components/vistas/whatsapp/WatsappEnviado';
import WatsappFallidos from './components/vistas/whatsapp/WatsappFallidos';
import WatsappFallido from './components/vistas/whatsapp/WatsappFallido';

import ReportesHome from './components/vistas/reportes/Home';

import MiPerfilEditar from './components/vistas/miPerfil/Editar';



export default function Router( props ) {
  
  const [sideBar, setSidebar] = useState(window.innerWidth >= 768);

  const handleToggleSidebar = () => {
    setSidebar(!sideBar);
  }

  return(
    <BrowserRouter>
      <Navbar 
        changeUserSession={props.changeUserSession}
        toggleSidebar={handleToggleSidebar}
      />
      <Sidebar 
        sideBar={sideBar}
        setSidebar={setSidebar}
      />
      <div id="routesContainer" className={(sideBar ? '' : 'extendido')}>
        <div id="contenedor">
        <Routes >

            <Route path="/" element={<Home />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/clientes/nuevo" element={<ClienteNuevo />} />
            <Route path="clientes/enviar-comunicacion-email" element={<ClientesEnviarComunicacionEmail />} />
            <Route path="/clientes/:id" element={<ClienteDetalle />} />
            <Route path="/clientes/:id/edit/marca" element={<ClienteEditMarca />} />
            <Route path="/clientes/:id/edit/info-de-contacto" element={<ClienteEditInfoContacto />} />
            <Route path="/clientes/:id/edit/colores" element={<ClienteEditColores />} />
            <Route path="/clientes/:id/edit/config-reserva" element={<ClienteEditConfigreserva />} />
            <Route path="/clientes/:id/edit/fechas" element={<ClienteEditFechas />} />
            <Route path="/clientes/:id/edit/turno-reprograma" element={<ClienteEditTurnoReprograma />} />
            <Route path="/clientes/:id/edit/paquetes" element={<ClienteEditPaquete />} />
            <Route path="/clientes/:id/edit/cobra-sena" element={<ClienteEditCobraSena />} />
            <Route path="/clientes/:id/edit/medios-de-pago" element={<ClienteEditMediosDePago />} />
            <Route path="/clientes/:id/edit/avisos-automaticos" element={<ClienteEditAvisosAutomaticos />} />
            <Route path="/clientes/:id/edit/email/turno-creado" element={<ClienteEditEmailTurnoCreado />} />
            <Route path="/clientes/:id/edit/email/turno-actualizado" element={<ClienteEditEmailTurnoActualizado />} />
            <Route path="/clientes/:id/edit/email/turno-cancelado" element={<ClienteEditEmailTurnoCancelado />} />
            <Route path="/clientes/:id/edit/email/turno-recordatorio" element={<ClienteEditEmailTurnoRecordatorioAutomatico />} />
            <Route path="/clientes/:id/edit/email/turno-recordatorio-pago" element={<ClienteEditEmailTurnoRecordatorioPago />} />
            <Route path="/clientes/:id/edit/whatsapp/turno-creado" element={<ClienteEditWhatsappTurnoCreado />} />
            <Route path="/clientes/:id/edit/whatsapp/turno-actualizado" element={<ClienteEditWhatsappTurnoActualizado />} />
            <Route path="/clientes/:id/edit/whatsapp/turno-cancelado" element={<ClienteEditWhatsappTurnoCancelado />} />
            <Route path="/clientes/:id/edit/whatsapp/admin-turno-creado" element={<ClienteEditWhatsappAdminTurnoCreado />} />
            <Route path="/clientes/:id/edit/whatsapp/admin-turno-actualizado" element={<ClienteEditWhatsappAdminTurnoActualizado />} />
            <Route path="/clientes/:id/edit/whatsapp/admin-turno-cancelado" element={<ClienteEditWhatsappAdminTurnoCancelado />} />
            <Route path="/clientes/:id/edit/whatsapp/recordatorio-manual" element={<ClienteEditWhatsappTurnoRecordatorioManual />} />
            <Route path="/clientes/:id/edit/whatsapp/recordatorio-automatico" element={<ClienteEditWhatsappTurnoRecordatorioAutomatico />} />
            <Route path="/clientes/:id/create/campos-adicionales/cliente" element={<ClienteCreateCamposAdicionalesCliente />} />
            <Route path="/clientes/:id/delete/campos-adicionales/cliente/:index" element={<ClienteDeleteCamposAdicionalesCliente />} />
            <Route path="/clientes/:id/create/campos-adicionales/turno" element={<ClienteCreateCamposAdicionalesTurno />} />
            <Route path="/clientes/:id/delete/campos-adicionales/turno/:index" element={<ClienteDeleteCamposAdicionalesTurno />} />
            <Route path="clientes/:id/edit/rutas" element={<ClienteEditRutas />} />
            <Route path="clientes/:id/edit/rutas/:elemento" element={<ClienteEditRutasCreate />} />
            <Route path="clientes/:id/edit/facturacion" element={<ClienteEditFacturacion />} />
            <Route path="clientes/:id/edit/zoom" element={<ClienteEditZoom />} />
            <Route path="clientes/:id/edit/gcalendar" element={<ClienteEditGooglecalendar />} />
            <Route path="clientes/:id/edit/mensaje-home" element={<ClienteEditMensajeHome />} />
            <Route path="clientes/:id/edit/terminosycondiciones" element={<ClienteEditTerminosyCondiciones />} />

            <Route path="/clientes/:id/cuenta-corriente/" element={<ClienteCuentaCorriente />} />
            <Route path="/clientes/:id/cuenta-corriente/planes/nuevo" element={<ClientePlanesNuevo />} />
            <Route path="/clientes/:id/cuenta-corriente/pagos/nuevo" element={<ClientePagosNuevo />} />

            <Route path="/clientes/:id/app/" element={<ClienteApp />} />
            <Route path="/clientes/:id/app/sucursal/nuevo" element={<ClienteAppSucursalNuevo />} />
            <Route path="/clientes/:id/app/sucursal/editar/:codigo" element={<ClienteAppSucursalEditar />} />
            <Route path="/clientes/:id/app/agenda/nuevo" element={<ClienteAppAgendaNuevo />} />
            <Route path="/clientes/:id/app/agenda/editar/:codigo" element={<ClienteAppAgendaEditar />} />
            <Route path="/clientes/:id/app/usuario/nuevo" element={<ClienteAppUsuarioNuevo />} />
            <Route path="/clientes/:id/app/usuario/editar/:codigo" element={<ClienteAppUsuarioEditar />} />

            <Route path="/planes" element={<Planes />} />
            <Route path="/planes/nuevo" element={<PlanesNuevo />} />
            <Route path="/planes/:id" element={<PlanesDetalle />} />
            <Route path="/planes/edit/:id" element={<PlanesEdit />} />

            <Route path="/gastos" element={<Gastos />} />
            <Route path="/gastos/nuevo" element={<GastosNuevo />} />
            <Route path="/gastos/edit/:id" element={<GastosEdit />} />
            <Route path="/gastos/rubros" element={<GastosRubros />} />
            <Route path="/gastos/rubros/nuevo" element={<RubrosNuevo />} />
            <Route path="/gastos/rubros/edit/:id" element={<RubrosEdit />} />

            <Route path="/permisosapp" element={<Permisosapp />} />
            <Route path="/permisosapp/nuevo" element={<PermisosappNuevo />} />
            <Route path="/permisosapp/edit/:id" element={<PermisosappEdit />} />

            <Route path="/usuarios" element={<Usuarios />} />
            <Route path="/usuarios/nuevo" element={<UsuariosNuevo />} />
            <Route path="/usuarios/:id" element={<UsuariosEditar />} />
            <Route path="/usuarios/delete/:id" element={<UsuariosEliminar />} />

            <Route path="/whatsapp/enviados/" element={<WatsappEnviados />} />
            <Route path="/whatsapp/fallidos/" element={<WatsappFallidos />} />
            <Route path="/whatsapp/enviados/:id" element={<WatsappEnviado />} />
            <Route path="/whatsapp/fallidos/:id" element={<WatsappFallido />} />

            <Route path="/reportes" element={<ReportesHome />} />

            <Route path="/mi-perfil" element={<MiPerfilEditar />} />

        </Routes>
        </div>
      </div>

    </BrowserRouter>
  );
     

  
}