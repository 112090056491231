import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import SwitchInput from '../../elementos/SwitchInput';
import Input from '../../elementos/Input';
import Select from '../../elementos/Select';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditFacturacion() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [activo, setActivo] = useState(false);
    const [tipo_documento, setTipoDocumento] = useState('');
    const [numero_documento, setNumeroDocumento] = useState('');
    const [condicion_iva, setCondicionIva] = useState('');
    const [razon_social, setRazonSocial] = useState('');
    const [condicion_venta, setCondicionVenta] = useState('');
    const [domicilio, setDomicilio] = useState('');
    const [provincia, setProvincia] = useState('');

    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [tiposCondicionIva, setTiposCondicionIva] = useState([]);

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                if (res.data.facturacion && res.data.facturacion.activo) setActivo(true)
                if (res.data.facturacion && res.data.facturacion.tipo_documento) setTipoDocumento(res.data.facturacion.tipo_documento)
                if (res.data.facturacion && res.data.facturacion.numero_documento) setNumeroDocumento(res.data.facturacion.numero_documento)
                if (res.data.facturacion && res.data.facturacion.condicion_iva) setCondicionIva(res.data.facturacion.condicion_iva)
                if (res.data.facturacion && res.data.facturacion.razon_social) setRazonSocial(res.data.facturacion.razon_social)
                if (res.data.facturacion && res.data.facturacion.condicion_venta) setCondicionVenta(res.data.facturacion.condicion_venta)
                if (res.data.facturacion && res.data.facturacion.domicilio) setDomicilio(res.data.facturacion.domicilio)
                if (res.data.facturacion && res.data.facturacion.provincia) setProvincia(res.data.facturacion.provincia)

                axios.get(Global.url_api.replace("adm", "tipos-afip") + 'documentos/')
                    .then(res => {

                        setTiposDocumentos(res.data);

                        axios.get(Global.url_api.replace("adm", "tipos-afip") + 'condicion-iva/')
                            .then(res => {

                                setTiposCondicionIva(res.data);

                                setEstadoVista('cargado');

                            })
                            .catch((error) => {
                                console.log(error?.response?.data);
                                setEstadoVista('error')
                            });

                    })
                    .catch((error) => {
                        console.log(error?.response?.data);
                        setEstadoVista('error')
                    });

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            activo,
            tipo_documento,
            numero_documento,
            condicion_iva,
            razon_social,
            condicion_venta,
            domicilio,
            provincia,
        }

        axios.put(Global.url_api + 'cliente/update/facturacion/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Facturación " + dominio}
                />

                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Facturación">Indica si se le hace factura al cliente cada vez que realiza el pago del plan.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <SwitchInput
                                    label="Activo"
                                    value={activo}
                                    onChange={setActivo}
                                    helperText="¿Se hace factura?"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} ></Grid>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Tipo Documento"
                                    value={tipo_documento}
                                    options={tiposDocumentos.map(elem => {
                                        return {
                                            value: elem.codigo,
                                            label: elem.descripcion,
                                        }
                                    })}
                                    onChange={setTipoDocumento}
                                    required={activo}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="N° Documento"
                                    value={numero_documento}
                                    onChange={setNumeroDocumento}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required={activo}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Condición IVA"
                                    value={condicion_iva}
                                    options={tiposCondicionIva.map(elem => {
                                        return {
                                            value: elem.codigo,
                                            label: elem.descripcion,
                                        }
                                    })}
                                    onChange={setCondicionIva}
                                    required={activo}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Razón Social"
                                    value={razon_social}
                                    onChange={setRazonSocial}
                                    type="text"
                                    required={activo}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Condición de Venta"
                                    value={condicion_venta}
                                    onChange={setCondicionVenta}
                                    type="text"
                                    helperText="Colocar 'Contado'"
                                    required={activo}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Domicilio"
                                    value={domicilio}
                                    onChange={setDomicilio}
                                    type="text"
                                    helperText="Calle y Número"
                                    required={activo}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Provincia"
                                    value={provincia}
                                    options={[
                                        {
                                            value: 'Ciudad de Buenos Aires',
                                            label: 'Ciudad de Buenos Aires'
                                        },
                                        {
                                            value: 'Buenos Aires',
                                            label: 'Buenos Aires'
                                        },
                                        {
                                            value: 'Catamarca',
                                            label: 'Catamarca'
                                        },
                                        {
                                            value: 'Chaco',
                                            label: 'Chaco'
                                        },
                                        {
                                            value: 'Chubut',
                                            label: 'Chubut'
                                        },
                                        {
                                            value: 'Córdoba',
                                            label: 'Córdoba'
                                        },
                                        {
                                            value: 'Corrientes',
                                            label: 'Corrientes'
                                        },
                                        {
                                            value: 'Entre Ríos',
                                            label: 'Entre Ríos'
                                        },
                                        {
                                            value: 'Formosa',
                                            label: 'Formosa'
                                        },
                                        {
                                            value: 'Jujuy',
                                            label: 'Jujuy'
                                        },
                                        {
                                            value: 'La Pampa',
                                            label: 'La Pampa'
                                        },
                                        {
                                            value: 'La Rioja',
                                            label: 'La Rioja'
                                        },
                                        {
                                            value: 'Mendoza',
                                            label: 'Mendoza'
                                        },
                                        {
                                            value: 'Misiones',
                                            label: 'Misiones'
                                        },
                                        {
                                            value: 'Neuquén',
                                            label: 'Neuquén'
                                        },
                                        {
                                            value: 'Río Negro',
                                            label: 'Río Negro'
                                        },
                                        {
                                            value: 'Salta',
                                            label: 'Salta'
                                        },
                                        {
                                            value: 'San Juan',
                                            label: 'San Juan'
                                        },
                                        {
                                            value: 'San Luis',
                                            label: 'San Luis'
                                        },
                                        {
                                            value: 'Santa Cruz',
                                            label: 'Sana Cruz'
                                        },
                                        {
                                            value: 'Santa Fe',
                                            label: 'Santa Fe'
                                        },
                                        {
                                            value: 'Santiago del Estero',
                                            label: 'Santiago del Estero'
                                        },
                                        {
                                            value: 'Tierra del Fuego',
                                            label: 'Tierra del Fuego'
                                        },
                                        {
                                            value: 'Tucumán',
                                            label: 'Tucumán'
                                        }
                                    ]}
                                    onChange={setProvincia}
                                    required={activo}
                                />
                            </Grid>


                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}