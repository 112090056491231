import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import Input from '../../elementos/Input';
import Select from '../../elementos/Select';
import Snack from '../../elementos/Snack';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import AccountCircle from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HttpIcon from '@mui/icons-material/Http';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import StoreIcon from '@mui/icons-material/Store';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';


export default function ClienteNuevo() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [vendedores, setVendedores] = useState([])

    const [dominio, setDominio] = useState('');
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');


    const [lenguaje, setLenguaje] = useState('es_ar');
    const [marca_nombre, setMarca_nombre] = useState('');
    const [marca_email, setMarca_email] = useState('');
    const [marca_ejcel, setMarca_ejcel] = useState('');
    const [marca_telefono, setMarca_telefono] = useState('');
    const [marca_mapa, setMarca_mapa] = useState('');
    const [marca_direccion, setMarca_direccion] = useState('');
    const [marca_telpais, setMarca_telpais] = useState('549');
    const [marca_telarea, setMarca_telarea] = useState('11');
    const [vendedor, setVendedor] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            dominio,
            nombre,
            telefono,
            email,
            lenguaje,
            marca_nombre,
            marca_email,
            marca_ejcel,
            marca_telefono,
            marca_mapa,
            marca_direccion,
            marca_telpais,
            marca_telarea,
            vendedor,
        }

        axios.post(Global.url_api + 'cliente', body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + res.data.id);

            })
            .catch((error) => {

                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');

            });

    }

    useEffect(() => {

        axios.get(Global.url_api + 'usuarios/all', { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setVendedores(res.data.usuarios);
                setEstadoVista('cargado')

            });
    }, [])

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/clientes"
                    titulo="Nuevo Cliente"
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">

                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TituloMini titulo="Info de Contacto">Información de contacto de uso interna de doyTurnos.com</TituloMini>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Dominio"
                                    icon={<HttpIcon />}
                                    helperText="Sólo letras y números"
                                    value={dominio}
                                    onChange={setDominio}
                                    type="text"
                                    restricciones={['alfanumerico']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Nombre"
                                    icon={<AccountCircle />}
                                    helperText="Del contacto o dueño"
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Teléfono"
                                    icon={<WhatsAppIcon />}
                                    helperText="Del contacto o dueño"
                                    value={telefono}
                                    onChange={setTelefono}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Email"
                                    icon={<MailOutlineIcon />}
                                    helperText="Del contacto o dueño"
                                    value={email}
                                    onChange={setEmail}
                                    type="email"
                                    restricciones={['email']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Vendedor"
                                    icon={<PersonIcon />}
                                    value={vendedor}
                                    options={vendedores.map(function (e) {
                                        return {
                                            value: e._id,
                                            label: e.nombre
                                        }
                                    })}
                                    onChange={setVendedor}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TituloMini titulo="Info de Marca">Información que visualizarán los clientes que deseen reservar un turno.</TituloMini>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Nombre de Marca"
                                    icon={<StoreIcon />}
                                    value={marca_nombre}
                                    onChange={setMarca_nombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Email"
                                    icon={<MailOutlineIcon />}
                                    value={marca_email}
                                    onChange={setMarca_email}
                                    type="email"
                                    restricciones={['email']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Dirección (ej: Calle 123, Barrio)"
                                    icon={<LocationOnIcon />}
                                    value={marca_direccion}
                                    onChange={setMarca_direccion}
                                    type="text"
                                    helperText="Sin dirección poner #"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="URL Mapa"
                                    icon={<LocationOnIcon />}
                                    value={marca_mapa}
                                    onChange={setMarca_mapa}
                                    type="text"
                                    helperText="Sin dirección poner #"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Ej Cel"
                                    icon={<WhatsAppIcon />}
                                    helperText="Formato 11-3333-5555. Sin cel #."
                                    value={marca_ejcel}
                                    onChange={setMarca_ejcel}
                                    type="tel"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Whatsapp para clientes"
                                    icon={<WhatsAppIcon />}
                                    helperText="Formato 5491133335555. Sin cel #."
                                    value={marca_telefono}
                                    onChange={setMarca_telefono}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Código de País (ej: 549)"
                                    icon={<WhatsAppIcon />}
                                    value={marca_telpais}
                                    onChange={setMarca_telpais}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Código de Área (ej: 11)"
                                    icon={<WhatsAppIcon />}
                                    value={marca_telarea}
                                    onChange={setMarca_telarea}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Lenguaje"
                                    icon={<PublicIcon />}
                                    value={lenguaje}
                                    options={[
                                        {
                                            value: 'es_ar',
                                            label: 'Argentina'
                                        },
                                        {
                                            value: 'es_cl',
                                            label: 'Chile'
                                        }
                                    ]}
                                    onChange={setLenguaje}
                                    type="text"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}