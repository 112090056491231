import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../components/Global';

import Loading from '../elementos/Loading';
import Input from '../elementos/Input';
import ButtonIcon from '../elementos/ButtonIcon';
import Snack from '../elementos/Snack';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function ConfirmDelete(props) {

    const [estadoVista, setEstadoVista] = useState('cargado');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [openDialog, setOpenDialog] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpenDialog(true);
    }

    const handleClose = () => {
        setOpenDialog(false);
    }

    const handleChangeInputValue = (value) => {

        if (value === 'eliminar') {
            setInputError(false);
        } else {
            setInputError(true);
        }

        setInputValue(value);

    }

    const handleConfirmDelete = () => {

        if (inputValue === 'eliminar') {

            setEstadoVista('loading');

            axios.delete(Global.url_api + props.httpDelete, { data: props.httpBody, headers: { Authorization: localStorage.getItem("token") } })
                .then(res => {

                    setInputValue('');
                    setOpenDialog(false);
                    setEstadoVista('cargado');
                    navigate(props.returnUrl);

                })
                .catch((error) => {
                    setSnackOpen({
                        open: true,
                        message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                        severity: 'error'
                    });
                    setEstadoVista('cargado');
                });

        }

    }


    return (
        <>
            {
                props.soloIcono &&
                <ButtonIcon color="error" handleClick={handleOpen} size={props.size} variant={props.variant} noLink startIcon={<DeleteOutlineIcon />} />
            }
            {
                !props.soloIcono &&
                <Button type="button" onClick={handleOpen} variant="contained" color="error" startIcon={<DeleteOutlineIcon />} >
                    Eliminar
                </Button>
            }

            <Dialog open={openDialog} onClose={handleOpen}>
                <DialogTitle>¿Eliminar registro?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro que deseas eliminar el registro?
                        <br />Por favor ingresa la palabra <b>eliminar</b> para continuar.
                    </DialogContentText>
                    <Input
                        type="text"
                        fullWidth
                        value={inputValue}
                        variant="standard"
                        error={inputError}
                        onChange={handleChangeInputValue}
                    />
                </DialogContent>
                {
                    estadoVista === 'cargado' &&
                    <DialogActions>
                        <Button variant="contained" color="neutral" onClick={handleClose}>Cancelar</Button>
                        <Button variant="contained" color="error" onClick={handleConfirmDelete} startIcon={<DeleteOutlineIcon />}>Eliminar</Button>
                    </DialogActions>
                }
                {
                    estadoVista === 'loading' &&
                    <DialogActions>
                        <Loading />
                    </DialogActions>
                }

            </Dialog>
            <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
        </>
    )

}