import { React, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

import axios from 'axios';
import { Global } from '../../Global';

import Loading from '../../elementos/Loading';
import ButtonIcon from '../../elementos/ButtonIcon';
import HeaderVista from '../../elementos/HeaderVista';
import Snack from '../../elementos/Snack';
import Error from '../../elementos/Error';

import LoginOutData from '../../helpers/LoginOutData';

import '../../../assets/css/table.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';


import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import PaidIcon from '@mui/icons-material/Paid';


const delay = 0.7;

export default function Planes() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [planes, setPlanes] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocs, setTotalDocs] = useState(0);
    const [textoSearch, setTextoSearch] = useState('');

    useEffect(() => {

        let timer1 = setTimeout(() => {

            let data = {
                page: (page ? page : 1),
                nombre: (textoSearch ? textoSearch : '')
            }

            axios.post(Global.url_api + 'planes/get', data, { headers: { Authorization: localStorage.getItem("token") } })
                .then(res => {

                    setPlanes(res.data.planes.registros)
                    setTotalPages(res.data.planes.totalPages)
                    setTotalDocs(res.data.planes.totalItems)
                    setEstadoVista('cargado')

                })
                .catch((error) => {
                    setEstadoVista('error');
                    setSnackOpen({
                        open: true,
                        message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                        severity: 'error'
                    });
                });


        }, delay * 1000);


        return () => {
            clearTimeout(timer1);
        };

    }, [textoSearch, page]);

    const handleChangeTextSearch = (e) => {
        setPage(1)
        setTextoSearch(e.target.value)
    }

    const handleGenerarEnlace = (idPlan, nombrePlan) => {

        navigator.clipboard.writeText("https://doyturnos.com/pago/"+idPlan);

        setSnackOpen({
            open: true,
            message: "¡Link de "+nombrePlan+" copiado!",
            severity: 'success'
        });

    }

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<><Error /><Snack estado={snackOpen} setSnackOpen={setSnackOpen} /></>)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    titulo="Planes"
                    icon={<AutoAwesomeMotionIcon />}
                    end={(LoginOutData.userHasPermiso(2) ? <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<AddIcon />}
                        to="/planes/nuevo"
                    >
                        Nuevo
                    </ButtonIcon> : '')}
                />
                <Container className="containerBigTable" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="titulo" item xs={12} md={8}>
                            <p>Se encontraron {totalDocs} registros.</p>
                        </Grid>
                        <Grid className="buscador" item xs={12} md={4}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField onChange={handleChangeTextSearch} fullWidth label="Buscar..." variant="standard" />
                            </Box>
                        </Grid>
                    </Grid>
                    <div className="tableContainer">
                        <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th align="right">Agendas</th>
                                    <th align="left">Nombre</th>
                                    <th align="left">Período</th>
                                    <th align="right">Importe</th>
                                    <th align="center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    planes.length === 0 &&
                                    <tr>
                                        <td colSpan={5} align='center'>
                                            No hay datos.
                                        </td>
                                    </tr>
                                }
                                {planes.map((value, key) => {
                                    return (
                                        <tr key={key} >
                                            <td align="right">{value.cant_agendas}</td>
                                            <td align="left">{value.nombre}</td>
                                            <td align="left">{value.periodo}</td>
                                            <td align="right">
                                                <NumberFormat
                                                    value={value.importe}
                                                    className="foo"
                                                    displayType={'text'}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    prefix={'$'}
                                                /></td>
                                            <td align="center">
                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    startIcon={<SearchIcon />}
                                                    to={'/planes/' + value.id}
                                                    marginRight
                                                />
                                                {
                                                    LoginOutData.userHasPermiso(3) &&
                                                    <ButtonIcon
                                                        variant="outlined"
                                                        size="small"
                                                        color="neutral"
                                                        endIcon={<EditIcon />}
                                                        to={'/planes/edit/' + value.id}
                                                        marginRight
                                                    />
                                                }
                                                <Button
                                                    onClick={() => handleGenerarEnlace(value.id, value.nombre)}
                                                    className="ButtonIcon"
                                                    variant="outlined"
                                                    color="success"
                                                    startIcon=""
                                                    endIcon={<PaidIcon />}
                                                >
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        className="table-pagination"
                        showFirstButton showLastButton
                        shape="rounded"
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />

                </Container>
                <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
            </>
        )

}