import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';


import axios from 'axios';
import { Global } from '../../../Global';

import LoginOutData from '../../../helpers/LoginOutData';

import '../../../../assets/css/detalleRegistro.css';

import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import ButtonIcon from '../../../elementos/ButtonIcon';
import ConfirmDelete from '../../../elementos/ConfirmDelete';


import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';

export default function Agendas() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [agendas, setAgendas] = useState({});

    const urlParams = useParams();



    useEffect(() => {

        axios.get(Global.url_api + 'mysql/agendas/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setAgendas(res.data.agendas);
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={6} style={{ marginBottom: '0px', fontSize: '1.2rem' }}>
                        <PersonIcon /> Agendas ({agendas.length})
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div align="right">
                            {
                                LoginOutData.userHasPermiso(11) &&
                                <ButtonIcon
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    to={"/clientes/" + urlParams.id + "/app/agenda/nuevo"}
                                >
                                    Nuevo
                                </ButtonIcon>
                            }
                        </div>
                    </Grid>
                </Grid>
                <div className="tableContainer">
                    <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th align="center">Codigo</th>
                                <th align="left">Sucursal</th>
                                <th align="left">Nombre</th>
                                <th align="right">Frecuencia Horarios</th>
                                <th align="center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {agendas.map((value, key) => {
                                return (
                                    <tr key={key} >
                                        <td align="center">{value.codigo}</td>
                                        <td align="left">{value.sucursal_nombre}</td>
                                        <td align="left">{value.nombre}</td>
                                        <td align="right">{value.frecuencia_horarios}</td>
                                        <td align="center">
                                            {
                                                LoginOutData.userHasPermiso(11) &&
                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="neutral"
                                                    endIcon={<EditIcon />}
                                                    to={'/clientes/' + urlParams.id + '/app/agenda/editar/' + value.codigo}
                                                    marginRight
                                                />
                                            }
                                            {
                                                LoginOutData.userHasPermiso(11) &&
                                                <ConfirmDelete
                                                    variant="outlined"
                                                    size="small"
                                                    httpDelete={"mysql/agendas/" + urlParams.id + "/" + value.codigo}
                                                    returnUrl={"/clientes/" + urlParams.id + "/app"}
                                                    soloIcono
                                                />
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        )

}