import { React, useEffect, useState } from 'react';
import '../../../../assets/css/niceTable.css'


import axios from 'axios';
import { Global } from '../../../Global';

import { Line } from 'react-chartjs-2';
import 'chart.js/auto';


import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Snack from '../../../elementos/Snack';

import nombreDelMes from '../../../helpers/NombreMes'

export default function AltaYBajaClientes(props) {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [dataFacturacionPlan, setDataFacturacionPlan] = useState({ labels: [], datasets: [] })

    useEffect(() => {

        setEstadoVista('loading')

        axios.get(Global.url_api + 'reportes/clientes-alta-baja/' + props.vendedor, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        })
            .then(res => {
                setDataFacturacionPlan({
                    labels: res.data.map(e => nombreDelMes(e.fecha, false, true, true)),
                    datasets: [
                        {
                            label: 'Altas',
                            borderColor: "#47D800",
                            backgroundColor: "rgba(71,216,0,0.1)",
                            data: res.data.map(e => e.cant_ini),
                            fill: false,
                            yAxisID: "yAxis1",
                        },
                        {
                            label: 'Bajas',
                            data: res.data.map(e => e.cant_fin),
                            borderColor: "#B73333",
                            backgroundColor: "rgba(183,51,51,0.1)",
                            fill: false,
                            yAxisID: "yAxis1",
                        },
                        {
                            label: 'Cantidad',
                            data: res.data.map(e => e.cant_clientes),
                            borderColor: "#000",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            fill: true,
                            yAxisID: "yAxis2",
                        }
                    ]
                })

                if (res.data.length === 0) setDataFacturacionPlan({ labels: [], datasets: [] })

                setEstadoVista('cargado');

            })
            .catch(error => {
                //console.log(error?.response?.data);
                /*setSnackOpen({
                    open: true,
                    message: error.response.data.message,
                    severity: 'error'
                })*/
                setEstadoVista('cargado')
            })

    }, [props]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <h3 align="center">Altas VS Bajas</h3>
                <Line
                    datasetIdKey="alta-y-baja"
                    height={75}
                    data={dataFacturacionPlan}
                    options={{
                        scales: {
                            yAxis1: {
                                type: "linear",
                                display: true,
                                position: "left",
                            },
                            yAxis2: {
                                type: "linear",
                                display: false,
                                position: 'right'
                            },
                        },
                    }}
                />
                <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
            </>
        )

}