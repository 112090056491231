import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import Input from '../../elementos/Input';
import Select from '../../elementos/Select';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';


export default function ClienteEditMarca() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [vendedores, setVendedores] = useState([]);

    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [vendedor, setVendedor] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'usuarios/all', { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setVendedores(res.data.usuarios);

                axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
                    .then(res => {

                        setDominio(res.data.dominio);
                        setNombre(res.data.nombre);
                        setTelefono(res.data.telefono);
                        setEmail(res.data.email);
                        setVendedor(res.data.vendedor._id);
                        setEstadoVista('cargado');

                    })
                    .catch((error) => {
                        console.log(error?.response?.data);
                        setEstadoVista('error')
                    });

            });



    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            nombre: nombre,
            email: email,
            telefono: telefono,
            vendedor: vendedor,
        }

        axios.put(Global.url_api + 'cliente/update/vendedor/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Info de Contacto " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TituloMini titulo="Info de Contacto">Información de contacto de uso interna de doyTurnos.com</TituloMini>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Nombre"
                                    icon={<AccountCircle />}
                                    helperText="Del contacto o dueño"
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Teléfono"
                                    icon={<WhatsAppIcon />}
                                    helperText="Del contacto o dueño"
                                    value={telefono}
                                    onChange={setTelefono}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Email"
                                    icon={<MailOutlineIcon />}
                                    helperText="Del contacto o dueño"
                                    value={email}
                                    onChange={setEmail}
                                    type="email"
                                    restricciones={['email']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Vendedor"
                                    icon={<PersonIcon />}
                                    value={vendedor}
                                    options={vendedores.map(function (e) {
                                        return {
                                            value: e._id,
                                            label: e.nombre
                                        }
                                    })}
                                    onChange={setVendedor}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}