import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { SketchPicker } from 'react-color';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditColores() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [menu, setMenu] = useState('');
    const [menu_texto, setMenuTexto] = useState('');
    const [botones, setBotones] = useState('');
    const [botones_texto, setBotonesTexto] = useState('');
    const [footer, setFooter] = useState('');
    const [footer_texto, setFooterTexto] = useState('');
    const [fondo, setFondo] = useState('');
    const [fondo_texto, setFondoTexto] = useState('');
    const [lista, setLista] = useState('');
    const [lista_texto, setListaTexto] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    const handleChangeMenu = (color, event) => {
        setMenu(color.hex)
    }
    const handleChangeMenuTexto = (color, event) => {
        setMenuTexto(color.hex)
    }
    const handleChangeBotones = (color, event) => {
        setBotones(color.hex)
    }
    const handleChangeBotonesTexto = (color, event) => {
        setBotonesTexto(color.hex)
    }
    const handleChangeFooter = (color, event) => {
        setFooter(color.hex)
    }
    const handleChangeFooterTexto = (color, event) => {
        setFooterTexto(color.hex)
    }
    const handleChangeFondo = (color, event) => {
        setFondo(color.hex)
    }
    const handleChangeFondoTexto = (color, event) => {
        setFondoTexto(color.hex)
    }
    const handleChangeLista = (color, event) => {
        setLista(color.hex)
    }
    const handleChangeListaTexto = (color, event) => {
        setListaTexto(color.hex)
    }

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);
                setMenu(res.data.colores.menu);
                setMenuTexto(res.data.colores.menu_texto);
                setBotones(res.data.colores.botones);
                setBotonesTexto(res.data.colores.botones_texto);
                setFooter(res.data.colores.footer);
                setFooterTexto(res.data.colores.footer_texto);
                setFondo(res.data.colores.fondo);
                setFondoTexto(res.data.colores.fondo_texto);
                setLista(res.data.colores.lista);
                setListaTexto(res.data.colores.lista_texto);

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            menu,
            menu_texto,
            botones,
            botones_texto,
            footer,
            footer_texto,
            fondo,
            fondo_texto,
            lista,
            lista_texto
        }

        axios.put(Global.url_api + 'cliente/update/colores/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Colores " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TituloMini titulo="Colores">Colores para la vista de clientes.</TituloMini>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Menú</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={menu}
                                        onChangeComplete={handleChangeMenu}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Menú Texto</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={menu_texto}
                                        onChangeComplete={handleChangeMenuTexto}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Ejemplo</h4>
                                <div style={{
                                    backgroundColor: menu,
                                    color: menu_texto,
                                    padding: '25px',
                                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                                }}>
                                    <h3>Esto es un título</h3>
                                    <p>Esto es un texto.</p>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Botones</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={botones}
                                        onChangeComplete={handleChangeBotones}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Botones Texto</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={botones_texto}
                                        onChangeComplete={handleChangeBotonesTexto}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Ejemplo</h4>
                                <div style={{
                                    backgroundColor: botones,
                                    color: botones_texto,
                                    padding: '25px',
                                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                                }}>
                                    <h3>Esto es un título</h3>
                                    <p>Esto es un texto.</p>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Footer</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={footer}
                                        onChangeComplete={handleChangeFooter}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Footer Texto</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={footer_texto}
                                        onChangeComplete={handleChangeFooterTexto}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Ejemplo</h4>
                                <div style={{
                                    backgroundColor: footer,
                                    color: footer_texto,
                                    padding: '25px',
                                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                                }}>
                                    <h3>Esto es un título</h3>
                                    <p>Esto es un texto.</p>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Fondo</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={fondo}
                                        onChangeComplete={handleChangeFondo}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Fondo Texto</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={fondo_texto}
                                        onChangeComplete={handleChangeFondoTexto}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Ejemplo</h4>
                                <div style={{
                                    backgroundColor: fondo,
                                    color: fondo_texto,
                                    padding: '25px',
                                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                                }}>
                                    <h3>Esto es un título</h3>
                                    <p>Esto es un texto.</p>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Lista</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={lista}
                                        onChangeComplete={handleChangeLista}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Lista Texto</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={lista_texto}
                                        onChangeComplete={handleChangeListaTexto}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                                <h4 align="center">Ejemplo</h4>
                                <div style={{
                                    backgroundColor: lista,
                                    color: lista_texto,
                                    padding: '25px',
                                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                                }}>
                                    <h3>Esto es un título</h3>
                                    <p>Esto es un texto.</p>
                                </div>
                            </Grid>
                        </Grid>

                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}