
export default function nombreDelMes(fecha, colocaDia = false, colocaMes = false, colocaAnio = false) {

    if (fecha.length !== 10 || fecha[4] !== "-" || fecha[7] !== "-") return '???'

    let arrFecha = fecha.split("-")

    let texto_retorno = ""

    if (colocaDia) {

        //agregar 'Martes 14' al comienzo

    }

    if (colocaMes) {

        if( colocaDia ) texto_retorno += " de "

        if (arrFecha[1] === "01") texto_retorno += "Enero"
        if (arrFecha[1] === "02") texto_retorno += "Febrero"
        if (arrFecha[1] === "03") texto_retorno += "Marzo"
        if (arrFecha[1] === "04") texto_retorno += "Abril"
        if (arrFecha[1] === "05") texto_retorno += "Mayo"
        if (arrFecha[1] === "06") texto_retorno += "Junio"
        if (arrFecha[1] === "07") texto_retorno += "Julio"
        if (arrFecha[1] === "08") texto_retorno += "Agosto"
        if (arrFecha[1] === "09") texto_retorno += "Septiembre"
        if (arrFecha[1] === "10") texto_retorno += "Octubre"
        if (arrFecha[1] === "11") texto_retorno += "Noviembre"
        if (arrFecha[1] === "12") texto_retorno += "Diciembre"

    }


    if (colocaAnio) texto_retorno += " " + arrFecha[0]

    return texto_retorno

}
