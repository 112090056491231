import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Moment from 'react-moment';

import NumberFormat from 'react-number-format';

import axios from 'axios';
import { Global } from '../../../Global';

import LoginOutData from '../../../helpers/LoginOutData';

import '../../../../assets/css/detalleRegistro.css';

import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import ButtonIcon from '../../../elementos/ButtonIcon';
import ConfirmDelete from '../../../elementos/ConfirmDelete';

import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';

import AddIcon from '@mui/icons-material/Add';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DescriptionIcon from '@mui/icons-material/Description';

export default function ClientePagos(props) {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [pagos, setPagos] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocs, setTotalDocs] = useState(0);

    const urlParams = useParams();



    useEffect(() => {

        axios.get(Global.url_api + 'clientes-pagos/' + urlParams.id + '/' + page, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setEstadoVista('cargado');
                setPagos(res.data.pagos);
                setTotalPages(res.data.totalPages);
                setTotalDocs(res.data.totalItems);

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams, page, totalPages]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={6} style={{ marginBottom: '0px', fontSize: '1.2rem' }}>
                        <PointOfSaleIcon /> Historial de Pagos
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div align="right">
                            {
                                LoginOutData.userHasPermiso(13) &&
                                <ButtonIcon
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    to={"/clientes/" + urlParams.id + "/cuenta-corriente/pagos/nuevo"}
                                >
                                    Nuevo
                                </ButtonIcon>
                            }
                        </div>
                    </Grid>
                </Grid>
                <div className="tableContainer">
                    <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th align="center">Fecha y Hora</th>
                                <th align="left">Plan</th>
                                <th align="left">Usuario</th>
                                <th align="left">Modalidad</th>
                                <th align="right">Importe</th>
                                <th align="center">Comprobante</th>
                                <th align="center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pagos.map((value, key) => {
                                return (
                                    <tr key={key}>
                                        <td align="center">{<Moment date={value.fechayhora} format="DD/MM/YYYY HH:mm:ss" />}</td>
                                        <td align="left">{value.plan}</td>
                                        <td align="left">{(value.usuario ? value.usuario : '-')}</td>
                                        <td align="left">{value.modalidad}</td>
                                        <td align="right">
                                            <NumberFormat
                                                value={value.importe}
                                                className="foo"
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                prefix={'$'}
                                            />
                                        </td>
                                        <td align="center">{value.comprobante}</td>
                                        <td align="center">
                                            {
                                                value.facturacion &&
                                                <a href={"https://billing.doyturnos.com.ar/api/prd/pdf/" + value.facturacion} rel="noreferrer" target="_blank">
                                                    <ButtonIcon
                                                        variant="outlined"
                                                        size="small"
                                                        color="success"
                                                        startIcon={<DescriptionIcon />}
                                                        noLink
                                                        marginRight
                                                    />
                                                </a>
                                            }
                                            {
                                                !value.facturacion &&
                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="success"
                                                    startIcon={<DescriptionIcon />}
                                                    disabled
                                                    marginRight
                                                />
                                            }

                                            {
                                                LoginOutData.userHasPermiso(13) &&
                                                <ConfirmDelete
                                                    variant="outlined"
                                                    size="small"
                                                    httpDelete={"clientes-pagos/" + value.id}
                                                    returnUrl={"/clientes/" + urlParams.id + "/cuenta-corriente"}
                                                    soloIcono
                                                />
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <Grid container>
                    <Grid item xs={12} md={6} style={{ margin: '0px', fontSize: '0.9rem' }}>
                        <Pagination
                            className="table-pagination"
                            showFirstButton showLastButton
                            shape="rounded"
                            count={totalPages}
                            page={page}
                            onChange={(event, value) => setPage(value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ margin: '0px', fontSize: '0.9rem' }}>
                        <p align="right">Se encontraron {totalDocs} registros.</p>
                    </Grid>
                </Grid>


            </>
        )

}