import { React, useState, useEffect } from 'react';

import axios from 'axios';
import { Global } from '../../../Global';
import Moment from 'react-moment';

import NumberFormat from 'react-number-format';

import Loading from '../../../elementos/Loading';
import ButtonIcon from '../../../elementos/ButtonIcon';

import '../../../../assets/css/table.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';

import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DescriptionIcon from '@mui/icons-material/Description';

export default function PagosDeClientes(props) {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [pagos, setPagos] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocs, setTotalDocs] = useState(0);

    useEffect(() => {

        axios.get(Global.url_api + 'reportes/clientes-pagos/' + props.vendedor + '/' + (page ? page : 1), 
        { 
            headers: { 
                Authorization: localStorage.getItem("token"), 
                "from": props.fechaInicio,
                "to": props.fechaFin
            } 
        })
            .then(res => {

                setPagos(res.data.pagos)
                setTotalPages(res.data.totalPages)
                setTotalDocs(res.data.totalItems)
                setEstadoVista('cargado')

            })
            .catch((error) => {

            });

    }, [props, page]);

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Container className="containerBigTable" component="main" maxWidth="lg" style={{marginTop: '0px'}}>
                    <Grid container>
                        <Grid className="titulo" item xs={12} md={8}>
                            <p style={{margin: '0px'}}>Se encontraron {totalDocs} registros.</p>
                        </Grid>
                        <Grid className="buscador" item xs={12} md={4}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>

                            </Box>
                        </Grid>
                    </Grid>
                    <div className="tableContainer">
                        <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th align="center">Fecha</th>
                                    <th align="left">Dominio</th>
                                    <th align="left">Plan</th>
                                    <th align="left">Modalidad</th>
                                    <th align="right">Importe</th>
                                    <th align="center">Accines</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    pagos.length === 0 &&
                                    <tr>
                                        <td colSpan={5} align='center'>
                                            No hay datos.
                                        </td>
                                    </tr>
                                }
                                {pagos.map((value, key) => {
                                    return (
                                        <tr key={key} >
                                            <td align="center">{<Moment date={value.fechayhora} format="DD/MM/YYYY" />}</td>
                                            <td align="left">{value.dominio}</td>
                                            <td align="left">{value.plan}</td>
                                            <td align="left">{value.modalidad}</td>
                                            <td align="right">
                                                <NumberFormat
                                                    value={value.importe}
                                                    className="foo"
                                                    displayType={'text'}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    prefix={'$ '}
                                                />
                                            </td>
                                            <td align="center">
                                                {
                                                    value.facturacion &&
                                                    <a href={"https://billing.doyturnos.com/ar/factura/" + value.facturacion} rel="noreferrer" target="_blank">
                                                        <ButtonIcon
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            startIcon={<DescriptionIcon />}
                                                            noLink
                                                            marginRight
                                                        />
                                                    </a>
                                                }
                                                {
                                                    !value.facturacion &&
                                                    <ButtonIcon
                                                        variant="outlined"
                                                        size="small"
                                                        color="primary"
                                                        startIcon={<DescriptionIcon />}
                                                        disabled
                                                        marginRight
                                                    />
                                                }

                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="success"
                                                    startIcon={<PointOfSaleIcon />}
                                                    to={'/clientes/' + value.clienteId + '/cuenta-corriente'}
                                                    marginRight
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        className="table-pagination"
                        showFirstButton showLastButton
                        shape="rounded"
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />

                </Container>
            </>
        )

}