import { React, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import '../../../../assets/css/niceTable.css'

import axios from 'axios';
import { Global } from '../../../Global';


import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Snack from '../../../elementos/Snack';

import Grid from '@mui/material/Grid';


export default function MensualVigenteVencido(props) {

    const [estadoVista, setEstadoVista] = useState('loading')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })


    const [mensualVigenteImporte, setMensualVigenteImporte] = useState(0)
    const [mensualVigenteCantidad, setMensualVigenteCantidad] = useState(0)
    const [mensualVencidoImporte, setMensualVencidoImporte] = useState(0)
    const [mensualVencidoCantidad, setMensualVencidoCantidad] = useState(0)

    useEffect(() => {

        setEstadoVista('loading')

        axios.get(Global.url_api + 'reportes/mensual-vigente/' + props.vendedor, {
            headers: {
                Authorization: localStorage.getItem("token"),
                "from": props.fechaInicio,
                "to": props.fechaFin
            }
        })
            .then(res => {

                setMensualVigenteImporte(res.data.importe)
                setMensualVigenteCantidad(res.data.abonos)
                setEstadoVista('cargado')


            })
            .catch((error) => {
                //console.log(error?.response?.data);

                setMensualVigenteImporte(0)
                setMensualVigenteCantidad(0)
                setEstadoVista('cargado')

                setSnackOpen({
                    open: true,
                    message: error.response.data.message,
                    severity: 'error'
                })
            });

        axios.get(Global.url_api + 'reportes/mensual-vencido/' + props.vendedor, {
            headers: {
                Authorization: localStorage.getItem("token"),
                "from": props.fechaInicio,
                "to": props.fechaFin
            }
        })
            .then(res => {

                setMensualVencidoImporte(res.data.importe)
                setMensualVencidoCantidad(res.data.abonos)
                setEstadoVista('cargado')

            })
            .catch((error) => {
                //console.log(error?.response?.data);

                setMensualVencidoImporte(0)
                setMensualVencidoCantidad(0)
                setEstadoVista('cargado')

                setSnackOpen({
                    open: true,
                    message: error.response.data.message,
                    severity: 'error'
                })
            });

    }, [props])


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3 className="text-center" style={{marginBottom: '25px'}}>
                            Total por Mes
                        </h3>
                    </Grid>
                </Grid>
                <table className='niceTable' width="100%" cellSpacing={0}>
                    <thead>
                        <tr>
                            <td>Estado</td>
                            <td align='right'>Importe</td>
                            <td align='right'>Cantidad</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Vigente</td>
                            <td align='right'>
                                <NumberFormat
                                    value={mensualVigenteImporte}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />
                            </td>
                            <td align='right'>
                                <NumberFormat
                                    value={mensualVigenteCantidad}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Vencido</td>
                            <td align='right'>
                                <NumberFormat
                                    value={mensualVencidoImporte}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />
                            </td>
                            <td align='right'>
                                <NumberFormat
                                    value={mensualVencidoCantidad}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={''}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
            </>
        )

}