import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import SwitchInput from '../../elementos/SwitchInput';
import Input from '../../elementos/Input';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';


import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditFechas() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [elige, setElige] = useState('');
    const [tope, setTope] = useState('');
    const [canchas, setCanchas] = useState('');
    const [anticipacion, setAnticipacion] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);
                setElige(res.data.fechas.elige);
                setTope(res.data.fechas.tope);
                setCanchas(res.data.fechas.canchas);
                setAnticipacion(res.data.fechas.anticipacion);

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            elige,
            tope,
            canchas,
            anticipacion,
        }

        axios.put(Global.url_api + 'cliente/update/fechas/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Config. Fechas " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Configuración de Fechas y Horas">Estas opciones afectan al listado de fechas y horas que puede elegir el cliente cuando está reservando.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Elige"
                                    value={elige}
                                    onChange={setElige}
                                    helperText="Puede ver más fechas de las que aparecen en el listado (botón Siguiente)"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Tope Días"
                                    value={tope}
                                    onChange={setTope}
                                    type="tel"
                                    helperText="Tope de días hacia adelante que puede reservar el cliente (ej: 30 días)"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Horas Anticipación"
                                    value={anticipacion}
                                    onChange={setAnticipacion}
                                    type="tel"
                                    helperText="Horas de anticipación para reservar un turno (ej 0, 2, 24, 48)"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Canchas"
                                    value={canchas}
                                    onChange={setCanchas}
                                    helperText="Si la marca utiliza la modalidad de canchas."
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}