import React from 'react';


export default function VariablesMensajes() {

    return (
        <div style={{ fontSize: '0.9rem' }}>
            <p>Variables:</p>
            <ul style={{ listStyle: 'none' }}>
                <li>{"{$MARCA}"} <em>Nombre tu Marca</em></li>
                <li>{"{$SUCURSAL}"} <em>Nombre de la sucursal</em></li>
                <li>{"{$CLIENTE_NOMBRE}"} <em>Nombre del cliente</em></li>
                <li>{"{$TURNO_FECHA}"} <em>Fecha del turno (DD/MM/AAAA)</em></li>
                <li>{"{$TURNO_FECHA_DENTRO_DE}"} <em>Fecha del turno (dentro de x días | mañana | hoy)</em></li>
                <li>{"{$TURNO_DIA}"} <em>Fecha del turno (martes 16 de julio)</em></li>
                <li>{"{$TURNO_HORA}"} <em>Hora del turno (HH:MM)</em></li>
                <li>{"{$TURNO_DIRECCION}"} <em>Dirección (calle, altura, barrio)</em></li>
                <li>{"{$TURNO_MAPA}"} <em>Link a Google Maps</em></li>
                <li>{"{$TURNO_ZOOM}"} <em>Link del zoom generado</em></li>
                <li>{"{$AGENDA}"} <em>Nombre de la agenda</em></li>
                <li>{"{$SERVICIOS}"} <em>Servicios del turno</em></li>
                <li>{"{$URL_WEB}"} <em>Link a la web de turnos</em></li>
                <li>{"{$URL_CONFIRMA_ASIST}"} <em>Link para que el cliente pueda confirmar asistencia</em></li>
            </ul>
        </div>
    )

}