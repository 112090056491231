import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';
import { Global } from '../../../Global';

import '../../../../assets/css/detalleRegistro.css';

import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import HeaderVista from '../../../elementos/HeaderVista';
import ButtonIcon from '../../../elementos/ButtonIcon';

import ClientePlanes from './ClientePlanes';
import ClientePagos from './ClientePagos';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import SettingsIcon from '@mui/icons-material/Settings';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';


export default function ClientesCuentaCorriente() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [dominio, setDominio] = useState({});

    const urlParams = useParams();



    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id + '/dominio', { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio)
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/clientes"
                    titulo={dominio + " - Cuenta Corriente"}
                    end={
                        <>
                            <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="primary"
                                startIcon={<SettingsIcon />}
                                to={'/clientes/' + urlParams.id}
                                marginRight
                            />
                            <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="success"
                                startIcon={<PointOfSaleIcon />}
                                to={'/clientes/' + urlParams.id + '/cuenta-corriente'}
                                marginRight
                            />
                            <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="secondary"
                                startIcon={<AppSettingsAltIcon />}
                                to={'/clientes/' + urlParams.id + '/app'}
                                marginRight
                            />
                        </>
                    }
                />
                <Container component="main" maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} md={12}><br /><br /></Grid>
                        <Grid item xs={12} md={12}>
                            <ClientePlanes />
                        </Grid>
                        <Grid item xs={12} md={12}><br /><br /></Grid>
                        <Grid item xs={12} md={12}>
                            <ClientePagos />
                        </Grid>
                    </Grid>
                </Container>
            </>
        )

}