import React from 'react';
import ReactDOM from 'react-dom';

import { createTheme, ThemeProvider  } from '@mui/material/styles';

import { imgLogos } from './components/Global'

import './assets/css/index.css';
import './assets/css/bootstrap-utilities.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const theme = createTheme({
  palette: {
    dark: {
      main: '#212529',
      contrastText: '#fff',
      dark: '#212529',
    },
    neutral: {
      main: '#6c757d',
      contrastText: '#fff',
      dark: '#6c757d',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
    <div className="fondoLogo" style={{backgroundImage: "url('"+imgLogos.poweredBy+"')"}} ></div>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
