let url = "http://localhost:3800/api/adm/"
if (window.location.href.includes("doyturnos.com")) {
    url = "https://api.doyturnos.com/api/adm/"
} else if (window.location.href.includes("agendaaltiro.cl")) {
    url = "https://api.agendaaltiro.cl/api/adm/"
}
const url_cdn = "https://cdn.doyturnos.com.ar";

var Global = {

    url_api: url

}

var imgLogos = {
    logoHeader: (window.location.href.includes("doyturnos.com") || window.location.href.includes("localhost:3000") ? url_cdn + "/images-adm/logo.jpg" : url_cdn + "/agendaaltiro/marca/logo-fondoamarillo.jpg"),
    logoHeaderNavidad: (window.location.href.includes("doyturnos.com") || window.location.href.includes("localhost:3000") ? url_cdn + "/images-adm/logo_navidad.jpg" : url_cdn + "/agendaaltiro/marca/logo-fondoamarillo.jpg"),
    poweredBy: (window.location.href.includes("doyturnos.com") || window.location.href.includes("localhost:3000") ? url_cdn + "/images-adm/fondo.png" : url_cdn + "/agendaaltiro/marca/fondo.png"),
    logoAlt: (window.location.href.includes("doyturnos.com") || window.location.href.includes("localhost:3000") ? "doyTurnos.com" : "AGENDA AL TIRO"),
}


module.exports = { Global, imgLogos, url_cdn };