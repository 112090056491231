import React from 'react';
import { Link } from 'react-router-dom';

import '../../assets/css/Card.css';

import ConfirmDelete from '../elementos/ConfirmDelete';

import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';

export default function Card(props) {

    return (
        <div className='card'>
            <div className="titulo">{props.titulo}</div>
            <div className="img">
                <img src={props.img} width="100%" alt={props.titulo} />
            </div>
            <div className="descripcion">{props.descripcion}</div>
            <div className='botones'>
                {
                    props.confirmDelete &&
                    <ConfirmDelete
                        soloIcono={true}
                        httpDelete={props.confirmDelete?.httpDelete}
                        returnUrl={props.confirmDelete?.returnUrl}
                    />
                }
                {
                    props.rutaPencil &&
                    <Link to={props.rutaPencil}>
                        <IconButton color="neutral">
                            <EditIcon />
                        </IconButton>
                    </Link>
                }

            </div>
        </div>
    )

}