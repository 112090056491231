import { React } from 'react'

import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingTabla(props) {

    return (
        <tbody>
            <tr>
                <td colSpan={props.colSpan} align="center">
                    <CircularProgress style={{ 'color': 'black', height: '20px', width: '20px' }} />
                </td>
            </tr>
        </tbody>
    )

}