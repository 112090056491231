import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';


import axios from 'axios';
import { Global } from '../../../Global';


import '../../../../assets/css/detalleRegistro.css';

import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Input from '../../../elementos/Input';
import Snack from '../../../elementos/Snack';
import HeaderVista from '../../../elementos/HeaderVista';
import CheckboxList from '../../../elementos/CheckboxList';
import Select from '../../../elementos/Select';

import { Roles } from './UsuariosRoles';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';


export default function ClienteAppUsuarioEditar() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [nombre, setNombre] = useState('');
    const [usuario, setUsuario] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [permisoAgendas, setPermisoAgendas] = useState([]);
    const [permisosUsuario, setPermisosUsuario] = useState([]);
    const [agendas, setAgendas] = useState([]);
    const [permisos, setPermisos] = useState([]);
    const [rolElegido, setRolElegido] = useState([]);

    const urlParams = useParams();

    const navigate = useNavigate();

    const handleChangeRolElegido = (e) => {

        //Convierto el string de permisos en array de numeros
        setPermisosUsuario(e.split(",").map(function (item) {
            return parseInt(item, 10);
        }));
        setRolElegido(e);

    }

    const handleChangeSetPermisoUsuario = (e) => {

        setPermisosUsuario(e);
        setRolElegido("");

    }

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            nombre,
            usuario,
            contrasena,
            permisoAgendas,
            permisosUsuario,
            codigo: urlParams.codigo
        }

        axios.put(Global.url_api + 'mysql/usuarios/' + urlParams.id, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: 'Se actualizó el usuario.',
                    severity: 'success'
                })
                navigate("/clientes/" + urlParams.id + "/app");

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }

    useEffect(() => {

        axios.get(Global.url_api + 'mysql/agendas/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setAgendas(res.data.agendas);

                axios.get(Global.url_api + 'mysql/permisos', { headers: { Authorization: localStorage.getItem("token") } })
                    .then(res => {

                        setPermisos(res.data.permisos);

                        axios.get(Global.url_api + 'mysql/usuarios/' + urlParams.id + '/' + urlParams.codigo, { headers: { Authorization: localStorage.getItem("token") } })
                            .then(res => {

                                setNombre(res.data.nombre);
                                setUsuario(res.data.usuario);
                                setPermisoAgendas(res.data.controla.split(",").map(function (item) { return Number(item); }));

                                axios.get(Global.url_api + 'mysql/usuarios/permisos/' + urlParams.id + '/' + urlParams.codigo, { headers: { Authorization: localStorage.getItem("token") } })
                                    .then(res => {

                                        setPermisosUsuario(res.data);

                                        const roles = Roles.map(item => {return item.value.split(",").map(elem => {return Number(elem);})});
                                        roles.forEach(unRol => {
                                            if(unRol.toString() === res.data.toString()){
                                                setRolElegido(unRol.toString())
                                            }
                                        });

                                        setEstadoVista('cargado');

                                    })
                                    .catch((error) => {
                                        console.log(error?.response?.data);
                                        setEstadoVista('error')
                                    });

                            })
                            .catch((error) => {
                                console.log(error?.response?.data);
                                setEstadoVista('error')
                            });

                    })
                    .catch((error) => {
                        console.log(error?.response?.data);
                        setEstadoVista('error')
                    });

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id + "/app"}
                    titulo="Editar Usuario"
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Nombre"
                                    icon={<PersonIcon />}
                                    helperText="Ej: Trini"
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Usuario"
                                    icon={<PersonIcon />}
                                    helperText="Ej: trini"
                                    value={usuario}
                                    onChange={setUsuario}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Contraseña"
                                    icon={<PasswordIcon />}
                                    helperText="Ej: rh123"
                                    value={contrasena}
                                    onChange={setContrasena}
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}></Grid>
                            <Grid item xs={12} md={4} >
                                <CheckboxList
                                    titulo="Acceso a Agendas"
                                    opciones={agendas}
                                    elegidos={permisoAgendas}
                                    handleChange={setPermisoAgendas}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <Select
                                    label="Roles"
                                    icon={<PersonIcon />}
                                    value={rolElegido}
                                    options={Roles}
                                    onChange={handleChangeRolElegido}
                                />
                                <CheckboxList
                                    titulo="Indica los Permisos"
                                    opciones={permisos}
                                    elegidos={permisosUsuario}
                                    handleChange={handleChangeSetPermisoUsuario}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}