import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

export default function SwitchInput(props) {

    const handleToggle = (value) => () => {
        const currentIndex = props.elegidos.indexOf(value);
        const newChecked = [...props.elegidos];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        props.handleChange(newChecked);
      };

    return (
        <>
            <h4 align="center">{props.titulo}</h4>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {props.opciones.map((value) => {
                    const labelId = `checkbox-list-label-${value.codigo}`;

                    return (
                        <ListItem
                            key={value.codigo}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={handleToggle(value.codigo)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={props.elegidos.indexOf(value.codigo) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.nombre} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </>
    )

}