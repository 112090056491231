import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import Error from '../../elementos/Error';
import Input from '../../elementos/Input';
import Select from '../../elementos/Select';
import Snack from '../../elementos/Snack';
import HeaderVista from '../../elementos/HeaderVista';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CachedIcon from '@mui/icons-material/Cached';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CheckIcon from '@mui/icons-material/Check';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';


export default function PlanesCreate() {

    const [estadoVista, setEstadoVista] = useState('cargado');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [nombre, setNombre] = useState('');
    const [periodo, setPeriodo] = useState('');
    const [importe, setImporte] = useState('');
    const [cant_agendas, setCantAgendas] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            nombre,
            periodo,
            importe,
            cant_agendas
        }

        axios.post(Global.url_api + 'planes', body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: 'Se creó el plan.',
                    severity: 'success'
                })
                navigate('/planes/');

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/planes"
                    titulo="Nuevo Plan"
                />

                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={4} >
                                <Input
                                    label="Agendas"
                                    icon={<AutoAwesomeMotionIcon />}
                                    value={cant_agendas}
                                    onChange={setCantAgendas}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Nombre"
                                    icon={<LocalGroceryStoreIcon />}
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Select
                                    label="Período"
                                    value={periodo}
                                    icon={<CachedIcon />}
                                    options={[
                                        {
                                            value: 'Month',
                                            label: 'Month'
                                        },
                                        {
                                            value: 'Year',
                                            label: 'Year'
                                        }
                                    ]}
                                    onChange={setPeriodo}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Importe"
                                    icon={<AttachMoneyIcon />}
                                    value={importe}
                                    onChange={setImporte}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}