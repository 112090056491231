import { React, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import Moment from 'react-moment'

import axios from 'axios'
import { Global } from '../../Global'

import LoadingTabla from '../../elementos/LoadingTabla'
import ButtonIcon from '../../elementos/ButtonIcon'
import HeaderVista from '../../elementos/HeaderVista'
import Snack from '../../elementos/Snack'
import Error from '../../elementos/Error'
import Divider from '../../elementos/Divider'

import LoginOutData from '../../helpers/LoginOutData'

import '../../../assets/css/table.css'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import { TextField } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import FilterNoneIcon from '@mui/icons-material/FilterNone'

const delay = 0.4

export default function Gastos() {

    const [estadoVista, setEstadoVista] = useState('cargado');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [gastos, setGastos] = useState([])
    const [textSearch, setTextSearch] = useState("")
    const [page, setPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(15)
    const [totalDocs, setTotalDocs] = useState(0)
    const [totalPages, setTotalPages] = useState(0)

    var bodyTabla = (<LoadingTabla colSpan="7" />)

    const handleChangeTextSearch = (e) => {
        setPage(1)
        setTextSearch(e.target.value)
    }

    useEffect(() => {

        let timer1 = setTimeout(() => {

            setEstadoVista('loading')

            axios.get(Global.url_api + 'gastos', {
                headers: {
                    Authorization: localStorage.getItem("token"),
                    page: page,
                    itemsperpage: itemsPerPage,
                    textsearch: textSearch,
                }
            })
                .then(res => {

                    setGastos(res.data.resultados)
                    setTotalDocs(res.data.totalDocs)
                    setTotalPages(res.data.totalPages)
                    setEstadoVista('cargado')

                })
                .catch((error) => {
                    setEstadoVista('error');
                    setSnackOpen({
                        open: true,
                        message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                        severity: 'error'
                    });
                })

        }, delay * 1000)


        return () => {
            clearTimeout(timer1)
        }

    }, [textSearch, itemsPerPage, page])

    if (estadoVista === 'loading') bodyTabla = (<LoadingTabla colSpan="7" />)

    if (estadoVista === 'error') return (<><Error /><Snack estado={snackOpen} setSnackOpen={setSnackOpen} /></>)

    if (estadoVista === 'cargado') {

        bodyTabla = (<tbody>
            {
                gastos.length === 0 &&
                <tr>
                    <td colSpan={7} align='center'>
                        No hay datos.
                    </td>
                </tr>
            }
            {gastos.map((value, key) => {
                return (
                    <tr key={key} >
                        <td align="center">
                            <Moment date={value.fechahora} format="DD/MM/YYYY HH:mm:ss" />
                        </td>
                        <td align="left">{value.rubro}</td>
                        <td align="left">{value.detalle}</td>
                        <td align="left">{value.vendedor}</td>
                        <td align="right">
                            <NumberFormat
                                value={value.importe_ars}
                                className="foo"
                                displayType={'text'}
                                decimalSeparator=","
                                thousandSeparator="."
                                prefix={'$ '}
                            />
                        </td>
                        <td align="right">
                            <NumberFormat
                                value={value.importe_usd}
                                className="foo"
                                displayType={'text'}
                                decimalSeparator=","
                                thousandSeparator="."
                                prefix={'$ '}
                            />
                        </td>
                        <td align="center">
                            {
                                !LoginOutData.userHasPermiso(19) &&
                                <ButtonIcon
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    endIcon={<EditIcon />}
                                    disabled
                                />
                            }
                            {
                                LoginOutData.userHasPermiso(19) &&
                                <ButtonIcon
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    endIcon={<EditIcon />}
                                    to={'/gastos/edit/' + value.id}
                                />
                            }
                        </td>
                    </tr>
                )
            })}
        </tbody>)

    }

    return (
        <>
            <HeaderVista
                titulo="Gastos"
                icon={<AutoAwesomeMotionIcon />}
                end={
                    (LoginOutData.userHasPermiso(18) ? <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<AddIcon />}
                        to="/gastos/nuevo"
                    >
                        Nuevo
                    </ButtonIcon> : '')
                }
            />
            <Container className="containerBigTable" component="main" maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<FilterNoneIcon />}
                            to="/gastos/rubros"
                        >
                            Administrar Rubros
                        </ButtonIcon>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid className="titulo" item xs={12} md={8}>
                        <p>Se encontraron {totalDocs} registros.</p>
                    </Grid>
                    <Grid className="buscador" item xs={12} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField onChange={handleChangeTextSearch} fullWidth label="Buscar..." variant="standard" />
                        </Box>
                    </Grid>
                </Grid>
                <div className="tableContainer">
                    <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th align="center">Fecha</th>
                                <th align="left">Rubro</th>
                                <th align="left">Detalle</th>
                                <th align="left">Usuario</th>
                                <th align="right">ARS</th>
                                <th align="right">USD</th>
                                <th align="center">Acciones</th>
                            </tr>
                        </thead>
                        {bodyTabla}
                    </table>
                </div>
                <Pagination
                    className="table-pagination"
                    showFirstButton showLastButton
                    shape="rounded"
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                />

            </Container>
        </>
    )

}