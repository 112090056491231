import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';


import axios from 'axios';
import { Global } from '../../../Global';


import '../../../../assets/css/detalleRegistro.css';

import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Input from '../../../elementos/Input';
import Snack from '../../../elementos/Snack';
import HeaderVista from '../../../elementos/HeaderVista';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckIcon from '@mui/icons-material/Check';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function ClienteAppSucursalEditar() {

    const [estadoVista, setEstadoVista] = useState('cargado');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });

    const [codigo, setCodigo] = useState('');
    const [barrio, setBarrio] = useState('');
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [whatsapp_texto, setWhatsappTexto] = useState('');
    const [whatsapp_link, setWhatsappLink] = useState('');
    const [direccion, setDireccion] = useState('');
    const [mapa, setMapa] = useState('');

    const urlParams = useParams();

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            codigo,
            barrio,
            nombre,
            telefono,
            whatsapp_texto,
            whatsapp_link,
            direccion,
            mapa,
        }

        axios.put(Global.url_api + 'mysql/sucursales/' + urlParams.id, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: 'Se actualizó la sucursal.',
                    severity: 'success'
                })
                navigate("/clientes/" + urlParams.id + "/app");

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }

    useEffect(() => {

        axios.get(Global.url_api + 'mysql/sucursales/' + urlParams.id + '/' + urlParams.codigo, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setCodigo(res.data.codigo)
                setBarrio(res.data.barrio)
                setNombre(res.data.nombre)
                setDireccion(res.data.direccion)
                setMapa(res.data.mapa)
                setTelefono(res.data.telefono)
                setWhatsappTexto(res.data.whatsapp_texto)
                setWhatsappLink(res.data.whatsapp_link)

                setEstadoVista('cargado')

            })
            .catch((error) => {
                console.log(error?.response?.data)
                setEstadoVista('error')
            });

    }, [urlParams]);

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id + "/app"}
                    titulo="Nueva Sucursal"
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Barrio"
                                    icon={<LocationOnIcon />}
                                    helperText="Ej: CABA"
                                    value={barrio}
                                    onChange={setBarrio}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Nombre"
                                    icon={<LocationOnIcon />}
                                    helperText="Ej: Liniers"
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12} ></Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Teléfono"
                                    icon={<PhoneEnabledIcon />}
                                    helperText="Ej: 11-5555-4444"
                                    value={telefono}
                                    onChange={setTelefono}
                                    type="text"
                                    
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Whatsapp Texto"
                                    icon={<WhatsAppIcon />}
                                    helperText="Ej: 11-5555-4444"
                                    value={whatsapp_texto}
                                    onChange={setWhatsappTexto}
                                    type="text"
                                    
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Whatsapp Link"
                                    icon={<WhatsAppIcon />}
                                    helperText="Ej: 5491155554444"
                                    value={whatsapp_link}
                                    onChange={setWhatsappLink}
                                    type="text"
                                    
                                />
                            </Grid>
                            <Grid item xs={12} md={12}></Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Calle N°, Localidad"
                                    icon={<LocationOnIcon />}
                                    helperText="Ej: Calle 1234, CABA"
                                    value={direccion}
                                    onChange={setDireccion}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="URL Mapa"
                                    icon={<LocationOnIcon />}
                                    helperText="Ej: https://maps.google.com/1234"
                                    value={mapa}
                                    onChange={setMapa}
                                    type="text"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}