import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import SwitchInput from '../../elementos/SwitchInput';
import Input from '../../elementos/Input';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditZoom() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [activo, setActivo] = useState('');
    const [dias_genera, setDiasGenera] = useState('');
    const [account_id, setAccountId] = useState('');
    const [client_id, setClientId] = useState('');
    const [client_secret, setClientSecret] = useState('');
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                if (res.data.zoom) {
                    setActivo(res.data.zoom.activo);
                    setDiasGenera(res.data.zoom.dias_genera);
                    setAccountId(res.data.zoom.account_id);
                    setClientId(res.data.zoom.client_id);
                    setClientSecret(res.data.zoom.client_secret);
                    setUser(res.data.zoom.user);
                    setPassword(res.data.zoom.password);
                }

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            activo,
            dias_genera,
            account_id,
            client_id,
            client_secret,
            user,
            password
        }

        axios.put(Global.url_api + 'cliente/update/zoom/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Config. Zoom " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Zoom">Opciones para generar link de zoom automático para cada turno.<br />Para completar esta información se debe generar una APP <b>Server-to-Server OAuth</b> en 'marketplace.zoom.us' </TituloMini>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Activo"
                                    value={activo}
                                    onChange={setActivo}
                                    helperText="¿Generar los links de zoom?"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Días Genera"
                                    value={dias_genera}
                                    onChange={setDiasGenera}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    helperText="¿Cuántos días antes se genera el link?"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Account ID"
                                    value={account_id}
                                    onChange={setAccountId}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Client ID"
                                    value={client_id}
                                    onChange={setClientId}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Client Secret"
                                    value={client_secret}
                                    onChange={setClientSecret}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="User ID"
                                    value={user}
                                    onChange={setUser}
                                    type="text"
                                    helperText="ID de usuario obtenido del endpoint 'api.zoom.us/v2/users'"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Password"
                                    value={password}
                                    onChange={setPassword}
                                    type="text"
                                    helperText="Contraseña que deberán ingresar los usuarios para acceder a la videollamada."
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}