import React from 'react';

import { imgLogos } from '../Global'

export default function LogoHeader() {

    let today = new Date();
    let periodo = (today.getMonth() + 1) + '' + today.getDate();

    if (periodo >= 1208) {

        return (
            <img style={{ paddingTop: '7px' }} height="75" alt={imgLogos.logoAlt} src={imgLogos.logoHeaderNavidad} />
        )

    } else {

        return (
            <img style={{ paddingTop: '7px' }} height="75" alt={imgLogos.logoAlt} src={imgLogos.logoHeader} />
        )

    }


}