import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import Error from '../../elementos/Error';
import HeaderVista from '../../elementos/HeaderVista';
import Input from '../../elementos/Input';
import Select from '../../elementos/Select';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CachedIcon from '@mui/icons-material/Cached';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';


export default function PlanesCreate() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [nombre, setNombre] = useState('');
    const [periodo, setPeriodo] = useState('');
    const [importe, setImporte] = useState('');
    const [cant_agendas, setCantAgendas] = useState('');

    const urlParams = useParams();

    useEffect(() => {

        axios.get(Global.url_api + 'planes/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setNombre(res.data.nombre);
                setImporte(res.data.importe);
                setPeriodo(res.data.periodo);
                setCantAgendas(res.data.cant_agendas);
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    });


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/planes"
                    titulo={"Planes / " + nombre}
                />
                <Container className="nuevoRegistro" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={4} >
                            <Input
                                label="Cant Agendas"
                                icon={<AutoAwesomeMotionIcon />}
                                value={cant_agendas}
                                onChange={setCantAgendas}
                                type="tel"
                                restricciones={['soloNumeros']}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Input
                                label="Nombre"
                                icon={<LocalGroceryStoreIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Select
                                label="Período"
                                value={periodo}
                                icon={<CachedIcon />}
                                options={[
                                    {
                                        value: 'Month',
                                        label: 'Month'
                                    },
                                    {
                                        value: 'Year',
                                        label: 'Year'
                                    }
                                ]}
                                onChange={setPeriodo}
                                type="text"
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Input
                                label="Importe"
                                icon={<AttachMoneyIcon />}
                                value={importe}
                                onChange={setImporte}
                                type="tel"
                                restricciones={['soloNumeros']}
                                disabled
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>
                
            </>
        )

}