import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import axios from 'axios'
import { Global } from '../../Global'

import '../../../assets/css/nuevoRegistro.css'

import Loading from '../../elementos/Loading'
import Error from '../../elementos/Error'
import Select from '../../elementos/Select'
import Input from '../../elementos/Input'
import Snack from '../../elementos/Snack'
import HeaderVista from '../../elementos/HeaderVista'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import CheckIcon from '@mui/icons-material/Check'
import FilterNoneIcon from '@mui/icons-material/FilterNone'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import TodayIcon from '@mui/icons-material/Today'

export default function GastosNuevo() {

    const [estadoVista, setEstadoVista] = useState('loading')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })

    const [rubros, setRubros] = useState([])

    const [fecha, setFecha] = useState(moment().format("YYYY-MM-DD"))
    const [hora, setHora] = useState(moment().format("HH:mm:ss"))
    const [rubro, setRubro] = useState('')
    const [detalle, setDetalle] = useState('')
    const [importe_ars, setImporteArs] = useState('')
    const [importe_usd, setImporteUsd] = useState('')

    const navigate = useNavigate()

    useEffect(() => {

        axios.get(Global.url_api + 'gastos/rubros', {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
            .then(res => {

                setRubros(res.data.resultados)
                setEstadoVista('cargado')

            })
            .catch((error) => {
                setEstadoVista('error');
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                });
            })

    }, [])

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            fecha,
            hora,
            rubro,
            detalle,
            importe_ars,
            importe_usd,
        }

        axios.post(Global.url_api + 'gastos', body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: 'Se creó el registro.',
                    severity: 'success'
                })
                navigate('/gastos')

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado')
            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/gastos"
                    titulo="Nuevo Gasto"
                />

                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Fecha"
                                    icon={<TodayIcon />}
                                    value={fecha}
                                    onChange={setFecha}
                                    type="date"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Hora"
                                    icon={<TodayIcon />}
                                    value={hora}
                                    onChange={setHora}
                                    type="time"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Rubro"
                                    value={rubro}
                                    options={rubros.map(function (item) {
                                        return {
                                            value: item.id,
                                            label: item.nombre
                                        }
                                    })}
                                    onChange={setRubro}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Detalle"
                                    icon={<FilterNoneIcon />}
                                    value={detalle}
                                    onChange={setDetalle}
                                    type="text"
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Importe ARS"
                                    icon={<AttachMoneyIcon />}
                                    value={importe_ars}
                                    onChange={setImporteArs}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Importe USD"
                                    icon={<AttachMoneyIcon />}
                                    value={importe_usd}
                                    onChange={setImporteUsd}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}