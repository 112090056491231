import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import SwitchInput from '../../elementos/SwitchInput';
import Input from '../../elementos/Input';
import Error from '../../elementos/Error';
import ConfirmDelete from '../../elementos/ConfirmDelete';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';


export default function ClienteDeleteCamposAdicionalesCliente() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [nombre, setNombre] = useState('');
    const [required, setRequired] = useState('');
    const [leyenda, setLeyenda] = useState('');

    const urlParams = useParams();


    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                setNombre(res.data.campos_adicionales.cliente[urlParams.index].nombre);
                setRequired(res.data.campos_adicionales.cliente[urlParams.index].required);
                setLeyenda(res.data.campos_adicionales.cliente[urlParams.index].leyenda);

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);



    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Campos adicionales del cliente " + dominio}
                />
                <Container className="nuevoRegistro" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <br />
                        <Grid item xs={12} >
                            <TituloMini titulo="Campo Adicional">Los campos adicionales <b>del cliente</b> se solicitan al momento de registrarse un cliente nuevo.<br />Los campos adicionales solo se pueden crear/eliminar. Si deseas modificar, deberás eliminarlo y volver a crearlo.</TituloMini>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Input
                                label="Nombre"
                                value={nombre}
                                type="text"
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Input
                                label="Leyenda"
                                value={leyenda}
                                type="text"
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <SwitchInput
                                label="Obligatorio"
                                disabled
                                value={required}
                            />
                        </Grid>



                    </Grid>
                </Container>
                <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <ConfirmDelete
                                httpDelete={"cliente/campos-adicionales-cliente/" + dominio}
                                httpBody={
                                    {
                                        nombre: nombre,
                                        indice: urlParams.index
                                    }
                                }
                                returnUrl={"/clientes/" + urlParams.id}
                            />
                        </Grid>
                    </Grid>
                </Container>


            </>
        )

}