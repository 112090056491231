import { React, useEffect, useState } from 'react';
import moment from 'moment';

//Vistas
import PagosYComisiones from './home/PagosYComisiones';
import FacturacionYPagos from './home/FacturacionYPagos';
import MensualVigenteVencido from './home/MensualVigenteVencido';
import PlanesVigentes from './home/PlanesVigentes';
import EstadoClientes from './home/EstadoClientes';
import AltaYBajaClientes from './home/AltayBajaClientes';
import PagosDeClientes from './home/PagosDeClientes';
import FacturacionPorProvincia from './home/FacturacionPorProvincia';

import Loading from '../../elementos/Loading';
import Error from '../../elementos/Error';
import HeaderVista from '../../elementos/HeaderVista';
import RangoFechas from '../../elementos/RangoFechas';
import FiltroVendedores from '../../elementos/FiltroVendedores';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function ReportesHome() {

    const [estadoVista, setEstadoVista] = useState('loading')

    const [bloqueFechas, setBloqueFechas] = useState(moment().startOf('month').format("YYYY-MM-DD") + "/" + moment().endOf('month').format('YYYY-MM-DD'))
    const [vendedor, setVendedor] = useState('0')

    useEffect(() => {

        setEstadoVista('cargado')

    }, []);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    titulo="Reportes"
                    end={
                        <FiltroVendedores
                            vendedor={vendedor}
                            changeVendedor={setVendedor}
                        />
                    }
                />
                <Container className="nuevoRegistro" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FacturacionYPagos
                                vendedor={vendedor}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <div style={{ borderBottom: '1px solid #ccc' }}></div>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AltaYBajaClientes
                                vendedor={vendedor}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <div style={{ borderBottom: '1px solid #ccc' }}></div>
                    <br />
                    <RangoFechas
                        bloqueFechas={bloqueFechas}
                        vendedor={vendedor}
                        changeBloqueFechas={setBloqueFechas}
                    />
                    <br />
                    <div style={{ borderBottom: '1px solid #ccc' }}></div>
                    <br />
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <PagosYComisiones
                                    fechaInicio={String(bloqueFechas).split("/")[0]}
                                    fechaFin={String(bloqueFechas).split("/")[1]}
                                    vendedor={vendedor}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FacturacionPorProvincia
                                    fechaInicio={String(bloqueFechas).split("/")[0]}
                                    fechaFin={String(bloqueFechas).split("/")[1]}
                                    vendedor={vendedor}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <br />
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <h3 className="text-center text-md-start" style={{ margin: '0px' }}>Historial de Pagos</h3>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <PagosDeClientes
                                            fechaInicio={String(bloqueFechas).split("/")[0]}
                                            fechaFin={String(bloqueFechas).split("/")[1]}
                                            vendedor={vendedor}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Container>
                    <br />
                    <div style={{ borderBottom: '1px solid #ccc' }}></div>
                    <br />
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <PlanesVigentes
                                    vendedor={vendedor}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MensualVigenteVencido
                                    vendedor={vendedor}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <br />
                    <div style={{ borderBottom: '1px solid #ccc' }}></div>
                    <br />
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <EstadoClientes
                                    vendedor={vendedor}
                                />
                            </Grid>
                        </Grid>
                    </Container>


                </Container>
            </>
        )

}