import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import Error from '../../elementos/Error';
import Input from '../../elementos/Input';

import VariablesMensajes from "../../avisos-y-mensajes/VariablesMensajes";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';



import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditWhatsappAdminTurnoCancelado() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [turno_cancelado, setTurnoCancelado] = useState('');


    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/mensajes-whatsapp/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                setTurnoCancelado(res.data.mensajes_whatsapp.admin_turno_cancelado);

                setEstadoVista('cargado');

            })
            .catch((error) => {
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            admin_turno_cancelado: turno_cancelado,
        }

        axios.put(Global.url_api + 'cliente/update/avisos-whatsapp/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Whatsapp: Turno Creado " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Turno Creado">Notificación por <b>whatsapp</b> enviada al cliente cada vez que se crea un turno.</TituloMini>
                            </Grid>
                            <Grid item xs={12} >
                                <VariablesMensajes />
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <Input multiline={true} value={turno_cancelado} onChange={setTurnoCancelado} />
                            </Grid>

                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}