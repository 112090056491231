import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import CheckboxList from '../../elementos/CheckboxList';
import Input from '../../elementos/Input';
import Error from '../../elementos/Error';
import Select from '../../elementos/Select';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditMediosDePago() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [medios_de_pago, setMediosDePago] = useState([]);
    const [mercadopago_accessToken, setMercadopagoAccessToken] = useState('');
    const [transbank_codigoComercio, setTransbankCodigoComercio] = useState('');
    const [transbank_llavePrivada, setTransbankLlavePrivada] = useState('');
    const [transbank_certifPublico, setTransbankCertifPublico] = useState('');
    const [paypal_clientId, setPaypalClientId] = useState('');
    const [paypal_clientSecret, setPaypalClientSecret] = useState('');
    const [paypal_tipoPago, setPaypalTipoPago] = useState('');
    const [paypal_importeValor, setPaypalImporteValor] = useState('');
    const [paypal_importeMoneda, setPaypalImporteMoneda] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                if (res.data.medios_de_pago?.medios_de_pago) {
                    setMediosDePago(res.data.medios_de_pago.medios_de_pago);

                    setPaypalTipoPago(res.data.medios_de_pago.paypal?.tipo_pago);
                    setPaypalImporteValor(res.data.medios_de_pago.paypal?.importe_valor);
                    setPaypalImporteMoneda(res.data.medios_de_pago.paypal?.moneda);
                }

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            medios_de_pago,
            mercadopago: {
                access_token: mercadopago_accessToken,
            },
            transbank: {
                codigo_comercio: transbank_codigoComercio,
                llave_privada: transbank_llavePrivada,
                certif_publico: transbank_certifPublico,
            },
            paypal: {
                client_id: paypal_clientId,
                secret: paypal_clientSecret,
                tipo_pago: paypal_tipoPago,
                importe_valor: paypal_importeValor,
                moneda: paypal_importeMoneda,
            }
        }

        axios.put(Global.url_api + 'cliente/update/medios-de-pago/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Config. Medios de Pago " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Configuración de Fechas y Horas">Estas opciones afectan al listado de fechas y horas que puede elegir el cliente cuando está reservando.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <CheckboxList
                                    titulo="Medios de Pago"
                                    opciones={[
                                        {
                                            codigo: 'mercadopago',
                                            nombre: 'Mercadopago'
                                        },
                                        {
                                            codigo: 'transbank',
                                            nombre: 'Transbank'
                                        },
                                        {
                                            codigo: 'paypal',
                                            nombre: 'Paypal'
                                        },
                                    ]}
                                    elegidos={medios_de_pago}
                                    handleChange={setMediosDePago}
                                />
                            </Grid>
                        </Grid>

                        {
                            (medios_de_pago.includes("mercadopago")) &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <br />
                                </Grid>
                                <Grid item xs={12} >
                                    <hr />
                                </Grid>
                                <Grid item xs={12} >
                                    <TituloMini titulo="Mercadopago">Información necesaria de Mercadopago.</TituloMini>
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <Input
                                        label="Access Token"
                                        value={mercadopago_accessToken}
                                        onChange={setMercadopagoAccessToken}
                                        type="text"
                                        helperText="Access Token de Mercadopago"
                                    />
                                </Grid>
                            </Grid>
                        }

                        {
                            (medios_de_pago.includes("transbank")) &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <br />
                                </Grid>
                                <Grid item xs={12} >
                                    <hr />
                                </Grid>
                                <Grid item xs={12} >
                                    <TituloMini titulo="Transbank">Información necesaria de Transbank.</TituloMini>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Input
                                        label="Código de Comercio"
                                        value={transbank_codigoComercio}
                                        onChange={setTransbankCodigoComercio}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Input
                                        label="Llave Privada"
                                        value={transbank_llavePrivada}
                                        onChange={setTransbankLlavePrivada}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Input
                                        label="Certificado Público"
                                        value={transbank_certifPublico}
                                        onChange={setTransbankCertifPublico}
                                        type="text"
                                    />
                                </Grid>

                            </Grid>
                        }

                        {
                            (medios_de_pago.includes("paypal")) &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <br />
                                </Grid>
                                <Grid item xs={12} >
                                    <hr />
                                </Grid>
                                <Grid item xs={12} >
                                    <TituloMini titulo="Paypal">Información necesaria de Paypal.</TituloMini>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Input
                                        label="Client ID"
                                        value={paypal_clientId}
                                        onChange={setPaypalClientId}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Input
                                        label="Secret"
                                        value={paypal_clientSecret}
                                        onChange={setPaypalClientSecret}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}></Grid>
                                <Grid item xs={12} md={4} >
                                    <Select
                                        label="Tipo de Pago"
                                        value={paypal_tipoPago}
                                        options={[
                                            {
                                                value: '1',
                                                label: 'Valor Fijo'
                                            },
                                            {
                                                value: '2',
                                                label: 'Porcentaje'
                                            }
                                        ]}
                                        onChange={setPaypalTipoPago}
                                        type="text"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Input
                                        label="Valor"
                                        value={paypal_importeValor}
                                        onChange={setPaypalImporteValor}
                                        type="tel"
                                        helperText="Valor. Ej: 500 (si quiere cobrar $500 fijos) ó 0.3 (si quiere cobrar 30% del turno) ó  1 (si quiere cobrar el total)"
                                        restricciones={['soloNumerosDecimales']}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Select
                                        label="Moneda de Pago"
                                        value={paypal_importeMoneda}
                                        options={[
                                            {
                                                label: 'Australian dollar',
                                                value: 'AUD'                                        
                                            },
                                            {
                                                label: 'Brazilian real',
                                                value: 'BRL'
                                            },
                                            {
                                                label: 'Canadian dollar',
                                                value: 'CAD'
                                            },
                                            {
                                                label: 'Chinese Renmenbi',
                                                value: 'CNY'
                                            },
                                            {
                                                label: 'Czech koruna',
                                                value: 'CZK'
                                            },
                                            {
                                                label: 'Danish krone',
                                                value: 'DKK'
                                            },
                                            {
                                                label: 'Euro',
                                                value: 'EUR'
                                            },
                                            {
                                                label: 'Hong Kong dollar',
                                                value: 'HKD'
                                            },
                                            {
                                                label: 'Hungarian forint',
                                                value: 'HUF'
                                            },
                                            {
                                                label: 'Israeli new shekel',
                                                value: 'ILS'
                                            },
                                            {
                                                label: 'Japanese yen',
                                                value: 'JPY'
                                            },
                                            {
                                                label: 'Malaysian ringgit',
                                                value: 'MYR'
                                            },
                                            {
                                                label: 'Mexican peso',
                                                value: 'MXN'
                                            },
                                            {
                                                label: 'New Taiwan dollar',
                                                value: 'TWD'
                                            },
                                            {
                                                label: 'New Zealand dollar',
                                                value: 'NZD'
                                            },
                                            {
                                                label: 'Norwegian krone',
                                                value: 'NOK'
                                            },
                                            {
                                                label: 'Philippine peso',
                                                value: 'PHP'
                                            },
                                            {
                                                label: 'Polish złoty',
                                                value: 'PLN'
                                            },
                                            {
                                                label: 'Pound sterling',
                                                value: 'GBP'
                                            },
                                            {
                                                label: 'Russian ruble',
                                                value: 'RUB'
                                            },
                                            {
                                                label: 'Singapore dollar',
                                                value: 'SGD'
                                            },
                                            {
                                                label: 'Swedish krona',
                                                value: 'SEK'
                                            },
                                            {
                                                label: 'Swiss franc',
                                                value: 'CHF'
                                            },
                                            {
                                                label: 'Thai baht',
                                                value: 'THB'
                                            },
                                            {
                                                label: 'United States dollar',
                                                value: 'USD'
                                            },
                                        ]}
                                        onChange={setPaypalImporteMoneda}
                                        type="text"
                                        required
                                    />
                                </Grid>

                            </Grid>
                        }

                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}