import { React, useState, useEffect } from 'react';

import axios from 'axios';
import { Global } from '../../Global';
import Moment from 'react-moment';

import Loading from '../../elementos/Loading';
import ButtonIcon from '../../elementos/ButtonIcon';
import HeaderVista from '../../elementos/HeaderVista';

import LoginOutData from '../../helpers/LoginOutData';

import '../../../assets/css/table.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { TextField } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';

const delay = 0.4;

export default function Clientes() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [clientes, setClientes] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocs, setTotalDocs] = useState(0);
    const [textoSearch, setTextoSearch] = useState('');

    useEffect(() => {

        let timer1 = setTimeout(() => {

            let data = {
                page: (page ? page : 1),
                nombre: (textoSearch ? textoSearch : '')
            }

            axios.post(Global.url_api + 'cliente/get', data, { headers: { Authorization: localStorage.getItem("token") } })
                .then(res => {

                    setClientes(res.data.clientes.docs)
                    setTotalPages(res.data.clientes.totalPages)
                    setTotalDocs(res.data.clientes.totalDocs)
                    setEstadoVista('cargado')

                })
                .catch((error) => {

                });


        }, delay * 1000);


        return () => {
            clearTimeout(timer1);
        };

    }, [textoSearch, page]);

    const handleChangeTextSearch = (e) => {
        setPage(1)
        setTextoSearch(e.target.value)
    }

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    titulo="Clientes"
                    icon={<PeopleAltIcon />}
                    end={<>
                        {
                            (LoginOutData.userHasPermiso(22) ? <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="secondary"
                                startIcon={<SendIcon />}
                                to="/clientes/enviar-comunicacion-email"
                                marginRight
                            >
                                Email
                            </ButtonIcon> : '')
                        }
                        {
                            (LoginOutData.userHasPermiso(9) ? <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="primary"
                                startIcon={<AddIcon />}
                                to="/clientes/nuevo"
                            >
                                Nuevo
                            </ButtonIcon> : '')
                        }
                    </>}
                />
                <Container className="containerBigTable" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="titulo" item xs={12} md={8}>
                            <p>Se encontraron {totalDocs} registros.</p>
                        </Grid>
                        <Grid className="buscador" item xs={12} md={4}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField onChange={handleChangeTextSearch} fullWidth label="Buscar..." variant="standard" />
                            </Box>
                        </Grid>
                    </Grid>
                    <div className="tableContainer">
                        <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th align="center">Alta</th>
                                    <th align="left">Marca</th>
                                    <th align="left">Dominio</th>
                                    <th align="left">Nombre</th>
                                    <th align="left">Email</th>
                                    <th align="center">Teléfono</th>
                                    <th align="center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    clientes.length === 0 &&
                                    <tr>
                                        <td colSpan={7} align='center'>
                                            No hay datos.
                                        </td>
                                    </tr>
                                }
                                {clientes.map((value, key) => {
                                    return (
                                        <tr key={key} >
                                            <td align="center">{<Moment date={value.alta} format="DD/MM/YYYY" />}</td>
                                            <td align="left">{value.marca.marca}</td>
                                            <td align="left">{value.dominio}</td>
                                            <td align="left">{value.nombre}</td>
                                            <td align="left">{value.email}</td>
                                            <td align="center">{value.telefono}</td>
                                            <td align="center">
                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    startIcon={<SettingsIcon />}
                                                    to={'/clientes/' + value._id}
                                                    marginRight
                                                />
                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="success"
                                                    startIcon={<PointOfSaleIcon />}
                                                    to={'/clientes/' + value._id + '/cuenta-corriente'}
                                                    marginRight
                                                />
                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    startIcon={<AppSettingsAltIcon />}
                                                    to={'/clientes/' + value._id + '/app'}
                                                    marginRight
                                                />
                                                <a href={'https://web.whatsapp.com/send/?phone=' + value.telefono} target='_blank'><ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="success"
                                                    startIcon={<WhatsAppIcon />}
                                                    noLink
                                                    marginRight
                                                /></a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        className="table-pagination"
                        showFirstButton showLastButton
                        shape="rounded"
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />

                </Container>
            </>
        )

}