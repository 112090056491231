import { React, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router'

import axios from 'axios'
import { Global } from '../../Global'

import '../../../assets/css/nuevoRegistro.css'

import Loading from '../../elementos/Loading'
import HeaderVista from '../../elementos/HeaderVista'
import TituloMini from '../../elementos/TituloMini'
import Input from '../../elementos/Input'
import SwitchInput from '../../elementos/SwitchInput'
import Error from '../../elementos/Error'
import Divider from '../../elementos/Divider'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import CheckIcon from '@mui/icons-material/Check'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/Email'

export default function ClienteEditAvisosAutomaticos() {

    const [estadoVista, setEstadoVista] = useState('loading')

    const [dominio, setDominio] = useState('')

    const [avisos_email_turno_creado, setAvisosEmailTurnoCreado] = useState('')
    const [avisos_email_turno_actualizado, setAvisosEmailTurnoActualizado] = useState('')
    const [avisos_email_turno_cancelado, setAvisosEmailTurnoCancelado] = useState('')
    const [avisos_email_recordatorio_activo, setAvisosEmailRecordatorioActivo] = useState('')
    const [avisos_email_recordatorio_pago_activo, setAvisosEmailRecordatorioPagoActivo] = useState('')
    const [avisos_email_notifica_admin, setAvisosEmailNotificaAdmin] = useState('')
    const [avisos_email_notifica_admin_destino, setAvisosEmailNotificaAdminDestino] = useState('')
    const [avisos_email_recordatorio, setAvisosEmailRecordatorio] = useState('')
    const [avisos_email_recordatorio_pago, setAvisosEmailRecordatorioPago] = useState('')

    const [avisos_whatsapp_turno_creado, setAvisosWhatsappTurnoCreado] = useState('')
    const [avisos_whatsapp_turno_actualizado, setAvisosWhatsappTurnoActualizado] = useState('')
    const [avisos_whatsapp_turno_cancelado, setAvisosWhatsappTurnoCancelado] = useState('')
    const [avisos_whatsapp_notifica_admin, setAvisosWhatsappNotificaAdmin] = useState('')
    const [avisos_whatsapp_notifica_admin_destino, setAvisosWhatsappNotificaAdminDestino] = useState('')
    const [avisos_whatsapp_recordatorio_activo, setAvisosWhatsappRecordatorioActivo] = useState('')
    const [avisos_whatsapp_recordatorio, setAvisosWhatsappRecordatorio] = useState('')

    const urlParams = useParams()
    const navigate = useNavigate()

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio)

                setAvisosEmailTurnoCreado(res.data.avisos_email.turno_creado)
                setAvisosEmailTurnoActualizado(res.data.avisos_email.turno_actualizado)
                setAvisosEmailTurnoCancelado(res.data.avisos_email.turno_cancelado)
                setAvisosEmailNotificaAdmin((res.data.avisos_email.notifica_admin ? res.data.avisos_email.notifica_admin : false))
                setAvisosEmailNotificaAdminDestino((res.data.avisos_email.notifica_admin_destino ? res.data.avisos_email.notifica_admin_destino : ""))
                setAvisosEmailRecordatorio((res.data.avisos_email.recordatorio ? res.data.avisos_email.recordatorio : 1))
                setAvisosEmailRecordatorioPago((res.data.avisos_email.recordatorio_pago ? res.data.avisos_email.recordatorio_pago : 7))
                setAvisosEmailRecordatorioActivo(res.data.avisos_email.recordatorio !== null)
                setAvisosEmailRecordatorioPagoActivo(res.data.avisos_email.recordatorio_pago !== null && res.data.avisos_email.recordatorio_pago !== undefined)

                setAvisosWhatsappTurnoCreado(res.data.avisos_whatsapp.turno_creado)
                setAvisosWhatsappTurnoActualizado(res.data.avisos_whatsapp.turno_actualizado)
                setAvisosWhatsappTurnoCancelado(res.data.avisos_whatsapp.turno_cancelado)
                setAvisosWhatsappNotificaAdmin((res.data.avisos_whatsapp.notifica_admin ? res.data.avisos_whatsapp.notifica_admin : 0))
                setAvisosWhatsappNotificaAdminDestino((res.data.avisos_whatsapp.notifica_admin ? res.data.avisos_whatsapp.notifica_admin_destino : ""))
                setAvisosWhatsappRecordatorio((res.data.avisos_whatsapp.recordatorio ? res.data.avisos_whatsapp.recordatorio : 0))
                setAvisosWhatsappRecordatorioActivo(res.data.avisos_whatsapp.recordatorio !== null)

                setEstadoVista('cargado')

            })
            .catch((error) => {
                //console.log(error?.response?.data)
                setEstadoVista('error')
            })


    }, [urlParams.id])


    const handleSubmit = (e) => {

        e.preventDefault()

        setEstadoVista('loading')

        let body = {
            avisos_email: {
                turno_creado: avisos_email_turno_creado,
                turno_actualizado: avisos_email_turno_actualizado,
                turno_cancelado: avisos_email_turno_cancelado,
                notifica_admin: avisos_email_notifica_admin,
                notifica_admin_destino: avisos_email_notifica_admin_destino,
                recordatorio: (avisos_email_recordatorio_activo ? avisos_email_recordatorio : null),
                recordatorio_pago: (avisos_email_recordatorio_pago_activo ? avisos_email_recordatorio_pago : null),
            },
            avisos_whatsapp: {
                turno_creado: avisos_whatsapp_turno_creado,
                turno_actualizado: avisos_whatsapp_turno_actualizado,
                turno_cancelado: avisos_whatsapp_turno_cancelado,
                notifica_admin: avisos_whatsapp_notifica_admin,
                notifica_admin_destino: avisos_whatsapp_notifica_admin_destino,
                recordatorio: (avisos_whatsapp_recordatorio_activo ? avisos_whatsapp_recordatorio : null),
            },
        }

        axios.put(Global.url_api + 'cliente/update/avisos-automaticos/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            })

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Notificaciones Automáticas " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Notificaciones Automáticas">Activar o desactivar las notificaciones automáticas.</TituloMini>
                            </Grid>
                            <br />
                            <Grid item xs={12} style={{ fontSize: '1.2rem', fontWeight: '600' }}>
                                <EmailIcon /> Notificaciones por Email
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Turno Creado"
                                    value={avisos_email_turno_creado}
                                    onChange={setAvisosEmailTurnoCreado}
                                    helperText="Recibirá un email al crearse el turno."
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Turno Actualizado"
                                    value={avisos_email_turno_actualizado}
                                    onChange={setAvisosEmailTurnoActualizado}
                                    helperText="Recibirá un email al actualizarse el turno."
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Turno Cancelado"
                                    value={avisos_email_turno_cancelado}
                                    onChange={setAvisosEmailTurnoCancelado}
                                    helperText="Recibirá un email al cancelarse el turno."
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Recordatorio de Turno"
                                    value={avisos_email_recordatorio_activo}
                                    onChange={setAvisosEmailRecordatorioActivo}
                                    helperText="Activar recordatorio de turno."
                                />
                                <Input
                                    label="Días Recordatorios"
                                    value={avisos_email_recordatorio}
                                    onChange={setAvisosEmailRecordatorio}
                                    type="tel"
                                    helperText="¿Cuántos días antes del turno debe enviarse el recordatorio?"
                                    restricciones={['soloNumeros']}
                                    disabled={!avisos_email_recordatorio_activo}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Recordatorio de Pago"
                                    value={avisos_email_recordatorio_pago_activo}
                                    onChange={setAvisosEmailRecordatorioPagoActivo}
                                    helperText="Activar recordatorio de pago."
                                />
                                <Input
                                    label="Días Recordatorios"
                                    value={avisos_email_recordatorio_pago}
                                    onChange={setAvisosEmailRecordatorioPago}
                                    type="tel"
                                    helperText="¿Cuántos días antes del turno debe enviarse el recordatorio?"
                                    restricciones={['soloNumeros']}
                                    disabled={!avisos_email_recordatorio_pago_activo}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Notifica Admin"
                                    value={avisos_email_notifica_admin}
                                    onChange={setAvisosEmailNotificaAdmin}
                                    helperText="Se enviará una copia oculta al email de la marca."
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Destino Notifica Admin"
                                    value={avisos_email_notifica_admin_destino}
                                    onChange={setAvisosEmailNotificaAdminDestino}
                                    type="tel"
                                    helperText="email"
                                    disabled={!avisos_email_notifica_admin}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} style={{ fontSize: '1.2rem', fontWeight: '600' }}>
                                <WhatsAppIcon /> Notificaciones por Whatsapp
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Turno Creado"
                                    value={avisos_whatsapp_turno_creado}
                                    onChange={setAvisosWhatsappTurnoCreado}
                                    helperText="Recibirá un whatsapp al crearse el turno."
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Turno Actualizado"
                                    value={avisos_whatsapp_turno_actualizado}
                                    onChange={setAvisosWhatsappTurnoActualizado}
                                    helperText="Recibirá un whatsapp al actualizarse el turno."
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Turno Cancelado"
                                    value={avisos_whatsapp_turno_cancelado}
                                    onChange={setAvisosWhatsappTurnoCancelado}
                                    helperText="Recibirá un whatsapp al cancelarse el turno."
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Recordatorio"
                                    value={avisos_whatsapp_recordatorio_activo}
                                    onChange={setAvisosWhatsappRecordatorioActivo}
                                    helperText="Activar recordatorio."
                                />
                                <Input
                                    label="Días Recordatorios"
                                    value={avisos_whatsapp_recordatorio}
                                    onChange={setAvisosWhatsappRecordatorio}
                                    type="tel"
                                    helperText="¿Cuántos días antes del turno debe enviarse el recordatorio?"
                                    restricciones={['soloNumeros']}
                                    disabled={!avisos_whatsapp_recordatorio_activo}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Notifica Admin"
                                    value={avisos_whatsapp_notifica_admin}
                                    onChange={setAvisosWhatsappNotificaAdmin}
                                    helperText="El administrador recibirá un mensaje cada vez que reserve/reprograme/cancele un turno el cliente."
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Destino Notifica Admin"
                                    value={avisos_whatsapp_notifica_admin_destino}
                                    onChange={setAvisosWhatsappNotificaAdminDestino}
                                    type="tel"
                                    helperText="5491101230123"
                                    disabled={!avisos_whatsapp_notifica_admin}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}