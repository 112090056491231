import React from 'react';

import '../../assets/css/HeaderVista.css';

import Volver from './Volver';
import TextoConIcono from './TextoConIcono';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';


export default function HeaderVista(props) {

    return (
        <Container className="Headervista" component="main" maxWidth="lg">
            <Grid container>
                <Grid className="start" item xs={12} md={2} >
                    {
                        props.volverTo && <Volver to={props.volverTo} />
                    }
                </Grid>
                <Grid className="titulo" item xs={12} md={8} >
                    <TextoConIcono texto={props.titulo} icono={props.icon} padding={'0'} justifyContent="center" />
                </Grid>
                <Grid className="end" item xs={12} md={2} >
                    {props.end}
                </Grid>
            </Grid>
        </Container>
    )

}