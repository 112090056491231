import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import SwitchInput from '../../elementos/SwitchInput';
import Input from '../../elementos/Input';
import Select from '../../elementos/Select';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditCobraSena() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [medio_de_pago, setMedioDePago] = useState('');
    const [deben_pagar, setDebenPagar] = useState('');
    const [pueden_pagar, setPuedenPagar] = useState('');
    const [importe_debe, setImporteDebe] = useState('');
    const [importe_valor, setImporteValor] = useState('');
    const [descuento_puede_pagar, setDescuentoPuedePagar] = useState('');
    const [completa_pago, setCompletaPago] = useState('');
    const [completa_dias_aviso, setCompletaDiasAviso] = useState('');
    const [completa_hs_baja, setCompletaHsBaja] = useState('');
    const [puede_pagar_en_local, setPuedePagarEnLocal] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                setMedioDePago(res.data.cobra_sena.medio_de_pago);
                setDebenPagar(res.data.cobra_sena.deben_pagar);
                setPuedenPagar(res.data.cobra_sena.pueden_pagar);
                setImporteDebe(res.data.cobra_sena.importe_debe);
                setImporteValor(res.data.cobra_sena.importe_valor);
                setDescuentoPuedePagar(res.data.cobra_sena.descuento_puede_pagar);
                setCompletaPago(res.data.cobra_sena.completa_pago);
                setCompletaDiasAviso(res.data.cobra_sena.completa_dias_aviso);
                setCompletaHsBaja(res.data.cobra_sena.completa_hs_baja);
                setPuedePagarEnLocal(res.data.cobra_sena.puede_pagar_en_local);

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            medio_de_pago,
            deben_pagar,
            pueden_pagar,
            importe_debe,
            importe_valor,
            descuento_puede_pagar,
            completa_pago,
            completa_dias_aviso,
            completa_hs_baja,
            puede_pagar_en_local
        }

        axios.put(Global.url_api + 'cliente/update/cobra-sena/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Config. Seña " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Seña Obligatoria">Opciones de pago cuando el cliente está obligado a pagar.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <SwitchInput
                                    label="Deben Pagar"
                                    value={deben_pagar}
                                    onChange={setDebenPagar}
                                    helperText="¿El cliente está obligado a pagar?"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Tipo de Pago"
                                    value={importe_debe}
                                    options={[
                                        {
                                            value: '1',
                                            label: 'Valor Fijo'
                                        },
                                        {
                                            value: '2',
                                            label: 'Porcentaje'
                                        }
                                    ]}
                                    onChange={setImporteDebe}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Input
                                    label="Valor"
                                    value={importe_valor}
                                    onChange={setImporteValor}
                                    type="tel"
                                    helperText="Valor. Ej: 500 (si quiere cobrar $500 fijos) ó 0.3 (si quiere cobrar 30% del turno) ó  1 (si quiere cobrar el total)"
                                    restricciones={['soloNumerosDecimales']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <hr />
                            </Grid>
                            <Grid item xs={12} >
                                <TituloMini titulo="Pago por Adelantado">El cliente tiene la posibilidad de pagar por adelantado (cuando reserva el turno). Si la empresa desea, puede hacerle un descuento.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Pueden Pagar"
                                    value={pueden_pagar}
                                    onChange={setPuedenPagar}
                                    helperText="¿El cliente puede pagar por adelantado?"
                                />
                            </Grid>
                            <Grid item xs={12} md={2} >

                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Input
                                    label="Descuento %"
                                    value={descuento_puede_pagar}
                                    onChange={setDescuentoPuedePagar}
                                    type="tel"
                                    helperText="Porcentaje de descuento si el cliente decide pagar por adelantado. Ej: 0.3 (paga el 30%) ó 0 (paga el 100%)"
                                    restricciones={['soloNumerosDecimales']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <hr />
                            </Grid>
                            <Grid item xs={12} >
                                <TituloMini titulo="Pagar en local">Habilita la opción de pagar en local (quiere decir que el turno se reserva sin que el cliente pague).</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <SwitchInput
                                    label="Puede Pagar en Local"
                                    value={puede_pagar_en_local}
                                    onChange={setPuedePagarEnLocal}
                                    helperText="A pesar de tener que pagar seña, el cliente tendrá la opción de reservar sin pagar (opción pagar en local)."
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <hr />
                            </Grid>
                            <Grid item xs={12} >
                                <TituloMini titulo="Completar Pago">El cliente puede completar el pago desde el detalle del turno.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Select
                                    label="Completar el Pago"
                                    value={completa_pago}
                                    options={[
                                        {
                                            value: '0',
                                            label: 'No puede completar el pago'
                                        },
                                        {
                                            value: '1',
                                            label: 'Pude completar el pago'
                                        },
                                    ]}
                                    onChange={setCompletaPago}
                                    type="text"
                                    helperText="En la vista de cliente, al ingresar al detalle del turno, el cliente tendrá la opción 'Completar Pago'."
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                
                            </Grid>
                            <Grid item xs={12} md={4} >
                                
                            </Grid>


                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}