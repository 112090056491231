import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Moment from 'react-moment';

import NumberFormat from 'react-number-format';

import axios from 'axios';
import { Global } from '../../../Global';

import LoginOutData from '../../../helpers/LoginOutData';

import '../../../../assets/css/detalleRegistro.css';

import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import ButtonIcon from '../../../elementos/ButtonIcon';
import ConfirmDelete from '../../../elementos/ConfirmDelete';


import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

export default function ClientePlanes() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [planes, setPlanes] = useState([]);

    const urlParams = useParams();


    useEffect(() => {

        axios.get(Global.url_api + 'clientes-planes/bycliente-con-deuda/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                if (res.data.deuda) setPlanes([res.data]);
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={6} style={{ marginBottom: '0px', fontSize: '1.2rem' }}>
                        <AutoAwesomeMotionIcon /> Planes Activos
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div align="right">
                            {
                                LoginOutData.userHasPermiso(12) &&
                                <ButtonIcon
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    to={"/clientes/" + urlParams.id + "/cuenta-corriente/planes/nuevo"}
                                >
                                    Nuevo
                                </ButtonIcon>
                            }
                        </div>
                    </Grid>
                </Grid>
                <div className="tableContainer">
                    <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th align="center">Activo</th>
                                <th align="left">Nombre</th>
                                <th align="center">Inicio</th>
                                <th align="center">Fin</th>
                                <th align="right">Cuota</th>
                                <th align="right">Meses Deuda</th>
                                <th align="right">Deuda</th>
                                <th align="center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                planes.map(function (plan, key) {
                                    return (<tr key={key}>
                                        <td align="center">{(plan.deuda.activo ? 'Sí' : 'No')}</td>
                                        <td align="left">{plan.plan.nombre}</td>
                                        <td align="center">{<Moment date={plan.deuda.inicio} format="DD/MM/YYYY" />}</td>
                                        <td align="center">{(plan.fin ? <Moment date={plan.deuda.fin} format="DD/MM/YYYY" /> : '-')}</td>
                                        <td align="right">
                                            <NumberFormat
                                                value={plan.deuda.importe_plan}
                                                className="foo"
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                prefix={'$'}
                                            />
                                        </td>
                                        <td align="right">
                                            {plan.deuda.periodos_deuda}
                                        </td>
                                        <td align="right">
                                            <NumberFormat
                                                value={plan.deuda.deuda_importe}
                                                className="foo"
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                prefix={'$'}
                                            />
                                        </td>
                                        <td align="center">
                                            {
                                                LoginOutData.userHasPermiso(12) &&
                                                <ConfirmDelete
                                                    variant="outlined"
                                                    size="small"
                                                    httpDelete={"clientes-planes/" + plan.plan.id}
                                                    returnUrl={"/clientes/" + urlParams.id + "/cuenta-corriente"}
                                                    soloIcono
                                                />
                                            }
                                        </td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )

}