import { React, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import axios from 'axios'
import { Global } from '../../Global'

import '../../../assets/css/nuevoRegistro.css'

import Loading from '../../elementos/Loading'
import Error from '../../elementos/Error'
import Input from '../../elementos/Input'
import Snack from '../../elementos/Snack'
import HeaderVista from '../../elementos/HeaderVista'
import ConfirmDelete from '../../elementos/ConfirmDelete'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import CheckIcon from '@mui/icons-material/Check'
import FilterNoneIcon from '@mui/icons-material/FilterNone'


export default function RubrosEdit() {

    const navigate = useNavigate()
    const urlParams = useParams()

    const [estadoVista, setEstadoVista] = useState('cargado')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })

    const [nombre, setNombre] = useState('')

    useEffect(() => {

        setEstadoVista('loading')

        axios.get(Global.url_api + 'gastos/rubros/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setNombre(res.data.nombre)
                setEstadoVista('cargado')

            })
            .catch((error) => {
                setEstadoVista('error');
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                });
            })



    }, [urlParams])

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            nombre,
        }

        axios.put(Global.url_api + 'gastos/rubros/' + urlParams.id, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: 'Se creó el registro.',
                    severity: 'success'
                })
                navigate('/gastos/rubros')

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado')
            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/gastos/rubros"
                    titulo="Nuevo Rubro"
                    end={<ConfirmDelete
                        httpDelete={"gastos/rubros/" + urlParams.id}
                        returnUrl={"/gastos/rubros"}
                    />}
                />

                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Nombre"
                                    icon={<FilterNoneIcon />}
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}