import { React, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../../Global';

import '../../../../assets/css/nuevoRegistro.css';

import Loading from '../../../elementos/Loading';
import HeaderVista from '../../../elementos/HeaderVista';
import Select from '../../../elementos/Select';
import Input from '../../../elementos/Input';
import Snack from '../../../elementos/Snack';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CheckIcon from '@mui/icons-material/Check';


export default function ClientePagosNuevo() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })

    const [dominio, setDominio] = useState('');
    const [planesCliente, setPlanesCliente] = useState([]);
    const [clientePlanElegido, setClientePlanElegido] = useState('');
    const [importeElegido, setImporteElegido] = useState('');

    const navigate = useNavigate();
    const urlParams = useParams();

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            cliente: urlParams.id,
            cliente_plan: clientePlanElegido,
            importe: importeElegido,
            modalidad: 'Manual'
        }

        axios.post(Global.url_api + 'clientes-pagos', body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate("/clientes/" + urlParams.id + "/cuenta-corriente");

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id + '/dominio', { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                axios.get(Global.url_api + 'clientes-planes/bycliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
                    .then(res => {

                        setPlanesCliente((res.data ? [res.data] : []));
                        setEstadoVista('cargado')

                    })
                    .catch((error) => {
                        console.log(error?.response?.data);
                        setEstadoVista('cargado')
                    });

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('cargado')
            });



    }, [urlParams]);

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id + "/cuenta-corriente"}
                    titulo={dominio + "- Nuevo pago"}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Planes Asociados"
                                    icon={<AutoAwesomeMotionIcon />}
                                    value={clientePlanElegido}
                                    options={planesCliente.map((item) => (
                                        {
                                            value: item.id,
                                            label: item.plan
                                        }
                                    ))}
                                    onChange={setClientePlanElegido}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Input
                                    label="Importe"
                                    value={importeElegido}
                                    onChange={setImporteElegido}
                                    type="tel"
                                    helperText="Importe pagado"
                                    restricciones={['soloNumerosDecimales']}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}