import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import Input from '../../elementos/Input';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StoreIcon from '@mui/icons-material/Store';
import CheckIcon from '@mui/icons-material/Check';
import LanguageIcon from '@mui/icons-material/Language';


export default function ClienteEditMarca() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [marca_nombre, setMarca_nombre] = useState('');
    const [marca_email, setMarca_email] = useState('');
    const [marca_ejcel, setMarca_ejcel] = useState('');
    const [marca_telefono, setMarca_telefono] = useState('');
    const [marca_mapa, setMarca_mapa] = useState('');
    const [marca_direccion, setMarca_direccion] = useState('');
    const [marca_telpais, setMarca_telpais] = useState('');
    const [marca_telarea, setMarca_telarea] = useState('');
    const [lenguaje, setLenguaje] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);
                setMarca_nombre(res.data.marca.marca);
                setMarca_email(res.data.marca.email);
                setMarca_ejcel(res.data.marca.ej_cel);
                setMarca_telefono(res.data.marca.telefono);
                setMarca_mapa(res.data.marca.mapa);
                setMarca_direccion(res.data.marca.direccion);
                setMarca_telpais(res.data.marca.tel_pais);
                setMarca_telarea(res.data.marca.tel_area);
                setLenguaje(res.data.lenguaje);
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            marca: marca_nombre,
            email: marca_email,
            ej_cel: marca_ejcel,
            telefono: marca_telefono,
            mapa: marca_mapa,
            direccion: marca_direccion,
            tel_pais: marca_telpais,
            tel_area: marca_telarea,
            lenguaje: lenguaje,
        }

        axios.put(Global.url_api + 'cliente/update/marca/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Marca" + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TituloMini titulo="Info de Marca">Información que visualizarán los clientes que deseen reservar un turno.</TituloMini>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Nombre de Marca"
                                    icon={<StoreIcon />}
                                    value={marca_nombre}
                                    onChange={setMarca_nombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Email"
                                    icon={<MailOutlineIcon />}
                                    value={marca_email}
                                    onChange={setMarca_email}
                                    type="email"
                                    restricciones={['email']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Dirección (ej: Calle 123, Barrio)"
                                    icon={<LocationOnIcon />}
                                    value={marca_direccion}
                                    onChange={setMarca_direccion}
                                    type="text"
                                    helperText="Sin dirección poner #"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="URL Mapa"
                                    icon={<LocationOnIcon />}
                                    value={marca_mapa}
                                    onChange={setMarca_mapa}
                                    type="text"
                                    helperText="Sin dirección poner #"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Ej Cel"
                                    icon={<WhatsAppIcon />}
                                    helperText="Formato 11-3333-5555. Sin cel #."
                                    value={marca_ejcel}
                                    onChange={setMarca_ejcel}
                                    type="tel"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Whatsapp para clientes"
                                    icon={<WhatsAppIcon />}
                                    helperText="Formato 5491133335555. Sin cel #."
                                    value={marca_telefono}
                                    onChange={setMarca_telefono}
                                    type="tel"
                                    restricciones={['soloNumeros']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Código de País (ej: 549)"
                                    icon={<WhatsAppIcon />}
                                    value={marca_telpais}
                                    onChange={setMarca_telpais}
                                    type="tel"
                                    helperText="Dejar vacío si no se quiere poner un default."
                                    restricciones={['soloNumeros']}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Código de Área (ej: 11)"
                                    icon={<WhatsAppIcon />}
                                    value={marca_telarea}
                                    onChange={setMarca_telarea}
                                    type="tel"
                                    helperText="Dejar vacío si no se quiere poner un default."
                                    restricciones={['soloNumeros']}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Input
                                    label="Lenguaje"
                                    icon={<LanguageIcon />}
                                    value={lenguaje}
                                    onChange={setLenguaje}
                                    type="text"
                                    helperText="Ej: es_ar/es_cl/es_ar_formal"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}