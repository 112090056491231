import { React, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import '../../../../assets/css/niceTable.css'

import axios from 'axios';
import { Global } from '../../../Global';


import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Snack from '../../../elementos/Snack';

import Grid from '@mui/material/Grid';


export default function FacturacionPorProvincia(props) {

    const [estadoVista, setEstadoVista] = useState('loading')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })


    const [facturado, setFacturado] = useState([])

    useEffect(() => {

        setEstadoVista('loading')

        axios.get(Global.url_api + 'reportes/facturacion/provincia/' + props.vendedor, {
            headers: {
                Authorization: localStorage.getItem("token"),
                "from": props.fechaInicio,
                "to": props.fechaFin
            }
        })
            .then(res => {

                setFacturado(res.data);
                setEstadoVista('cargado');


            })
            .catch((error) => {
                //console.log(error?.response?.data);

                setEstadoVista('cargado');

                setSnackOpen({
                    open: true,
                    message: error.response.data.message,
                    severity: 'error'
                });
            });

    }, [props])


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3 className="text-center text-md-start" style={{ marginBottom: '25px' }}>
                            Fact. AFIP por Provincia
                        </h3>
                    </Grid>
                </Grid>
                <table className='niceTable' width="100%" cellSpacing={0}>
                    <thead>
                        <tr>
                            <td>Provincia</td>
                            <td align='right'>Importe</td>
                        </tr>
                    </thead>
                    <tbody>
                        {facturado.map((item, index) => {

                            return (
                                <tr key={index}>
                                    <td>{item.provincia}</td>
                                    <td align='right'>
                                        <NumberFormat
                                            value={item.importe}
                                            className="foo"
                                            displayType={'text'}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            prefix={'$ '}
                                        />
                                    </td>
                                </tr>
                            )

                        })}
                    </tbody>
                </table>
                <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
            </>
        )

}