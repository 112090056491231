import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import SwitchInput from '../../elementos/SwitchInput';
import Input from '../../elementos/Input';
import Error from '../../elementos/Error';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditTurnoReprograma() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [cancela, setCancela] = useState('');
    const [cancela_hs, setCancelaHs] = useState('');
    const [reprograma, setReprograma] = useState('');
    const [reprograma_hs, setReprogramaHs] = useState('');
    const [reprograma_cant_maxima, setReprogramaCantMaxima] = useState('');

    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                setCancela((res.data.turno_reprograma.cancela ? true : false));
                setCancelaHs(res.data.turno_reprograma.cancela_hs);
                setReprograma((res.data.turno_reprograma.reprograma ? true : false));
                setReprogramaHs(res.data.turno_reprograma.reprograma_hs);
                setReprogramaCantMaxima((res.data.turno_reprograma.reprograma_cant_maxima ? res.data.turno_reprograma.reprograma_cant_maxima : 0));

                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            cancela,
            cancela_hs,
            reprograma,
            reprograma_hs,
            reprograma_cant_maxima,
        }

        axios.put(Global.url_api + 'cliente/update/turno-reprograma/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Editar Config. Reprograma " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Configuración de Reprogramaciones">Estas opciones indican la posibilidad de reprogramar que tiene el cliente.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Cancela"
                                    value={cancela}
                                    onChange={setCancela}
                                    helperText="Puede cancelar el turno"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Cancela HS"
                                    value={cancela_hs}
                                    onChange={setCancelaHs}
                                    type="tel"
                                    helperText="Horas de anticipación para cancelar un turno"
                                    restricciones={['soloNumeros']}
                                    disabled={!cancela}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} ></Grid>
                            <Grid item xs={12} md={4} >
                                <SwitchInput
                                    label="Reprograma"
                                    value={reprograma}
                                    onChange={setReprograma}
                                    helperText="Puede reprogramar el turno"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Reprograma HS"
                                    value={reprograma_hs}
                                    onChange={setReprogramaHs}
                                    type="tel"
                                    helperText="Horas de anticipación para reprogramar un turno"
                                    restricciones={['soloNumeros']}
                                    disabled={!reprograma}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Input
                                    label="Reprograma Cant. Máxima"
                                    value={reprograma_cant_maxima}
                                    onChange={setReprogramaCantMaxima}
                                    type="tel"
                                    helperText="Cantidad de veces que puede reprogramar el cliente un mismo turno. Para reprogramaciones ilimitadas colocar 0"
                                    restricciones={['soloNumeros']}
                                    disabled={!reprograma}
                                    required
                                />
                            </Grid>



                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </>
        )

}