import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { SketchPicker } from 'react-color';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import Loading from '../../elementos/Loading';
import HeaderVista from '../../elementos/HeaderVista';
import TituloMini from '../../elementos/TituloMini';
import Error from '../../elementos/Error';
import TextEditor from '../../elementos/TextEditor';
import SwitchInput from '../../elementos/SwitchInput';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';


export default function ClienteEditMensajeHome() {

    const [estadoVista, setEstadoVista] = useState('loading');

    const [dominio, setDominio] = useState('');

    const [activo, setActivo] = useState(false);
    const [mensaje_home, setMensajeHome] = useState('');
    const [color_fondo, setColorFondo] = useState('');
    const [color_texto, setColorTexto] = useState('');


    const urlParams = useParams();
    const navigate = useNavigate();

    const handleChangeColorFondo = (color, event) => {
        setColorFondo(color.hex)
    }
    const handleChangeColorTexto = (color, event) => {
        setColorTexto(color.hex)
    }

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);

                setActivo(res.data.home.activo)
                setMensajeHome(res.data.home.mensaje_home)
                setColorFondo(res.data.home.color_fondo)
                setColorTexto(res.data.home.color_texto)
                setEstadoVista('cargado');

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams.id]);


    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            activo,
            mensaje_home,
            color_fondo,
            color_texto,
        }

        axios.put(Global.url_api + 'cliente/update/home/' + dominio, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate('/clientes/' + urlParams.id);

            })
            .catch((error) => {

            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id}
                    titulo={"Mensaje Home " + dominio}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={4}>
                                <h4 align="center">Activo</h4>
                                <SwitchInput
                                    label="Activo"
                                    value={activo}
                                    onChange={setActivo}
                                    helperText="Si está prendido, el mensaje se mostrará a los clientes en la web de la tienda."
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <h4 align="center">Color Fondo</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={color_fondo}
                                        onChangeComplete={handleChangeColorFondo}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <h4 align="center">Color Texto</h4>
                                <div align="center">
                                    <SketchPicker
                                        color={color_texto}
                                        onChangeComplete={handleChangeColorTexto}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <br />
                            <Grid item xs={12} >
                                <TituloMini titulo="Mensaje Home">Mensaje que se visualiza en la vista de cliente arriba del botón de Registrarse/Ingresar.</TituloMini>
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <TextEditor textoHtml={mensaje_home} setTextoHtml={setMensajeHome} />
                            </Grid>

                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                            
                        </Grid>
                    </Container>
                </form>
            </>
        )

}