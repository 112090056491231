import { React, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';
import { Global } from '../../../Global';

import '../../../../assets/css/nuevoRegistro.css';

import Loading from '../../../elementos/Loading';
import HeaderVista from '../../../elementos/HeaderVista';
import Select from '../../../elementos/Select';
import Snack from '../../../elementos/Snack';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CheckIcon from '@mui/icons-material/Check';


export default function ClientePlanesNuevo() {

    const [estadoVista, setEstadoVista] = useState('loading');
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })

    const [dominio, setDominio] = useState('');
    const [planes, setPlanes] = useState([]);
    const [plan, setPlan] = useState('');

    const navigate = useNavigate();
    const urlParams = useParams();

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            plan,
            cliente: urlParams.id
        }

        axios.post(Global.url_api + 'clientes-planes', body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                navigate("/clientes/" + urlParams.id + "/cuenta-corriente");

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado');
            });

    }

    useEffect(() => {

        axios.get(Global.url_api + 'cliente/' + urlParams.id + '/dominio', { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setDominio(res.data.dominio);
                
                axios.get(Global.url_api + 'planes', { headers: { Authorization: localStorage.getItem("token") } })
                .then(res => {

                    setPlanes(res.data.planes.registros);
                    setEstadoVista('cargado');

                })
                .catch((error) => {
                    console.log(error?.response?.data);
                    setEstadoVista('error')
                });

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });

    }, [urlParams]);

    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo={"/clientes/" + urlParams.id + "/cuenta-corriente"}
                    titulo={dominio + "- Nuevo Plan"}
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} >
                                <Select
                                    label="Plan"
                                    icon={<AutoAwesomeMotionIcon />}
                                    value={plan}
                                    options={planes.map((item) => (
                                        {
                                            value: item.id,
                                            label: item.nombre
                                        }
                                    ))}
                                    onChange={setPlan}
                                    type="text"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}