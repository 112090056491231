import { React, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import '../../../../assets/css/niceTable.css'

import axios from 'axios';
import { Global } from '../../../Global';


import Loading from '../../../elementos/Loading';
import Error from '../../../elementos/Error';
import Snack from '../../../elementos/Snack';

import Grid from '@mui/material/Grid';


export default function PlanesVigentes(props) {

    const [estadoVista, setEstadoVista] = useState('loading')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })


    const [planesVigentes, setPlanesVigentes] = useState([])
    const [totalImportePlanes, setTotalImportePlanes] = useState(0)

    useEffect(() => {

        setEstadoVista('loading')

        axios.get(Global.url_api + 'reportes/planes-vigentes/' + props.vendedor, {
            headers: {
                Authorization: localStorage.getItem("token"),
                "from": props.fechaInicio,
                "to": props.fechaFin
            }
        })
            .then(res => {

                setPlanesVigentes(res.data)
                setTotalImportePlanes(res.data.map(item => item.total_importe).reduce((partialSum, a) => partialSum + a, 0))
                setEstadoVista('cargado')

            })
            .catch((error) => {
                //console.log(error?.response?.data);

                setPlanesVigentes([])
                setTotalImportePlanes(0)
                setEstadoVista('cargado')

                setSnackOpen({
                    open: true,
                    message: error.response.data.message,
                    severity: 'error'
                })
            });

    }, [props])


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3 className="text-center" style={{ marginBottom: '25px' }}>
                            Planes Vigentes
                        </h3>
                    </Grid>
                </Grid>
                <table className='niceTable' width="100%" cellSpacing={0}>
                    <thead>
                        <tr>
                            <td>Plan</td>
                            <td align='right'>Suscriptos</td>
                            <td align='right'>Importe</td>
                            <td align='right'>Total</td>
                            <td align='right'>Porcentaje</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            planesVigentes.length === 0 &&
                            <tr>
                                <td colSpan={5} align='center'>No hay datos.</td>
                            </tr>
                        }
                        {
                            planesVigentes.map(function (item, key) {
                                return (
                                    <tr key={key}>
                                        <td>{item.plan_nombre}</td>
                                        <td align='right'>
                                            <NumberFormat
                                                value={item.cantidad}
                                                className="foo"
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                prefix={''}
                                            />
                                        </td>
                                        <td align='right'>
                                            <NumberFormat
                                                value={item.plan_importe}
                                                className="foo"
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                prefix={'$ '}
                                            />
                                        </td>
                                        <td align='right'>
                                            <NumberFormat
                                                value={item.total_importe}
                                                className="foo"
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                prefix={'$ '}
                                            />
                                        </td>
                                        <td align='right'>
                                            <NumberFormat
                                                value={Math.round((item.total_importe * 100 / totalImportePlanes) * 100) / 100}
                                                className="foo"
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                suffix={' %'}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
            </>
        )

}