import { React, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import axios from 'axios'
import { Global } from '../../Global'

import '../../../assets/css/nuevoRegistro.css'

import Loading from '../../elementos/Loading'
import Error from '../../elementos/Error'
import Input from '../../elementos/Input'
import Snack from '../../elementos/Snack'
import HeaderVista from '../../elementos/HeaderVista'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import CheckIcon from '@mui/icons-material/Check'
import NumbersIcon from '@mui/icons-material/Numbers'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'

export default function PermisosappEdit() {

    const urlParams = useParams()

    const [estadoVista, setEstadoVista] = useState('cargado')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' })


    const [codigo, setCodigo] = useState('')
    const [nombre, setNombre] = useState('')
    const [descripcion, setDescripcion] = useState('')

    const navigate = useNavigate()

    useEffect(() => {

        axios.get(Global.url_api + 'mysql/permisos-by-id/' + urlParams.id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
            .then(res => {

                setCodigo((res.data.codigo ? res.data.codigo : ''))
                setNombre((res.data.nombre ? res.data.nombre : ''))
                setDescripcion((res.data.descripcion ? res.data.descripcion : ''))

            })
            .catch((error) => {
                setEstadoVista('error');
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                });
            })

    }, [])

    const handleSubmit = (e) => {

        e.preventDefault();

        setEstadoVista('loading');

        let body = {
            codigo,
            nombre,
            descripcion,
        }

        axios.put(Global.url_api + 'mysql/permisos/' + urlParams.id, body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: 'Se creó el registro.',
                    severity: 'success'
                })
                navigate('/permisosapp')

            })
            .catch((error) => {
                //console.log(error?.response?.data);
                console.log(error?.response?.data)
                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })
                setEstadoVista('cargado')
            });

    }


    if (estadoVista === 'loading') return (<Loading />)

    if (estadoVista === 'error') return (<Error />)

    if (estadoVista === 'cargado')
        return (
            <>
                <HeaderVista
                    volverTo="/permisosapp"
                    titulo="Editar Permiso"
                    end={<ConfirmDelete
                        httpDelete={"mysql/permisos/" + urlParams.id}
                        returnUrl={"/permisosapp"}
                    />}
                />

                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={4} >
                                <Input
                                    label="Código"
                                    icon={<NumbersIcon />}
                                    value={codigo}
                                    onChange={setCodigo}
                                    type="tel"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={8} >
                                <Input
                                    label="Nombre"
                                    icon={<DriveFileRenameOutlineIcon />}
                                    value={nombre}
                                    onChange={setNombre}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <Input
                                    label="Descripción"
                                    icon={<DriveFileRenameOutlineIcon />}
                                    value={descripcion}
                                    onChange={setDescripcion}
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

}