import { React } from 'react';

import '../../assets/css/Error.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';


export default function Error() {

    return(
        <Container component="main" >
            <Grid container spacing={3}>
                <Grid item xs={12} align="center">
                    <div id="error">
                        <div className="titulo">¡Ups!</div>
                    </div>  
                    <div id="errorLeyenda">
                        <p>Parece que ocurrió un problema al acceder a la página.
                        <br />Por favor intenta nuevamente.</p>
                    </div>
                    
                </Grid>
            </Grid >
        </Container>
    )

}