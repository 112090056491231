import { React, useState } from 'react';

import axios from 'axios';
import { Global } from '../../Global';

import '../../../assets/css/nuevoRegistro.css';

import HeaderVista from '../../elementos/HeaderVista';
import Input from '../../elementos/Input';
import Snack from '../../elementos/Snack';
import TextEditor from '../../elementos/TextEditor';

import LoginOutData from '../../helpers/LoginOutData';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';


export default function ClientesEnviarComunicacionEmail() {

    const [asunto, setAsunto] = useState('')
    const [mensaje, setMensaje] = useState('')
    const [snackOpen, setSnackOpen] = useState({ open: false, message: '', severity: 'error' });


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            asunto,
            mensaje,
        }

        axios.post(Global.url_api + 'cliente/enviar-comunicacion-email', body, { headers: { Authorization: localStorage.getItem("token") } })
            .then(res => {

                setSnackOpen({
                    open: true,
                    message: "Mensajes enviados!",
                    severity: 'success'
                })

            })
            .catch((error) => {

                setSnackOpen({
                    open: true,
                    message: (error?.response?.data ? error.response.data.message : 'Ocurrió un error.'),
                    severity: 'error'
                })

            });

    }

    if (LoginOutData.userHasPermiso(22))
        return (
            <>
                <HeaderVista
                    volverTo="/clientes"
                    titulo="Enviar Email"
                />
                <form onSubmit={handleSubmit}>
                    <Container className="nuevoRegistro" component="main" maxWidth="lg">

                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Input
                                    label="Asunto"
                                    icon={<EmailIcon />}
                                    helperText="Asunto del Email"
                                    value={asunto}
                                    onChange={setAsunto}
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextEditor textoHtml={mensaje} setTextoHtml={setMensaje} />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="nuevoRegistro footer" component="main" maxWidth="lg">
                        <Grid container>
                            <Grid className="botones" item xs={12} >
                                <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                    Enviar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Snack estado={snackOpen} setSnackOpen={setSnackOpen} />
                </form>
            </>
        )

    if (!LoginOutData.userHasPermiso(22)) return (<p>No puedes acceder aquí</p>)

}