import React from 'react';


export default function TextoConIcono(props){

    return(
        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            padding: (props.padding ? props.padding : '15px 0px'),
            justifyContent: props.justifyContent
        }}>
            {props.icono}
            <span style={{marginLeft: '3px'}}>{props.texto}</span>
        </div>
    )

}